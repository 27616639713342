import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import MiniHeader from '../commonComponents/MiniHeader'
import { closeCircleOutline, personOutline, swapHorizontalOutline } from 'ionicons/icons'
import SalesCreditModal from './salesCreditModal'
import ChangeSubscriptionModal from './changeSubscriptionModal'
import { colors } from '../theme/colors'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'

export default function SubscriptionDetails({ match }) {
  let subscription_id = match?.params?.id
  const didMountRef = useRef(false)

  const [value, setValue] = React.useState(0)
  const [subscription, setSubscription] = useState()
  const [subscriptionTemp, setSubscriptionTemp] = useState()
  const [subscriptionChanged, setSubscriptionChanged] = useState(false)
  const [salesCreditModalOpen, setSalesCreditModalOpen] = useState(false)
  const [changeSubscriptionModalOpen, setChangeSubscriptionModalOpen] = useState(false)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  const history = useHistory()
  const [discounts, setDiscounts] = useState([])
  const getSubscription = useCallback(async () => {
    let response = await axios.get(`/subscription?id=${subscription_id}`)

    setSubscription(response.data)
    setSubscriptionTemp(response.data)
    await getDiscountsList(response.data.customerId)
  }, [subscription_id])
  // const getDiscountsList = async customerId => {
  //   try {
  //     let response = await axios.get(`/customer/coupons/active?id=${customerId}`)
  //     setDiscounts(response.data)
  //   } catch (error) {}
  // }
  const getDiscountsList = useCallback(
    async (ignoreLoading) => {
      if (!businessData?._id) return
      try {
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        let response = await axios.get(`/discount/getList?businessId=${businessData?._id}${locQuery}`)
        setDiscounts(response.data)
      } catch (error) {}
    },
    [businessData?._id, locationId]
  )

  useEffect(() => {
    setTimeout(() => {
      getSubscription()
    }, 200)
  }, [getSubscription, subscription_id])
  useEffect(() => {
    setTimeout(() => {
      getSubscription()
    }, 200)
  }, [])

  const save = async () => {
    let response = await axios.patch(`/subscription?id=${subscription_id}`, subscriptionTemp)
    await getSubscription()
    setSubscriptionChanged(false)
  }
  useIonViewWillEnter(() => {
    // Check if the component has already mounted
    if (didMountRef.current) {
      getSubscription()
    } else {
      // If it's the first mount, set the ref to true
      didMountRef.current = true
    }
  })
  const cancel = () => {
    setSubscriptionTemp(subscription)
    setSubscriptionChanged(false)
  }

  const handleUpgradeSubscription = async () => {
    setChangeSubscriptionModalOpen(true)
  }
  const handleCancelSubscription = () => {
    history.push(`/core/cancelSubscription/${subscription?._id}`, { subscriptionTemp })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{subscriptionTemp ? 'Subscription' : 'loading...'}</IonTitle>
          {subscriptionChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {subscription ? (
          <StandardContainer>
            <IonCard style={{ padding: 20 }}>
              <MiniHeader title={'Subscription Details'} />
              <h5>
                {subscriptionTemp?.customer?.firstName} {subscriptionTemp?.customer?.lastName}
              </h5>
              <p>
                <strong>{subscriptionTemp?.subscriptionPackage?.name}</strong>
              </p>
              <p>
                ${subscriptionTemp?.price} {subscriptionTemp?.period}
              </p>
              <p>{new Date(subscriptionTemp?.startDate).toLocaleDateString()}</p>
              <p>{subscriptionTemp?.status}</p>
              <p>{subscriptionTemp?.status == 'Canceled' ? 'Canceled Date: ' + new Date(subscriptionTemp?.endDate).toLocaleDateString() : null}</p>
              <p>Platform: {subscriptionTemp?.initiationPlatform}</p>
            </IonCard>

            <IonCard style={{ padding: 20 }}>
              <MiniHeader title={'Sold by'} />
              <IonGrid style={{ marginBottom: -18, marginTop: -10 }}>
                <IonRow>
                  <IonCol>
                    <h6>
                      {subscriptionTemp?.soldEmployeeId ? (
                        <>
                          <span style={{ color: 'gold' }}>★</span> {subscriptionTemp?.soldEmployee?.firstName} {subscriptionTemp?.soldEmployee?.lastName}
                        </>
                      ) : (
                        <h6>Self Sale</h6>
                      )}
                    </h6>
                  </IonCol>
                  {(userEntitlements?.isManager || userEntitlements?.isAdmin) && (
                    <IonCol size='auto'>
                      <IonButton
                        fill='clear'
                        onClick={() => {
                          setSalesCreditModalOpen(true)
                        }}
                        style={{}}
                      >
                        Change
                      </IonButton>
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </IonCard>

            <br></br>
            <div style={{ marginLeft: 16 }}>
              <MiniHeader title={'CUSTOMER'} />
            </div>
            <IonList inset={true} mode='ios'>
              <IonItem button routerLink={`/core/customers/${subscriptionTemp?.customerId}`}>
                <IonIcon icon={personOutline} slot='start' style={{ color: colors.black }} />
                <IonLabel>Customer Profile</IonLabel>
              </IonItem>
            </IonList>

            <br></br>
            <div style={{ marginLeft: 16 }}>
              <MiniHeader title={'OTHER ACTIONS'} />
            </div>
            <IonList inset={true} mode='ios'>
              {subscriptionTemp?.status == 'Canceled' || subscriptionTemp?.status == 'Upgraded' ? null : (
                <IonItem button onClick={handleUpgradeSubscription}>
                  <IonIcon icon={swapHorizontalOutline} slot='start' style={{ color: colors.green }} />
                  <IonLabel>Change Subscription Plan</IonLabel>
                </IonItem>
              )}
              {subscriptionTemp?.status == 'Canceled' || subscriptionTemp?.status == 'Upgraded' ? null : (
                <IonItem button onClick={handleCancelSubscription}>
                  <IonIcon icon={closeCircleOutline} slot='start' style={{ color: colors.orange }} />
                  <IonLabel>Cancel Subscription</IonLabel>
                </IonItem>
              )}
            </IonList>
            <br></br>
            <br></br>
          </StandardContainer>
        ) : (
          <IonSpinner name='dots' />
        )}
        <SalesCreditModal
          isOpen={salesCreditModalOpen}
          setIsOpen={setSalesCreditModalOpen}
          locationId={locationId}
          subscriptionId={subscription?._id}
          onSuccess={getSubscription}
        />
        <ChangeSubscriptionModal
          alldiscounts={discounts}
          isOpen={changeSubscriptionModalOpen}
          setIsOpen={setChangeSubscriptionModalOpen}
          locationId={locationId}
          currentSubscription={subscription}
          onSuccess={() => {
            history.goBack()
          }}
        />
      </IonContent>
    </IonPage>
  )
}

import { IonItem, IonInput } from '@ionic/react';

export default function LocationAddressStep({ formData, updateFormData }) {
  return (
    <div style={{ margin: 24, border: '1px solid #dcdcdc', borderRadius: '10px' }}>
      <IonItem className="transparent-item">
        <IonInput
          value={formData.addressStreet1}
          placeholder="Street Address 1"
          onIonInput={(e) => updateFormData('addressStreet1', e.target.value)}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonInput
          value={formData.addressStreet2}
          placeholder="Street Address 2 (Optional)"
          onIonInput={(e) => updateFormData('addressStreet2', e.target.value)}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonInput
          value={formData.addressCity}
          placeholder="City"
          onIonInput={(e) => updateFormData('addressCity', e.target.value)}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonInput
          value={formData.addressState}
          placeholder="State"
          onIonInput={(e) => updateFormData('addressState', e.target.value)}
        ></IonInput>
      </IonItem>
      <IonItem className="transparent-item" lines="none">
        <IonInput
          value={formData.addressZipcode}
          placeholder="Zip Code"
          onIonInput={(e) => updateFormData('addressZipcode', e.target.value)}
        ></IonInput>
      </IonItem>
    </div>
  );
}

import { IonGrid, IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'
import StatusCircle from '../../commonComponents/statusCircle'
import StandardCenterCenter from '../../commonComponents/StandardCenterCenter'

const standardSteps = ['Scheduled', 'Arrived', 'In Progress', 'Complete']

export default function ProgressStepper({ status, isWalkin }) {
  if (!status) return null

  let steps = standardSteps

  let stepsData = {
    Scheduled: 0,
    Arrived: 1,
    'In Progress': 2,
    Complete: 3,
  }

  let activeStep = status === 'No Show' ? -1 : stepsData?.[status] || null

  if (status === 'No Show') return <NoShow />
  if (status === 'Canceled') return <Canceled />

  return (
    <IonGrid>
      <IonRow>
        {steps.map((label, index) => {
          let text = label
          if (label === 'Scheduled' && isWalkin) {
            text = 'Walk-in'
          }

          return (
            <IonCol key={label} size='3' className='ion-text-center'>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {index <= activeStep ? (
                  <IonIcon
                    icon={checkmarkCircleOutline}
                    style={{
                      color: 'green',
                      fontSize: '24px',
                    }}
                  />
                ) : (
                  <IonLabel
                    style={{
                      border: '1px solid grey',
                      borderRadius: '50%',
                      height: 22,
                      width: 22,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 2,
                    }}
                  >
                    {index + 1}
                  </IonLabel>
                )}
              </div>
              <div style={{ marginTop: 10, fontSize: '12px' }}>{text}</div>
            </IonCol>
          )
        })}
      </IonRow>
    </IonGrid>
  )
}

function NoShow() {
  return (
    <div style={{ marginTop: 10 }}>
      <StandardCenterCenter>
        <StatusCircle status={'No Show'} xs />
        <div style={{ fontSize: 12, marginTop: 8 }}>No Show</div>
      </StandardCenterCenter>
    </div>
  )
}

function Canceled() {
  return (
    <div style={{ marginTop: 10 }}>
      <StandardCenterCenter>
        <StatusCircle status={'Canceled'} xs />
        <div style={{ fontSize: 12, marginTop: 8 }}>Canceled</div>
      </StandardCenterCenter>
    </div>
  )
}

import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import {
  IonCard,
  IonContent,
  IonPage,
  IonIcon,
  IonRow,
  IonLabel,
  IonGrid,
  IonAvatar,
  IonModal,
  IonButton,
  IonButtons,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { trash } from 'ionicons/icons'
import CopyText from '../commonComponents/CopyText'
import BusinessPermissions from './NewUser/BusinessPermissions'
import LocationPermissions from './NewUser/LocationPermissions'
import CommonHeader from '../commonComponents/CommonHeader'

export default function InviteDetails({ match }) {
  const id = match?.params?.id
  const history = useHistory()
  const { businessData, userData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [invite, setInvite] = useState()
  const [inviteUserChanged, setInviteUserChanged] = useState(false)
  const [entitlementsData, setEntitlementsData] = useState(false)
  let registrationURL = invite
    ? `${window.location.protocol}//${window.location.hostname}${
        process.env.REACT_APP_ENVIRONMENT == 'develop' ? ':8100' : ''
      }/registerNewUser/?businessId=${businessData._id}&email=${invite?.email}&code=${invite?.code}`
    : ''

  const getInvite = async () => {
    let response = await axios.get(`/user/invite?id=${id}`)
    //
    const entitlementsData = {
      id: response?.data?.businessId,
      locations: response?.data?.locations,
    }
    setEntitlementsData(entitlementsData)
    setInvite(response.data)
  }
  const updateEntitlements = async entitlements => {
    setInviteUserChanged(true)
    setEntitlementsData(entitlements)
  }
  // const updateEntitlements = async entitlements => {
  //   const updateInviteInput = {
  //     _id: invite._id,
  //     entitlements: entitlements,
  //   }

  //   try {
  //     const response = await axios.patch('/user/updateInvite', updateInviteInput)
  //     if (response.status == 200) {
  //       getInvite()
  //     }
  //   } catch (error) {
  //     getInvite()
  //   }
  // }

  useEffect(() => {
    getInvite()
  }, [id])

  //
  let formattedCreatedDate = ''
  if (invite?.createdDate) {
    const utcDate = new Date(invite?.createdDate)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    }
    formattedCreatedDate = utcDate.toLocaleString('en-US', options)

    //
  }
  const save = async () => {
    setLoading(true)
    const updateInviteInput = {
      _id: invite._id,
      locations: entitlementsData.locations,
    }

    try {
      const response = await axios.patch('/user/updateInvite', updateInviteInput)
      if (response.status == 200) {
        setLoading(false)
        getInvite()
        setInviteUserChanged(false)
      }
    } catch (error) {
      setLoading(false)
      getInvite()
      setInviteUserChanged(false)
    }
  }
  const openDeleteModal = () => {
    setDeleteModalVisible(true)
  }
  const closeDeleteModal = () => {
    setDeleteModalVisible(false)
  }
  const deleteInvite = async () => {
    try {
      const deleteResponse = await axios.post(`/user/deleteInvite?id=${invite._id}`)
      if (deleteResponse.status === 200) {
        history.goBack()
      }
    } catch (error) {}
  }
  const confirmDeleteInvite = () => {
    deleteInvite()
    setDeleteModalVisible(false)
  }

  return (
    <IonPage>
      <CommonHeader
        title={'Invited User Details'}
        saveButton={inviteUserChanged}
        loading={loading}
        backIcon={true}
        handleSaveButton={save}
      />
      <IonContent>
        <StandardContainer>
          <IonModal style={{ color: 'black' }} isOpen={deleteModalVisible}>
            <IonContent>
              <IonCard style={{ padding: '2rem' }}>
                <h4>Are you sure you want to delete this invite?</h4>
                <IonRow
                  style={{
                    marginTop: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonButtons>
                    <IonButton onClick={closeDeleteModal} style={{ color: 'black' }}>
                      Cancel
                    </IonButton>
                    <IonButton onClick={confirmDeleteInvite}>Delete</IonButton>
                  </IonButtons>
                </IonRow>
              </IonCard>
            </IonContent>
          </IonModal>
          <IonCard
            style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IonIcon
              onClick={openDeleteModal}
              style={{
                fontSize: '1.8rem',
                position: 'absolute',
                right: '0',
                top: '0',
                padding: '1rem',
              }}
              icon={trash}
            ></IonIcon>
            <IonGrid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <IonAvatar style={{ margin: 20, height: 100, width: 100, marginBottom: 0 }}>
                <img
                  alt="Silhouette of a person's head"
                  src='https://ionicframework.com/docs/img/demos/avatar.svg'
                />
              </IonAvatar>

              <h1
                style={{
                  width: '100%',
                  fontSize: 30,
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  color: 'black',
                }}
              >
                {invite?.email}
              </h1>
              <IonLabel>Created : {formattedCreatedDate}</IonLabel>
              <IonRow
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CopyText textToCopy={registrationURL} />
              </IonRow>
            </IonGrid>
          </IonCard>
          <IonCard style={{ padding: 20 }}>
            {invite && (
              <BusinessPermissions
                entitlementsData={entitlementsData}
                updateEntitlements={updateEntitlements}
              />
            )}
            {/* <KeyList data={invite} update={updateEntitlements} /> */}
          </IonCard>
          <IonCard style={{ padding: 20 }}>
            {invite && (
              <LocationPermissions
                updateEntitlements={updateEntitlements}
                entitlementsData={entitlementsData}
              />
            )}
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from './auth'
import StandardContainer from './commonComponents/StandardContainer'
import { checkmarkCircle } from 'ionicons/icons'
import GetExpiration from './Utils/GetExpiration'
import UserNotice from './Notice'

export default function JoinBusiness() {
  const { userData, locationData } = useContext(AuthContext)
  const [deletingIndices, setDeletingIndices] = useState([])
  const [invitationsList, setInvitationsList] = useState([])
  const [loadingInvitations, setLoadingInvitations] = useState(false)
  const [invitationAccepted, setInvitationAccepted] = useState(false)
  const [refreshingList, setRefreshingList] = useState(false)
  const [acceptingIndices, setAcceptingIndices] = useState([])
  const [acceptedIndices, setAcceptedIndices] = useState([])

  const searchParams = new URLSearchParams(window.location.search)
  const email = searchParams.get('email')
  const getPendingInvitations = async refreshing => {
    refreshing ? setRefreshingList(true) : setLoadingInvitations(true)
    try {
      const pendingInvitationsResponse = await axios.get(
        `/user/invitations?email=inviteuser778@test.com`,
      )

      if (pendingInvitationsResponse?.data) {
        refreshing ? setRefreshingList(false) : setLoadingInvitations(false)
        setInvitationsList(pendingInvitationsResponse?.data)
      }
    } catch (error) {
      setLoadingInvitations(false)
    }
  }
  const handleAcceptInvitation = async (invitation, index) => {
    setAcceptingIndices(prevIndices => [...prevIndices, index])

    let locationsArr = locationData.map((item, index) => item._id)
    const acceptInvitationInput = {
      code: invitation.code,
      businessId: invitation.businessId,
      firebaseId: userData?._id,
      email: invitation.email,
      locations: locationsArr,
    }
    try {
      const registerAndClaimCodeResponse = await axios.post(
        '/user/registerAndClaimCode',
        acceptInvitationInput,
      )
      if (registerAndClaimCodeResponse.status) {
        setTimeout(() => {
          setAcceptingIndices(previousIndices =>
            previousIndices.filter((_, i) => !acceptingIndices.includes(i)),
          )
          setAcceptedIndices(prevIndices => [...prevIndices, index])
        }, 1000)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getPendingInvitations()
  }, [userData])
  useEffect(() => {
    getPendingInvitations()
  }, [])

  return (
    <IonPage id='main-content'>
      <IonContent>
        <StandardContainer>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h4>Join The Business</h4>
          </div>
          {loadingInvitations && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IonSpinner name='dots' />
            </div>
          )}
          {!loadingInvitations && invitationsList && invitationsList.length == 0 && (
            <UserNotice fromBusiness={true} />
          )}
          {!loadingInvitations && !invitationAccepted && (
            <>
              {invitationsList &&
                invitationsList.map((invitation, index) => {
                  return (
                    invitation?.business?.name && (
                      <>
                        <IonItem>
                          <IonGrid style={{ padding: 0 }}>
                            <IonRow
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <IonCol>
                                <h6 style={{ margin: 0 }}>{invitation?.business?.name}</h6>
                              </IonCol>
                              <IonCol size='auto'>
                                <IonButton
                                  style={
                                    GetExpiration(invitation.expiryTime).includes('Expired')
                                      ? {
                                          '--background': ' #c2c2c2',
                                          '--color': '#101010',
                                        }
                                      : {}
                                  }
                                  disabled={GetExpiration(invitation.expiryTime).includes(
                                    'Expired',
                                  )}
                                  fill='clear'
                                  onClick={() => handleAcceptInvitation(invitation, index)}
                                  expand='full'
                                >
                                  {acceptedIndices.includes(index) ? (
                                    <IonIcon icon={checkmarkCircle} />
                                  ) : acceptingIndices.includes(index) ? (
                                    <IonSpinner name='lines' />
                                  ) : (
                                    'Join'
                                  )}
                                </IonButton>{' '}
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      </>
                    )
                  )
                })}
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { IonAvatar, IonButton, IonGrid, IonRow, IonCol, IonInput, IonText, IonSpinner, IonIcon } from '@ionic/react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { AuthContext } from './auth'
import checkEmailExists from './Utils/CheckEmailValidation'
import { storefrontOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import Copyright from './commonComponents/copyright'

// Styling for components can be handled with CSS or inline styles
const styles = {
  avatar: {
    margin: '10px auto',
    backgroundColor: '#1e90ff', // Primary blue color
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: 10,
  },
  submit: {
    marginTop: 20,
    height: 50,
  },
}

const RegisterNewUserToBusiness = () => {
  const hist = useHistory()
  const authContextData = useContext(AuthContext)
  const userData = authContextData ? authContextData.userData : undefined
  const [disabled, setDisabled] = useState(true)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const searchParams = new URLSearchParams(window.location.search)
  const businessId = searchParams.get('businessId')
  const code = searchParams.get('code')
  const email = searchParams.get('email')

  useEffect(() => {
    const checkUserAndInvitation = async () => {
      setLoadingPage(true)

      if (userData && userData.email === email) {
        try {
          const verifyResponse = await Axios.get(`/user/invite/check?code=${code}`)
          if (verifyResponse.data.invitationExists) {
            hist.push(`/pendingInvitations?email=${email}`)
          } else {
            hist.push('/core/dashboard')
          }
        } catch (error) {
          console.error('Error checking invitation status:', error)
        }
      } else {
        try {
          const accountCheckResponse = await checkEmailExists('user', email)
          if (accountCheckResponse.emailExists) {
            localStorage.setItem('redirectTo', 'pendingInvitations')
            hist.push('/login')
          } else {
            setLoadingPage(false)
            localStorage.setItem('redirectTo', 'pendingInvitations')
          }
        } catch (error) {
          console.error('Error checking account status:', error)
        }
      }
    }
    checkUserAndInvitation()
  }, [userData, email, businessId, code, hist])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const businessId = searchParams.get('bid')
    const link = searchParams.get('link')
  }, [])

  const handlePassword = (event) => {
    setPassword(event.target.value)
    setDisabled(!event.target.value)
  }

  const handleSignUp = async (event) => {
    setDisabled(true)
    event.preventDefault()

    setLoading(true)
    try {
      const verifyResponse = await Axios.post('/user/verifyCode', {
        businessId,
        code,
        email,
      })
      if (verifyResponse.data.success) {
        const response = await createUserWithEmailAndPassword(getAuth(), email, password)
        try {
          const registerResponse = await Axios.post('/user/registerAndClaimCode', {
            email,
            firebaseId: response.user.uid,
            code,
            businessId,
          })
          if (registerResponse.status) {
            const redirectTo = localStorage.getItem('redirectTo')
            if (redirectTo === 'pendingInvitations') {
              hist.push(`/pendingInvitations?email=${email}`)
              localStorage.removeItem('redirectTo')
            } else {
              hist.push('/core/dashboard')
            }
          } else {
            hist.push('/login')
          }
        } catch (error) {
          hist.push('/login')
        }
      } else {
        throw new Error('Not Verified')
      }
    } catch (error) {
      alert(error)
      setDisabled(false)
    }
  }

  if (!code || !businessId || !email) {
    hist.push('/core/dashboard')
  }

  return (
    <IonGrid>
      <IonRow>
        <IonCol size='12' className='ion-text-center'>
          {loadingPage && <IonSpinner name='dots' />}
          {!loadingPage && (
            <>
              <div style={styles.avatar}>
                <IonAvatar>
                  <IonIcon icon={storefrontOutline} />
                </IonAvatar>
              </div>
              <IonText>
                <h1>Create Account</h1>
                <p>Welcome</p>
              </IonText>
              <form onSubmit={handleSignUp} style={styles.form}>
                <IonRow>
                  <IonCol size='12'>
                    <IonInput value={email} disabled placeholder='Email' type='email' required />
                  </IonCol>
                  <IonCol size='12'>
                    <IonInput value={password} onIonInput={handlePassword} placeholder='Password' type='password' required />
                  </IonCol>
                </IonRow>
                <IonButton type='submit' expand='block' disabled={disabled} style={styles.submit}>
                  Continue
                </IonButton>
                <IonRow className='ion-justify-content-center' style={{ marginTop: 20 }}>
                  {loading && <IonSpinner name='dots' />}
                </IonRow>
              </form>
            </>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Copyright />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default RegisterNewUserToBusiness

import { IonItem, IonInput } from '@ionic/react';

export default function LocationNameStep({ formData, updateFormData }) {
  return (
    <>
      <IonItem style={{ margin: '1rem' }}>
        <IonInput
          onIonInput={(e) => updateFormData('name', e.target.value)}
          value={formData.name}
          placeholder="e.g. Highlands Ranch or Central Park"
        ></IonInput>
      </IonItem>
    </>
  );
}

import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { IonButton, IonCard } from '@ionic/react'

export default function ArchiveEmployee({ employee, getEmployee }) {
  const history = useHistory
  const archiveEmployee = async () => {
    if (window.confirm('Are you sure you want to archive the employee?')) {
      // Delete it!
      try {
        await axios.delete(`/employee?id=${employee._id}`)
        history.push(-1)
      } catch (error) {
        alert("I'm sorry there was a problem. Please try again later AE-1002")
      }
    }
  }

  const unArchiveEmployee = async () => {
    if (window.confirm('Are you sure you want to unarchive the employee?')) {
      // Delete it!
      try {
        await axios.post(`/employee/unarchive`, { id: employee._id })
        getEmployee()
      } catch (error) {
        alert("I'm sorry there was a problem. Please try again later UAE-1001")
      }
    }
  }

  return (
    <>
      <IonCard style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        {employee.isArchived ? (
          <IonButton color='warning' onClick={unArchiveEmployee}>
            Unarchive Employee
          </IonButton>
        ) : (
          <IonButton color='warning' onClick={archiveEmployee}>
            Archive Employee
          </IonButton>
        )}
      </IonCard>
    </>
  )
}

import { IonSelect, IonSelectOption, IonLabel, IonChip, IonItem } from '@ionic/react'
import { useState } from 'react'

function getStyles(name, includedInSubscriptions = []) {
  return {
    fontWeight: (includedInSubscriptions || []).indexOf(name) === -1 ? 'normal' : 'bold',
  }
}

export default function SubscriptionPackageMultiSelect({ includedInSubscriptions = [], onValueChange, subscriptionPackages, locationId }) {
  const [selectedPackages, setSelectedPackages] = useState(includedInSubscriptions || [])

  const handleChange = (event) => {
    const { value } = event.target
    setSelectedPackages(value)
    onValueChange('includedInSubscriptions', value)
  }

  if (!subscriptionPackages) return <div>Loading subscription packages...</div>

  return (
    <IonItem>
      <IonLabel position='stacked'>Included with Subscription Package</IonLabel>
      <IonSelect multiple={true} value={selectedPackages} onIonChange={handleChange}>
        {subscriptionPackages.map((subscriptionPackage) => (
          <IonSelectOption key={subscriptionPackage._id} value={subscriptionPackage._id} style={getStyles(subscriptionPackage, selectedPackages)}>
            {subscriptionPackage.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  )
}

import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react'
import LocationsMultiSelect from './locationsMultiSelect'
import SubscriptionPackageMultiSelect from './subscriptionPackageMultiSelect'

export default function DetailsView({ service, updateService, checkService, locations, subscriptionPackages, locationId }) {
  const onValueChange = (field, value) => {
    updateService({ ...service, [field]: value })
  }

  if (!service) return 'loading...'

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='12' sizeMd='6'>
            <IonItem>
              <IonLabel position='stacked'>Name</IonLabel>
              <IonInput value={service.name} onIonChange={(e) => onValueChange('name', e.detail.value)} placeholder='Enter service name' />
            </IonItem>

            <IonItem>
              <IonLabel position='stacked'>Description</IonLabel>
              <IonInput value={service.description} onIonChange={(e) => onValueChange('description', e.detail.value)} placeholder='Enter service description' />
            </IonItem>

            <IonItem>
              <IonLabel position='stacked'>Duration</IonLabel>
              <IonSelect value={service.durationMin} onIonChange={(e) => onValueChange('durationMin', e.detail.value)}>
                <IonSelectOption value={10}>10 min</IonSelectOption>
                <IonSelectOption value={15}>15 min</IonSelectOption>
                <IonSelectOption value={30}>30 min</IonSelectOption>
                <IonSelectOption value={45}>45 min</IonSelectOption>
                <IonSelectOption value={60}>60 min</IonSelectOption>
                <IonSelectOption value={90}>90 min</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position='stacked'>Price</IonLabel>
              <IonInput value={service.price} onIonChange={(e) => onValueChange('price', e.detail.value)} type='number' placeholder='Enter price' />
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='6'>
            <LocationsMultiSelect locations={locations} serviceLocations={service.locations} onValueChange={onValueChange} locationId={locationId} />
            <SubscriptionPackageMultiSelect
              subscriptionPackages={subscriptionPackages}
              includedInSubscriptions={service.includedInSubscriptions}
              onValueChange={onValueChange}
              locationId={locationId}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}

import { IonSelect, IonSelectOption, IonLabel, IonChip, IonItem } from '@ionic/react'
import { useState } from 'react'

function getStyles(name, productLocations = []) {
  return {
    fontWeight: (productLocations || []).indexOf(name) === -1 ? 'normal' : 'bold',
  }
}

export default function MultipleSelectChip({ productLocations = [], onValueChange, locations, locationId }) {
  const [selectedLocations, setSelectedLocations] = useState(productLocations || [])

  const handleChange = (event) => {
    const { value } = event.target
    setSelectedLocations(value)
    onValueChange('locations', value)
  }

  if (!locations) return 'loading more...'

  return (
    <IonItem>
      <IonLabel position='stacked'>Locations</IonLabel>
      <IonSelect multiple={true} value={selectedLocations} disabled={!!locationId} onIonChange={handleChange}>
        {locations.map((location) => (
          <IonSelectOption key={location._id} value={location._id} style={getStyles(location, selectedLocations)}>
            {location.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  )
}

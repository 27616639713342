import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'

export default function DiscountListView() {
  const { businessData, locationId } = useContext(AuthContext)
  const [discounts, setDiscounts] = useState([])
  const [addDiscountDialogOpen, setAddDiscountDialogOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  // useEffect(()=>{
  //     window.onpopstate = () => {
  //         getDiscountsList()
  //     }
  //     return ()=>{window.onpopstate = null}
  // })
  useIonViewWillEnter(() => {
    getDiscountsList()
  })
  useEffect(() => {
    getDiscountsList()
  }, [businessData])
  useEffect(() => {
    getDiscountsList()
  }, [])

  const getDiscountsList = async (ignoreLoading) => {
    if (!businessData?._id) return
    setLoading(!ignoreLoading)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/discount/getList?businessId=${businessData._id}${locQuery}`)
      setDiscounts(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    setLoading(false)
  }

  const openAddDiscountDialog = () => {
    setAddDiscountDialogOpen(true)
  }
  const closeAddDiscountDialog = () => {
    getDiscountsList()
    setAddDiscountDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getDiscountsList(true)
    event.detail.complete()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Discounts</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={openAddDiscountDialog}>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          <ResultsList discounts={discounts} locationId={locationId} />
        </StandardContainer>
      </IonContent>
      <AddDialog open={addDiscountDialogOpen} handleClose={closeAddDiscountDialog} businessId={businessData._id} locationId={locationId} />
    </IonPage>
  )
}

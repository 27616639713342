import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import CalendarView from './calendarView6'
import { IonCheckbox, IonContent, IonItem, IonLabel, IonList, IonMenu, IonPage } from '@ionic/react'
import AddHeader from '../commonComponents/AddHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import { colors } from '../theme/colors'
import { fontWeights } from '../theme/typography'
import Avatar from '../commonComponents/avatar'
import ListViewAppointments from './ListView/index'
import '../Calendar/MyCalendar.css'
export default function AppointmentListView() {
  const { businessData, userData, locationId } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const [appointments, setAppointments] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = React.useState(false)
  const [breaks, setBreaks] = React.useState([])
  const [defaultView, setDefaultView] = useState('calendar')
  const [employees, setEmployees] = useState([])
  const [selectedStylists, setSelectedStylists] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    setSelectedDate(newSelectedDate)
  }

  const handleNextWeek = (date) => {
    const newSelectedDate = new Date(date)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    setSelectedDate(newSelectedDate)
  }
  const getEmployeesList = async () => {
    if (defaultView == 'calendar') {
      // let locQuery = (scheduleData && scheduleData.location && scheduleData.location._id || null) ? `&locationId=${scheduleData.location._id}` : ''
      let response = await axios.get(`/employee/getPublicList?businessId=${businessData?._id}`)
      const filteredEmployeeFormattedData = response?.data?.map((item, index) => ({
        id: item._id,
        _id: item._id,
        title: item.firstName + ' ' + item.lastName,
        ...item,
      }))
      setEmployees(filteredEmployeeFormattedData)
    }
  }
  const refreshAppointments = () => {
    if (defaultView == 'calendar') {
      getAppointmentsList()
      getEmployeesList()
    }
  }
  useEffect(() => {
    if (defaultView == 'calendar' && locationId && businessData?._id) {
      getAppointmentsList()
      getEmployeesList()
      getBreaks()

      const interval = setInterval(() => {
        getAppointmentsList(true)
      }, 20000)
      return () => clearInterval(interval)
    }
  }, [selectedDate, businessData, locationId])

  const getAppointmentsList = async (hideLoader = false) => {
    if (defaultView == 'calendar') {
      if (!businessData?._id) return
      setLoading(!hideLoader)
      try {
        let locQuery = locationId ? `&locationId=${locationId}` : ''
        let date = getFormattedMediumDate(selectedDate)

        let response = await axios.get(`/appointment/getList?businessId=${businessData?._id}${locQuery}&dateText=${date}`)

        const filteredData = response.data.filter((item, index) => item.status != 'Canceled')
        const data = filteredData.map((item, index) => {
          return {
            resourceId: item?.barber?._id,
            start: new Date(item.startTime),
            end: new Date(item.endTime),
            extendedProps: { ...item },
          }
        })
        setAppointments(response.data)
      } catch (error) {}
      setLoading(false)
    }
  }

  const getBreaks = async () => {
    if (defaultView == 'calendar') {
      if (!businessData?._id) return
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let date = getFormattedMediumDate(selectedDate)
      try {
        let response = await axios.get(`/employee/searchForBreaksByDate?businessId=${businessData?._id}${locQuery}&dateText=${date}`)

        setBreaks(response && response.data ? response.data : [])
      } catch (error) {}
    }
  }

  const handleStylistChange = (id) => {
    const updatedStylists = selectedStylists.includes(id) ? selectedStylists.filter((stylistId) => stylistId !== id) : [...selectedStylists, id]
    setSelectedStylists(updatedStylists)
  }

  const handleTypeChange = (type) => {
    const updatedTypes = selectedTypes.includes(type) ? selectedTypes.filter((selectedType) => selectedType !== type) : [...selectedTypes, type]
    setSelectedTypes(updatedTypes)
  }

  if (userEntitlements?.noPermissions) {
    return (
      <>
        <DefaultMenu />
        <IonPage id='main-content'>
          <AddHeader title={'Appointments'} />
          <IonContent>
            <StandardContainer>
              <NoPermissionsCard />
            </StandardContainer>
          </IonContent>
        </IonPage>
      </>
    )
  }

  const handleView = () => {
    setDefaultView(defaultView === 'list' ? 'calendar' : 'list')
  }
  const filteredBarbers = employees?.filter((barber) => appointments?.some((appointment) => appointment?.barber?._id === barber?._id))

  return (
    <>
      <IonMenu contentId='appointment-menu'>
        <IonContent className='regularBackground ion-padding '>
          <h6 style={{ color: colors.black50, fontWeight: fontWeights.weightBold }}>VIEW</h6>
          <IonList lines='none'>
            <IonItem style={{ cursor: 'pointer' }} onClick={() => setDefaultView('list')}>
              {/* <AlignHorizontalIcon style={{ marginRight: '0.5rem' }} /> */}
              <IonLabel>Schedule</IonLabel>
            </IonItem>
            <IonItem style={{ cursor: 'pointer' }}>
              {/* <AlignVerticalIcon style={{ marginRight: '0.5rem' }} /> */}
              <IonLabel onClick={() => setDefaultView('calendar')}>Calendar</IonLabel>
            </IonItem>
          </IonList>
          {appointments?.length > 0 ? (
            <>
              <h6 style={{ borderTop: `1px solid ${colors.black10}`, paddingTop: '1rem', color: colors.black50, fontWeight: fontWeights.weightBold }}>
                STYLISTS
              </h6>

              <IonList lines='none'>
                {filteredBarbers?.map((item, index) => (
                  <IonItem key={index} style={{ padding: '5px 0' }} onClick={() => handleStylistChange(item.title)}>
                    <IonCheckbox slot='end' checked={selectedStylists.includes(item.title)} onIonChange={(e) => handleStylistChange(item.title)} />
                    <Avatar employee={item} businessId={businessData?._id} small />
                    <IonLabel style={{ marginLeft: 10 }}>{item.title}</IonLabel>
                  </IonItem>
                ))}
              </IonList>
              <h6 style={{ borderTop: `1px solid ${colors.black10}`, paddingTop: '1rem', color: colors.black50, fontWeight: fontWeights.weightBold }}>TYPE</h6>
              <IonList lines='none'>
                {['Scheduled', 'In Progress', 'Complete', 'Canceled', 'No Show', 'Arrived'].map((item, index) => (
                  <IonItem key={index} style={{ padding: '5px 0' }} onClick={() => handleTypeChange(item)}>
                    <IonCheckbox slot='end' checked={selectedTypes.includes(item)} onIonChange={(e) => handleTypeChange(item)} />
                    <IonLabel style={{ marginLeft: 10 }}>{item}</IonLabel>
                  </IonItem>
                ))}
              </IonList>
            </>
          ) : null}
        </IonContent>
      </IonMenu>

      <IonPage id='appointment-menu'>
        {defaultView === 'list' ? (
          <>
            {/* <NewListView /> */}
            <ListViewAppointments
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              selectedStylists={selectedStylists}
              selectedTypes={selectedTypes}
              setAppointmentsInMenu={setAppointments}
              employees={employees}
              getBreaks={getBreaks}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </>
        ) : (
          <>
            <CalendarView
              filteredBarbers={filteredBarbers}
              handleStylistChange={handleStylistChange}
              handleTypeChange={handleTypeChange}
              employees={employees}
              breaks={breaks}
              handleView={handleView}
              loading={loading}
              appointments={appointments}
              selectedDate={selectedDate}
              locationId={locationId}
              setSelectedDate={setSelectedDate}
              refreshAppointments={refreshAppointments}
              selectedStylists={selectedStylists}
              selectedTypes={selectedTypes}
              getBreaks={getBreaks}
            />
          </>
        )}
      </IonPage>
    </>
  )
}

import React from 'react';
import { AuthContext } from './auth'



const access = {
    UN_AUTHENTICATED: "UN_AUTHENTICATED",
    AUTHENTICATED: "AUTHENTICATED",
    PROFESSIONAL: "PROFESSIONAL",
    MANAGER: "MANAGER",
    ADMIN: "ADMIN",
    OWNER: "OWNER"
};

const NOT_LOGGED_IN = [access.UN_AUTHENTICATED, access.AUTHENTICATED, access.PROFESSIONAL, access.MANAGER, access.ADMIN, access.OWNER]
const PROFESSIONAL_PLUS = [access.PROFESSIONAL, access.MANAGER, access.ADMIN, access.OWNER]
const MANAGER_PLUS = [access.MANAGER, access.ADMIN, access.OWNER]
const ADMIN_PLUS = [access.ADMIN, access.OWNER]
const OWNER_ONLY = [access.OWNER]


const components = {
    "location_general": ADMIN_PLUS,
    "location_schedule": MANAGER_PLUS,
    "location_advanced_all": ADMIN_PLUS,
};

export const userHasAccess = (component, user, businessId, locationId) => {

    let accessType = user ? access.AUTHENTICATED : access.UN_AUTHENTICATED;

    if (user.businesses && businessId && locationId) {
        const combinedEntitlements = getCombinedEntitlements(businessId, locationId, user?.businesses)

        if(combinedEntitlements.owner) {
            accessType = access.OWNER
        } else if(combinedEntitlements.admin) {
            accessType = access.ADMIN
        }  else if(combinedEntitlements.manager) {
            accessType = access.MANAGER
        } else if(combinedEntitlements.professional) {
            accessType = access.PROFESSIONAL
        }
    }

    console.log(accessType)
    return components[component]?.includes(accessType) ?? false;
};

function getCombinedEntitlements(businessId, locationId, businesses) {

    // Find the specific business by ID
    const business = businesses.find(b => b._id === businessId);
    if (!business) {
        throw new Error("Business not found");
    }

    // Default business-level entitlements (global entitlements)
    const businessEntitlements = {
        owner: false,
        admin: false,
        professional: false,
        manager: false,
    };

    // Function to combine entitlements where 'true' always wins
    const combineEntitlements = (ent1, ent2) => {
        return {
            owner: ent1.owner || ent2.owner,
            admin: ent1.admin || ent2.admin,
            professional: ent1.professional || ent2.professional,
            manager: ent1.manager || ent2.manager,
        };
    };

    // Find the specific location by ID
    const location = business.locations.find(loc => loc._id === locationId);
    if (!location) {
        // throw new Error("Location not found");
    }

    // Combine business and location entitlements, with 'true' being the winning value
    const combinedEntitlements = combineEntitlements(businessEntitlements, location.entitlements);

    // Return the combined entitlements for this specific location
    return combinedEntitlements
   
}

// AccessControl component
export const AccessControl = ({ componentName, children }) => {
    const { userData, businessData, selectedLocationData, locationId } = React.useContext(AuthContext)
    console.log(locationId)
    const hasAccess = userHasAccess(componentName, userData, businessData._id, selectedLocationData._id);

    if (hasAccess) {
        return <>{children}</>;
    } else {
        return null
    }
};
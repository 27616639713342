import { useState, useContext, useEffect, useRef } from 'react'
import { IonButton, IonModal, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonInput, IonLabel, IonSpinner, IonCard } from '@ionic/react'
import { AuthContext } from '../../../auth'
import axios from 'axios'
import { receiptOutline } from 'ionicons/icons'
import StandardContainer from '../../../commonComponents/StandardContainer'
import { colors } from '../../../theme/colors'
import CommonHeader from '../../../commonComponents/CommonHeader'
import CardOnFile from './CardOnFile'
import styles from '../Styles/FinalizePaymentAndTipStyles'
import PaymentSuccess from './PaymentSuccess'
import ServicesProductsSummary from './ServicesProductsSummary'

function FinalizePaymentAndTip({ isOpen, setOpen, appointment, updateAppointment, payment, tip, setTip, setPaymentOptionsModalOpen }) {
  const { businessData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [cardData, setCardData] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isCardOnFile, setIsCardOnFile] = useState(false)
  const [paymentResponse, setPaymentResponse] = useState(false)
  const [showReceiptModal, setShowReceiptModal] = useState(false)

  function addTip(value) {
    setTip(value)
  }

  function dismiss() {
    setOpen(false)
    setProcessing(false)
    setTip(null)
    setPaymentResponse(false)
  }

  function closeModals() {
    setOpen(false)
    setProcessing(false)
    setTip(null)
    setPaymentResponse(false)
    setPaymentOptionsModalOpen(false)
  }

  const finalizePayment = async () => {
    setProcessing(true)
    setErrorMessage(null)
    try {
      const appointmentPaymentResponse = await axios.post(`/payment/appointment`, {
        appointmentId: appointment._id,
        payment,
        businessId: businessData._id,
      })

      setPaymentResponse(appointmentPaymentResponse?.data)
      const response = await updateAppointment()
    } catch (error) {
      let message = error?.response?.data?.error?.raw?.message
      setErrorMessage('There was an issue with processing the payment. ' + message + ' (PA-1000)')
    }
    setProcessing(false)
  }

  const getCard = async () => {
    setLoading(true)
    let response = await axios.get(`/customer/cardStripe?customerId=${appointment?.customer?._id}`)
    setCardData(response?.data)
    setLoading(false)
  }

  useEffect(() => {
    getCard()
  }, [])

  return (
    <>
      <IonModal onDidDismiss={() => dismiss()} isOpen={isOpen}>
        {paymentResponse ? (
          <PaymentSuccess cardData={cardData} appointment={appointment} payment={payment} closeModals={closeModals} />
        ) : (
          <>
            <CommonHeader closeButton={true} closeModal={() => dismiss()} title={'Checkout'} />
            <IonContent>
              <StandardContainer>
                <TipStep
                  processing={processing}
                  cardData={cardData}
                  loading={loading}
                  payment={payment}
                  addTip={addTip}
                  tip={tip}
                  appointment={appointment}
                  finalizePayment={finalizePayment}
                  setIsCardOnFile={setIsCardOnFile}
                  paymentResponse={paymentResponse}
                  errorMessage={errorMessage}
                  customerName={`${appointment?.customer?.firstName} ${appointment?.customer?.lastName}`}
                  onShowReceipt={() => setShowReceiptModal(true)}
                />
              </StandardContainer>
            </IonContent>
          </>
        )}
      </IonModal>
      <ReceiptModal isOpen={showReceiptModal} onClose={() => setShowReceiptModal(false)} appointment={appointment} />
    </>
  )
}

function TipButton({ title, subTitle, onClick, selection, id, height, color }) {
  let baseStyle = {
    ...styles.baseStyle,
    height: height ? height : 90,
    margin: height ? '5px' : 0,
  }
  let modifyerStyle = styles.modifyerStyle

  if (selection?.id === id) {
    modifyerStyle = {
      ...styles.modifyerSelectedStyle,
    }
  }
  return (
    <div onClick={onClick} style={{ ...baseStyle, ...modifyerStyle }}>
      <IonLabel style={{ ...styles.tipButtonLabel, color: color ? color : colors.black }}>{title}</IonLabel>
      <h6 style={{ ...styles.tipButtonSubLabel }}>{subTitle}</h6>
    </div>
  )
}

function TipStep({ payment, addTip, tip, finalizePayment, appointment, setIsCardOnFile, processing, paymentResponse, errorMessage, onShowReceipt }) {
  const [selection, setSelection] = useState()
  const [customTipError, setCustomTipError] = useState(false)

  function itemSelected(tip) {
    addTip(tip.value)
    setSelection(tip)
    if (selection !== 'Custom') {
      setCustomTipError(false)
    }
  }

  function addCustomTip(value) {
    if (typeof value === 'number') {
      addTip(value)
      setCustomTipError(false)
    } else if (typeof value === 'string') {
      let parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) {
        addTip(parsedValue)
        setCustomTipError(false)
      } else {
        setCustomTipError(true)
      }
    } else {
      setCustomTipError(true)
    }
  }

  return (
    <>
      {errorMessage ? (
        <IonCard style={{ padding: '1rem' }}>
          <IonLabel>Error: {errorMessage}</IonLabel>
        </IonCard>
      ) : !paymentResponse ? (
        <>
          <IonGrid style={styles.gridStyle}>
            <IonRow>
              <h6 style={styles.totalLabel}>YOUR TOTAL</h6>
            </IonRow>
            <IonRow style={styles.totalPriceRow}>
              <IonLabel style={styles.dollarSign}>$</IonLabel>
              <IonLabel style={styles.totalPrice}>{payment?.totalPrice?.toFixed(2)}</IonLabel>
            </IonRow>
            {tip ? (
              <IonLabel style={styles.tipInfo}>
                (${((payment?.totalPrice || 0) - (tip || 0)).toFixed(2)} + ${tip?.toFixed(2)} tip)
              </IonLabel>
            ) : null}
            <IonRow style={styles.viewReceiptRow} onClick={onShowReceipt}>
              <IonIcon style={styles.receiptIcon} size='small' icon={receiptOutline} />
              <IonLabel style={styles.viewReceiptLabel}>View receipt</IonLabel>
            </IonRow>
          </IonGrid>
          <IonGrid style={styles.tipHeaderGrid}>
            <h6 style={styles.tipHeaderLabel}>Add Tip</h6>
          </IonGrid>

          <IonGrid>
            <TipCalculator selection={selection} totalPrice={payment?.totalPrice - (tip || 0)} itemSelected={itemSelected} />
            <IonRow>
              {selection?.id === 'Custom' && (
                <IonRow style={{ width: '100%' }}>
                  <IonCol>
                    <CustomInputFocus addCustomTip={addCustomTip} />
                  </IonCol>
                </IonRow>
              )}
              {selection?.id !== 'Custom' && (
                <TipButton
                  color={colors.black50}
                  height={50}
                  subTitle='Custom'
                  onClick={() => itemSelected({ id: 'Custom', value: null })}
                  selection={selection}
                  id={'Custom'}
                />
              )}
              <TipButton
                color={colors.black50}
                height={50}
                subTitle='No Tip'
                onClick={() => itemSelected({ id: 'No Tip', value: 0 })}
                selection={selection}
                id={'No Tip'}
              />
            </IonRow>
            <IonGrid style={styles.finalizePaymentGrid}>
              <CardOnFile customerId={appointment?.customer?._id} setIsCardOnFile={setIsCardOnFile} />
              {tip != null && !customTipError && (
                <IonButton disabled={processing} color='primary' expand='block' shape='round' onClick={finalizePayment} style={styles.finalizePaymentButton}>
                  {processing && <IonSpinner style={{ marginRight: 10 }} name='dots' />}
                  Accept & Pay ${payment?.totalPrice?.toFixed(2)}
                </IonButton>
              )}
            </IonGrid>
          </IonGrid>
        </>
      ) : null}
    </>
  )
}

function CustomInputFocus({ addCustomTip }) {
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <IonInput
      type='number'
      labelPlacement='floating'
      fill='outline'
      placeholder='Enter custom tip amount'
      onIonInput={(e) => addCustomTip(e.detail.value)}
      clearInput={true}
      ref={inputRef}
    ></IonInput>
  )
}

function TipCalculator({ totalPrice, itemSelected, selection }) {
  let tips =
    totalPrice < 17
      ? {
          $6: 6,
          $7: 7,
          $8: 8,
        }
      : {
          '20%': Math.ceil(totalPrice * 0.2),
          '25%': Math.ceil(totalPrice * 0.25),
          '30%': Math.ceil(totalPrice * 0.3),
        }

  return (
    <IonRow>
      {Object.entries(tips).map(([id, value]) => (
        <IonCol key={id}>
          <TipButton
            title={id}
            subTitle={totalPrice >= 17 ? `$${value.toFixed(2)}` : undefined}
            onClick={() => itemSelected({ id, value })}
            selection={selection}
            id={id}
          />
        </IonCol>
      ))}
    </IonRow>
  )
}

function ReceiptModal({ isOpen, onClose, appointment }) {
  return (
    <IonModal cssClass='custom-modal' isOpen={isOpen} onDidDismiss={onClose} trigger='open-modal' initialBreakpoint={0.75} breakpoints={[0.75]}>
      <CommonHeader title={'Receipt'} closeButton={true} closeModal={onClose} />
      <IonContent>
        <IonGrid>
          <IonGrid>
            <ServicesProductsSummary appointment={appointment} />
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonModal>
  )
}

export default FinalizePaymentAndTip

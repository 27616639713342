export const styles = {
  tableStyle: {
    width: '100%',
  },
  thStyle: {
    borderBottom: '1px solid #e3e3e3',
    textAlign: 'center',
    position: 'sticky',
    maxWidth: '18rem',
    background: 'white',
    zIndex: 998,
  },
  hourStyle: {
    // justifyContent: 'center',
    display: 'flex',
    // alignItems: 'start',
    borderRight: '1px solid #e3e3e3',
    height: 60, // Each row represents one hour
    textAlign: 'center',
    position: 'sticky',
    left: '0',
    transform: 'translateZ(0)',
    maxWidth: '3.5rem',
    width: '3.5rem',
    padding: '2px',
    zIndex: 999,
    background: 'white',
  },
  tdStyle: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'start',
    borderRight: '1px solid #e3e3e3',
    height: 60, // Each row represents one hour
    textAlign: 'center',
    position: 'sticky',
    left: '0',
    transform: 'translateZ(0)',
    maxWidth: '3.5rem',
    width: '3.5rem',
    padding: '2px',
    background: 'white',
  },
  appointmentItemStyle: {
    padding: '2px',
    borderBottom: '1px solid #e3e3e3',
    height: 60,
    textAlign: 'center',
    position: 'relative',
  },
}


const categories = [
    "Appointments", 
    // "Revenue"
];

export default function CategoryToggelControls({selectedCategory, setSelectedCategory}) {
    return (

        <div style={{ display: 'flex', marginBottom: 8 }}>
            {categories?.map((category) => {
                let activeStyle = { color: 'black', backgroundColor: "#f2f2f2", border: '1px solid transparent' };
                if (category === selectedCategory) {
                    activeStyle = { color: '#fff', backgroundColor: "#1b6dff", border: '1px solid #1b6dff' };
                }

                return (
                    <div
                        key={category}
                        style={{
                            margin: 2,
                            marginRight:6,
                            padding: 6,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 15,
                            fontSize: 11,
                            fontWeight: '500',
                            cursor: 'pointer',

                            ...activeStyle
                        }}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {category}
                    </div>
                );
            })}
        </div>
    )
}
import React from 'react'
import { IonButton, IonChip, IonGrid, IonIcon, IonLabel, IonRow, IonCol, IonTitle, IonText } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import axios from 'axios'
import SimpleDialog from './addNewSchedule'

export default function ModifiedSchedule({ standardSchedule, locationId, businessId }) {
  const [open, setOpen] = React.useState(false)
  const [modifiedSchedule, setModifiedSchedule] = React.useState([])

  const getModifiedSchedule = async () => {
    try {
      const response = await axios.get(`/location/modifiedSchedule?locationId=${locationId}`)
      setModifiedSchedule(response.data)
    } catch (error) {
      console.error('Error fetching modified schedule:', error)
    }
  }

  React.useEffect(() => {
    getModifiedSchedule()
  }, [locationId])

  const handleSave = async (data) => {
    try {
      await axios.post(`/location/modifiedSchedule`, { ...{ locationId, businessId }, ...data })
      getModifiedSchedule()
      handleClose()
    } catch (error) {
      alert('Sorry there was an issue saving. Try again later.')
    }
  }

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete the modified schedule?')) {
        await axios.delete(`/location/modifiedSchedule?id=${id}`)
        getModifiedSchedule()
      }
    } catch (error) {
      window.alert('Looks like there was an issue. Try again later.')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonText>
              <h5>Modified Schedule</h5>
            </IonText>
          </IonCol>
          <IonCol size='4' className='ion-text-right'>
            <IonButton color='primary' onClick={handleClickOpen} fill='clear'>
              + Add
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <p style={{ marginBottom: 20, fontSize: 12 }}>Modify your future schedule by changing hours or closing on a day.</p>
            <IonGrid>
              <IonRow>
                <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='3'>
                  <strong>Date</strong>
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='3'>
                  <strong>Open</strong>
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                  <strong>Open Time</strong>
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                  <strong>Close Time</strong>
                </IonCol>
                <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                  <strong>Delete</strong>
                </IonCol>
              </IonRow>
              {modifiedSchedule.map((d) => (
                <IonRow key={d._id}>
                  <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='3'>
                    {d.dateText}
                  </IonCol>
                  <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='3'>
                    <IonChip color={d.enabled ? 'primary' : 'medium'}>
                      <IonLabel>{d.enabled ? 'Open' : 'Closed'}</IonLabel>
                    </IonChip>
                  </IonCol>
                  <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                    {d.enabled ? d.startTime : '-'}
                  </IonCol>
                  <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                    {d.enabled ? d.endTime : '-'}
                  </IonCol>
                  <IonCol style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} size='2'>
                    <IonButton fill='clear' color='danger' onClick={() => handleDelete(d._id)}>
                      <IonIcon icon={trashOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <SimpleDialog open={open} onClose={handleClose} standardSchedule={standardSchedule} handleSave={handleSave} />
    </>
  )
}

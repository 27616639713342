import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'

export default function Details({ match }) {
  const product_id = match?.params?.id
  const [product, setProduct] = useState()
  const [productTemp, setProductTemp] = useState()
  const [productChanged, setProductChanged] = useState(false)
  const [locations, setLocations] = useState()
  const [subscriptionPackages, setSubscriptionPackages] = useState()
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()
  const getProduct = async () => {
    let response = await axios.get(`/product?id=${product_id}`)

    setProduct(response.data)
    setProductTemp(response.data)
  }
  useEffect(() => {
    getProduct()
  }, [product_id])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    let response = await axios.get(`/location/getList?businessId=${businessData._id}`)
    setLocations(response.data)
  }
  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
    setSubscriptionPackages(response.data)
  }
  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])

  const updateProduct = (value) => {
    setProductTemp(value)
    setProductChanged(true)
  }

  const save = async () => {
    let response = await axios.patch(`/product?id=${product_id}`, productTemp)
    await getProduct()
    setProductChanged(false)
  }

  const cancel = () => {
    setProductTemp(product)
    setProductChanged(false)
  }

  const deleteProduct = async () => {
    if (window.confirm('Are you sure you want to delete the product?')) {
      // Delete it!
      await axios.delete(`/product?id=${product_id}`)
      history.push(-1)
    }
  }

  if (!product) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{productTemp?.name || 'loading...'}</IonTitle>
          {productChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ padding: 20 }}>
            <GeneralDetails
              product={productTemp}
              updateProduct={updateProduct}
              locations={locations}
              subscriptionPackages={subscriptionPackages}
              locationId={locationId}
            />
          </IonCard>
          <IonCard style={{ padding: 20 }}>
            <IonButton color='warning' variant='contained' onClick={deleteProduct}>
              Delete
            </IonButton>
          </IonCard>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import { useState, useEffect, useContext } from 'react'
import StepPickBarber from './stepPickBarber'
import StepPickLocation from './stepPickLocation'
import StepPickDateTime from './stepPickDateTime'
import StepPickService from './stepPickService'
import StepSuccess from './stepSuccess'
import StepReviewOrder from './reviewOrder'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonSpinner } from '@ionic/react'
import StepLoginOrRegister from './stepLoginOrRegister'
import HeaderComponent from './headerComponent'
import { AuthContext } from '../auth'
import { calculatePayment } from '../sharedComponents/paymentCalculation'
import StandardContainer from '../commonComponents/StandardContainer'
import { trackScheduling } from '../analytics'
import { chevronBackOutline, closeOutline } from 'ionicons/icons'
import StepPickCustomer from './stepPickCustomer'
import StepCancellation from './StepCancellation'
import axios from 'axios'

const HEADER_TEXT = {
  service: 'Pick a Service',
  dateTime: 'Pick a Time',
  barber: 'Pick a Professional',
  loginOrRegister: 'Login or Register',
  summary: 'Review Appointment',
  success: 'Thanks for Booking!',
  location: 'Pick a Location',
}

export default function StepController({
  handlePrevWeek,
  handleNextWeek,
  isOpen,
  closeModal,
  getAppointments,
  isWalkin,
  setSubscriptionReferrer,
  customerId,
  customerSubscription,
  customerIsSubscribed,
  choosecustomer,
}) {
  const { businessData } = useContext(AuthContext)

  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState()
  const [customer, setCustomer] = useState(null)
  const [trackedSteps, setTrackedSteps] = useState([])

  let businessId = businessData._id
  const getCustomer = async () => {
    if (customerId && isOpen) {
      let response = await axios.get(`/customer?id=${customerId}`)

      setCustomer(response.data)
    }
  }
  useEffect(() => {
    getCustomer()
  }, [customerId, isOpen])

  useEffect(() => {
    if (isWalkin) {
      let steps = ['location']
      if (choosecustomer) {
        steps.push('customer')
      }
      steps.push('service', 'summary', 'success')
      setSteps(steps)
    } else {
      let baseSteps = []
      baseSteps.push('location')
      if (choosecustomer) {
        baseSteps.push(...['barber', 'customer', 'service', 'dateTime', 'cancellationPolicy', 'summary', 'success'])
      } else {
        baseSteps.push(...['barber', 'service', 'dateTime', 'cancellationPolicy', 'summary', 'success'])
      }

      setSteps(baseSteps)
    }
  }, [isOpen])

  function trackStep(stepName, step, data) {
    trackScheduling(stepName, step, data, trackedSteps.includes(stepName) ? true : false)
    setTrackedSteps([...trackedSteps, ...[stepName]])
  }

  function stepNext() {
    if (step + 1 >= steps.length) {
      closeModal()
    } else {
      setStep(step + 1)
    }
  }

  function stepBack() {
    if (step == 0) {
      closeModal()
    } else {
      setStep(step - 1)
    }
  }
  const handleChangeStep = (stepName) => {
    const index = steps.indexOf(stepName)
    setStep(index)
  }

  const [coreAppointment, setCoreAppointment] = useState({
    businessId: businessId,
    barber: null,
    anyProfessional: isWalkin ? true : false,
    isWalkin: isWalkin ? true : false,
    services: [],
    dateTime: null,
    payment: {
      subTotalPrice: 0,
      totalPrice: 0,
      tipAmount: 0,
      tipPercentage: 0,
      taxes: 0,
      taxRate: 0,
      productsTotal: 0,
      servicesTotal: 0,
      totalDiscount: 0,
    },
    durationMin: null,
  })

  function handleCancel() {
    closeModal()
  }

  function updateScheduleData(key, value) {
    let updatedData = { ...coreAppointment }
    updatedData[key] = value

    if (key == 'dateTime') {
      updatedData.barber = value.barber
      // updatedData.dateTime.barber = null
    }

    if (key == 'barber' && value == 'Anyone') {
      updatedData[key] = null
      updatedData['requestedBarber'] = null
      updatedData.anyProfessional = true
    } else if (key == 'barber') {
      updatedData['requestedBarber'] = value
      updatedData.anyProfessional = false
    }

    //if Schedule Update
    if (key == 'services') {
      let duration = 0
      for (let s of updatedData?.services) {
        duration += s.durationMin
      }
      updatedData.durationMin = duration

      updatedData.payment = calculatePayment(
        updatedData?.services,
        updatedData?.products,
        updatedData?.discounts,
        customerSubscription?.subscriptionPackageId,
        null,
        null,
        null
      )
    }

    setCoreAppointment(updatedData)
  }

  let scheduleData = { ...coreAppointment, ...{ customer } }

  let stepComponent = null
  let stepName = (steps && steps[step]) || null
  let fadeDuration = 1000

  if (stepName == 'barber') {
    stepComponent = (
      <StepPickBarber
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'customer') {
    stepComponent = (
      <StepPickCustomer
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'location') {
    stepComponent = (
      <StepPickLocation
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'service') {
    stepComponent = (
      <StepPickService
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        userSubscription={customerSubscription}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'dateTime') {
    stepComponent = (
      <StepPickDateTime
        handlePrevWeek={handlePrevWeek}
        handleNextWeek={handleNextWeek}
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'cancellationPolicy') {
    stepComponent = (
      <StepCancellation
        businessId={businessId}
        scheduleData={scheduleData}
        updateScheduleData={updateScheduleData}
        step={step}
        stepBack={stepBack}
        stepNext={stepNext}
        totalSteps={(steps && steps.length) || 0}
        userSubscription={customerSubscription}
        isWalkin={isWalkin}
        trackStep={trackStep}
      />
    )
  } else if (stepName == 'summary') {
    if (!coreAppointment?.customer?._id && !scheduleData && !scheduleData?.customer?._id) {
      stepComponent = (
        <span>
          <div>
            <StepLoginOrRegister
              businessId={businessId}
              scheduleData={scheduleData}
              updateScheduleData={updateScheduleData}
              step={step}
              stepBack={stepBack}
              stepNext={stepNext}
              totalSteps={(steps && steps.length) || 0}
              trackStep={trackStep}
            />
          </div>
        </span>
      )
    } else {
      stepComponent = (
        <div>
          <div>
            <StepReviewOrder
              choosecustomer={choosecustomer}
              setStep={handleChangeStep}
              coreAppointment={coreAppointment}
              isWalkin={isWalkin}
              userSubscription={customerSubscription}
              businessId={businessId}
              scheduleData={scheduleData}
              updateScheduleData={updateScheduleData}
              step={step}
              stepBack={stepBack}
              stepNext={stepNext}
              totalSteps={(steps && steps.length) || 0}
              trackStep={trackStep}
            />
          </div>
        </div>
      )
    }
  } else if (stepName == 'success') {
    stepComponent = (
      <div>
        <StepSuccess
          businessId={businessId}
          isSubscribed={customerIsSubscribed}
          scheduleData={scheduleData}
          updateScheduleData={updateScheduleData}
          step={step}
          stepBack={stepBack}
          stepNext={stepNext}
          totalSteps={(steps && steps.length) || 0}
          getAppointments={getAppointments}
          isWalkin={isWalkin}
          trackStep={trackStep}
          setSubscriptionReferrer={setSubscriptionReferrer}
        />
      </div>
    )
  } else {
    stepComponent = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <IonSpinner name='dots' />
        <p>{stepName}</p>
      </div>
    )
  }

  return (
    <>
      {stepName == 'success' ? null : (
        <IonHeader style={{ paddingTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
          {stepName == 'success' || step == 0 ? null : (
            <IonButtons slot='start'>
              <IonButton onClick={stepBack} style={{ color: 'black' }}>
                <IonIcon icon={chevronBackOutline} size='large' />
              </IonButton>
            </IonButtons>
          )}

          <IonButtons slot='end' style={{ marginRight: 20, color: 'black' }}>
            <IonIcon onClick={handleCancel} style={{ height: 30, width: 30 }} icon={closeOutline} />
            {/* <IonButton onClick={handleCancel}>Cancel</IonButton> */}
          </IonButtons>
        </IonHeader>
      )}
      <IonContent fullscreen={true} className='whiteBackground'>
        <StandardContainer>
          <HeaderComponent handleBack={stepBack} progress={{ total: (steps && steps.length) || 1, step: (steps && step + 1) || 0 }} />
          {stepComponent}
        </StandardContainer>
      </IonContent>
    </>
  )
}

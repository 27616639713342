import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'
import StandardContainer from '../../../commonComponents/StandardContainer'
import { bookOutline, cameraOutline, cardOutline, cashOutline, closeOutline, cubeOutline, linkOutline, settingsOutline, ticketOutline } from 'ionicons/icons'
import { colors } from '../../../theme/colors'
import AddCashTip from '../../details/AddCashTip'
import { useHistory } from 'react-router'
import { useContext, useState } from 'react'
import CustomerNotesModal from '../../../commonComponents/customerNotes'
import axios from 'axios'
import { AuthContext } from '../../../auth'
import RefundTransaction from '../../../customers/PaymentHistory/RefundTransaction'
import CommonHeader from '../../../commonComponents/CommonHeader'
import AddCardToFile from './AddCardToFile'
import ServicesProductsAndDiscounts from '../../details/servicesProductsAndDiscounts'
import ExternalPayment from './ExternalPayment'

export default function MoreActionsModal({ subscription, closeModal, appointment, isOpen, onClose, getAppointment }) {
  const [notesId, setNotesId] = useState(null)
  const [notes, setNotes] = useState('')
  const [lastEditTime, setLastEditTime] = useState(null)
  const [lastEditorName, setLastEditorName] = useState(null)
  const [notesOpen, setNotesOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const { businessData } = useContext(AuthContext)
  const [refundModalOpen, setRefundModalOpen] = useState(false)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('Products')
  const [payExternalModalOpen, setPayExternalModalOpen] = useState(false)
  const [updating, setUpdating] = useState(false)

  const history = useHistory()
  const updateModal = (value) => {
    setUpdateModalOpen(true)
    setActiveTab(value)
  }
  const navigateToHistoryPage = () => {
    history.push(`/core/history/${appointment?.customer?._id}`)
    onClose()
    closeModal()
  }
  const handleNotes = (value) => {
    setNotesOpen(value)
  }
  const handleRefundModal = (value) => {
    setRefundModalOpen(value)
  }
  const closeAddPaymentMethodModal = () => {
    setAddCardModalOpen(false)
  }
  function handleCardUpdate() {
    closeAddPaymentMethodModal()
  }

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`/customer/getNotes`, {
        params: {
          customerId: appointment?.customer?._id,
          businessId: businessData._id,
        },
      })
      setNotesId(response.data._id)
      setNotes(response.data?.notes)
      setLastEditTime(response.data?.lastEditTime)
      setLastEditorName(response.data?.lastEditorName)
    } catch (error) {
      setErrorMessage('Sorry, there was an error fetching the data')
    } finally {
    }
  }
  const payedExternal = async () => {
    if (window.confirm('Has the customer paid through other ways than this app? This should be uncommon. Click "Ok" to continue')) {
      setUpdating(true)
      try {
        let response = await axios.patch(`/appointment/updateStatus`, {
          appointmentId: appointment?._id,
          status: 'Complete',
          paidExternal: true,
        })
        await getAppointment()
      } catch (error) {}
      setUpdating(false)
      setPayExternalModalOpen(false)
    }
  }
  const handleNavigateToGallery = () => {
    onClose()
    closeModal()
    history.push(`/core/appointmentgallery/${appointment?._id}`)
  }
  return (
    <IonModal trigger='open-modal' initialBreakpoint={1} breakpoints={[1]} cssClass='custom-modal' isOpen={isOpen} onDidDismiss={onClose}>
      <StandardContainer>
        <div style={{ padding: '1rem' }}>
          <IonIcon onClick={onClose} size='large' style={{ cursor: 'pointer', color: colors.black50 }} icon={closeOutline} />
          <h2 style={{ padding: 10 }}>Payment & Services</h2>
          <IonList inset={true} mode='ios'>
            {['Complete'].includes(appointment?.status) ? null : (
              <>
                <IonItem style={{ cursor: 'pointer' }} detail={true} onClick={() => setUpdateModalOpen('Services')}>
                  <IonIcon icon={settingsOutline} />
                  <IonLabel style={{ marginLeft: 10 }}>Update Services</IonLabel>
                </IonItem>
                <IonItem style={{ cursor: 'pointer' }} detail={true} onClick={() => setUpdateModalOpen('Products')}>
                  <IonIcon icon={cubeOutline} />
                  <IonLabel style={{ marginLeft: 10 }}>Add/Update Products</IonLabel>
                </IonItem>
                <IonItem style={{ cursor: 'pointer' }} detail={true} onClick={() => setUpdateModalOpen('Discounts')}>
                  <IonIcon icon={cashOutline} />
                  <IonLabel style={{ marginLeft: 10 }}>Add/Update Discounts</IonLabel>
                </IonItem>
              </>
            )}
            {/* <IonItem style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={syncOutline} />
              <IonLabel style={{ marginLeft: 10 }}>Schedule a New Appointment</IonLabel>
            </IonItem> */}
            {appointment?.status == 'Complete' && (
              <IonItem style={{ cursor: 'pointer' }} detail={true}>
                <IonIcon icon={ticketOutline} />
                <IonLabel style={{ marginLeft: 10 }}>
                  <AddCashTip textcolor={'black'} refreshAppointment={getAppointment} appointment={appointment} />
                </IonLabel>
              </IonItem>
            )}
            {/* {appointment?.status == 'Complete' && (
              <IonItem style={{ cursor: 'pointer' }} detail={true}>
                <IonIcon icon={refreshOutline} />
                <IonLabel style={{ marginLeft: 10 }}>Refund</IonLabel>
              </IonItem>
            )} */}
            {appointment?.status != 'Complete' && (
              <IonItem onClick={() => setPayExternalModalOpen(true)} style={{ cursor: 'pointer' }} detail={true}>
                <IonIcon icon={closeOutline} />
                <IonLabel style={{ marginLeft: 10 }}>Mark as Paid External</IonLabel>
              </IonItem>
            )}
            <IonItem onClick={() => setAddCardModalOpen(true)} style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={cardOutline} />
              <IonLabel style={{ marginLeft: 10 }}>Update Payment Method</IonLabel>
            </IonItem>
          </IonList>
          <h2 style={{ padding: 10 }}>Other Actions</h2>
          <IonList inset={true} mode='ios'>
            {/* <IonItem style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={diamondOutline} />
              <IonLabel style={{ marginLeft: 10 }}>Manage/Add Subscription</IonLabel>
            </IonItem> */}
            <IonItem onClick={() => navigateToHistoryPage()} style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={linkOutline} />
              <IonLabel style={{ marginLeft: 10 }}>View Appointment History</IonLabel>
            </IonItem>
            <IonItem onClick={() => handleNotes(true)} style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={bookOutline} />
              <IonLabel style={{ marginLeft: 10 }}>Add/Update Appointment Note</IonLabel>
            </IonItem>
            <IonItem onClick={() => handleNavigateToGallery()} style={{ cursor: 'pointer' }} detail={true}>
              <IonIcon icon={cameraOutline} />
              <IonLabel style={{ marginLeft: 10 }}>Update Customer Photos</IonLabel>
            </IonItem>
          </IonList>
        </div>
        <CustomerNotesModal
          isOpen={notesOpen}
          setOpen={handleNotes}
          customerId={appointment?.customer?._id}
          fetchNotes={fetchNotes}
          setNotes={setNotes}
          notes={notes}
          notesId={notesId}
          lastEditTime={lastEditTime}
          lastEditorName={lastEditorName}
        />
        <ExternalPayment
          payment={appointment?.payment}
          payExternalModalOpen={payExternalModalOpen}
          setPayExternalModalOpen={setPayExternalModalOpen}
          updating={updating}
          appointment={appointment}
          payedExternal={payedExternal}
        />
        <IonModal isOpen={addCardModalOpen}>
          <IonContent>
            <CommonHeader title={'Add New Card'} closeButton={true} closeModal={closeAddPaymentMethodModal} />
            <AddCardToFile buttonText={'Save'} addCardToFileSuccess={handleCardUpdate} customerId={appointment?.customer?._id} />
          </IonContent>
        </IonModal>
        <IonModal onDidDismiss={() => handleRefundModal(false)} isOpen={refundModalOpen}>
          <CommonHeader title={'Refund A Transaction'} closeButton={true} closeModal={() => handleRefundModal(false)} />
          <IonContent>
            <StandardContainer>
              <RefundTransaction customer_id={appointment?.customer?._id} closeRefundModal={() => handleRefundModal(false)} />
            </StandardContainer>
          </IonContent>
        </IonModal>
        <ServicesProductsAndDiscounts
          tab={activeTab}
          isOpen={updateModalOpen}
          setOpen={setUpdateModalOpen}
          appointment={appointment}
          updateAppointment={getAppointment}
          subscription={subscription}
        />
      </StandardContainer>
    </IonModal>
  )
}

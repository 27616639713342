import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonButton, IonCard, IonContent, IonPage, IonSpinner } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import DetailsHeader from '../commonComponents/DetailsHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import CardOnFile from '../Payment/cardOnFile'
import CustomerSubscription from './subscription'
import checkEmailExists from '../Utils/CheckEmailValidation'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import CustomerDetailsListOptions from './CustomerDetailsListOptions'

export default function Details({ match }) {
  let customer_id = match?.params?.id
  const [customer, setCustomer] = useState()
  const [customerTemp, setCustomerTemp] = useState()
  const [locations, setLocations] = useState()
  const [loading, setLoading] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(null)
  const [reloadTrigger, setReloadTrigger] = useState(null)
  const [emailValidationMessage, setEmailValidationMessage] = useState(false)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const windowLocation = useLocation()
  const [creditLoading, setCreditLoading] = useState(true)
  const [creditData, setCreditData] = useState(false)
  const [apptCount, setApptCount] = useState(0)
  const [conversationDetails, setConversationDetails] = useState(false)
  const getCustomer = async () => {
    setLoading(true)
    try {
      let response = await axios.get(`/customer?id=${customer_id}`)

      setCustomer(response.data)
      setCustomerTemp(response.data)
      getCustomerCredit(response?.data?.stripeId)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const getCustomerAppointmentCount = async () => {
    try {
      const response = await axios.get(`appointment/count?customerId=${customer_id}`)

      setApptCount(response?.data?.count || 0)
    } catch (error) {}
  }
  const getCustomerCredit = async (stripeId) => {
    try {
      const creditResponse = await axios.get(`/customer/credit/balance?customerStripeId=${stripeId}`)
      if (creditResponse && creditResponse.status == 200) {
        const creditData = creditResponse.data
        setCreditData(creditData)
      }
      setCreditLoading(false)
    } catch (error) {
      setCreditLoading(false)
    }
  }
  const checkConversationExists = async () => {
    try {
      const response = await axios.get(
        `/conversation/find?locationId=${locationId}&businessId=${businessData?._id}&userId=${userData?._id}&customerId=${customer_id}`
      )
      if (response) {
        setConversationDetails(response?.data)
      }
    } catch (error) {}
  }
  useEffect(() => {
    setCreditLoading(true)
    getCustomer()
    getCustomerAppointmentCount()
    checkConversationExists()
  }, [customer_id])

  useEffect(() => {
    if (windowLocation.pathname == `/core/customers/${customer_id}`) {
      getCustomer()
      checkConversationExists()
      getCustomerAppointmentCount()
      setReloadTrigger(Math.floor(Math.random() * (100000 - 1 + 1) + 1))
    }
  }, [businessData, windowLocation])

  const getLocationsList = async () => {
    let response = await axios.get(`/location/getList?businessId=${businessData._id}`)
    setLocations(response.data)
  }
  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const updateCustomer = async (field, value) => {
    setCustomerTemp(value)

    /* if the changed field is email then 
    we have to check if the user email already existsfor any user 
    */
    if (field == 'email' && customer.email != value.email) {
      if (field == 'email' && value.email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const isValid = emailPattern.test(value.email)
        if (isValid) {
          setEmailValidationMessage(false)
          try {
            const emailExists = await checkEmailExists('customer', value.email)

            //if emailValidationMessage then seterror message as email already exists
            const validationMessage = emailExists.emailExists ? 'Email Already In Use' : false
            setEmailValidationMessage(validationMessage)
          } catch (error) {}
        } else {
          setEmailValidationMessage('Please Enter A Valid Email')
        }
      } else if (field == 'email') {
        setEmailValidationMessage('Email Address is required')
      }
    } else {
      setEmailValidationMessage('')
    }
  }

  const save = async () => {
    try {
      let response = await axios.patch(`/customer?id=${customer_id}`, customerTemp)
    } catch (error) {}
    await getCustomer()
    checkConversationExists()

    getCustomerAppointmentCount()
  }

  const cancel = () => {
    setCustomerTemp(customer)
  }

  const generateStripeId = async () => {
    try {
      await axios.post(`/customer/generateStripeId`, { customerId: customer._id })
      getCustomer()
      checkConversationExists()

      getCustomerAppointmentCount()
    } catch (error) {
      alert('Error Generating stripe Id')
    }
  }

  const deleteCustomer = async () => {
    if (window.confirm('Are you sure you want to delete the customer?')) {
      // Delete it!
      // await axios.delete(`/customer?id=${customer_id}`)
      // history.push(-1)
    }
  }
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  return (
    <IonPage>
      <DetailsHeader save={save} cancel={cancel} valueChanged={false} title={customerTemp ? customerTemp?.firstName + ' ' + customerTemp?.lastName : ''} />
      <IonContent>
        <StandardContainer>
          {customer ? (
            <>
              <GeneralDetails
                save={save}
                emailValidationMessage={emailValidationMessage}
                customer={customerTemp}
                updateCustomer={updateCustomer}
                locations={locations}
                locationId={locationId}
              />

              <CustomerSubscription customer={customerTemp} setIsSubscribed={setIsSubscribed} reloadTrigger={reloadTrigger} />

              <CustomerDetailsListOptions
                conversationDetails={conversationDetails}
                apptCount={apptCount}
                creditLoading={creditLoading}
                balance={creditData?.ending_balance}
                userEntitlements={userEntitlements}
                customer_id={customer_id}
                customerTemp={customerTemp}
              />
              {!loading && (
                <>
                  {customer?.stripeId ? (
                    <IonCard style={{ padding: 10 }} className='flat'>
                      <CardOnFile customerId={customer._id} watcherValue={isSubscribed} />
                    </IonCard>
                  ) : (
                    <IonCard style={{ padding: 10 }}>
                      <h5>This user does not have a stripe Id. Generate one now</h5>
                      <IonButton onClick={generateStripeId}>Generate Stripe Id</IonButton>
                    </IonCard>
                  )}
                </>
              )}
            </>
          ) : (
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: 200,
              }}
            >
              <IonSpinner name='dots' />
            </div>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

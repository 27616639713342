import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import MiniHeader from '../../commonComponents/MiniHeader'
import { colors } from '../../theme/colors'
import { createOutline } from 'ionicons/icons'
import EditAppointmentTime from './EditAppointmentTime'
import { useState } from 'react'
import { convertMinutesToHoursAndMinutes } from '../../utils'
import NoShowButton from './NoShowButton'
import styles from '../NewCalendarView/Styles/NoShowButtonStyles'

export default function AppointmentInfoCard({ appointment, updateStatus, updatingStatus, updateAppointmentData, getAppointment }) {
  const [showCalendar, setShowCalendar] = useState(false)
  const handleShowCalendar = () => {
    setShowCalendar(true)
  }

  const closeCalendar = () => {
    setShowCalendar(false)
  }
  let duration = 0
  duration = appointment?.durationMin
  // for (let s of appointment?.services) {
  //   duration += s.durationMin
  // }

  duration = convertMinutesToHoursAndMinutes(duration)

  return (
    <IonCard style={{ color: 'black', padding: 10 }}>
      <IonGrid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IonButton onClick={handleShowCalendar} fill='clear'>
            <IonIcon icon={createOutline} />
          </IonButton>
        </div>
        <EditAppointmentTime
          closeCalendar={closeCalendar}
          showCalendar={showCalendar}
          updateAppointmentData={updateAppointmentData}
          appointmentData={appointment}
        />
        <IonRow>
          <IonCol>
            <MiniHeader title='Date' />
            <h6>{appointment?.selectedDate}</h6>
          </IonCol>
          <IonCol>
            <MiniHeader title='Time' />
            <h6>{appointment?.startTimeText}</h6>
          </IonCol>
          <IonCol size='auto'>
            <MiniHeader title='Duration' />
            <h6>{duration} </h6>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <MiniHeader title='Services' />
            {appointment?.services?.map((s) => {
              return <h6>{s.name}</h6>
            })}
          </IonCol>
        </IonRow>
      </IonGrid>
      {['In Progress', 'Complete', 'Canceled', 'No Show', 'Arrived'].includes(appointment?.status) ? null : !appointment?.barber?._id ? (
        <IonRow>
          <IonCol>
            <p style={{ margin: 0, color: colors.orange }}>You must add a professional before you can start the appointment</p>
          </IonCol>
        </IonRow>
      ) : !appointment?.startTimeText ? (
        <IonRow>
          <IonCol>
            <p style={{ margin: 0, color: colors.orange }}>You must add a start and end time of the appointment</p>
          </IonCol>
        </IonRow>
      ) : (
        <IonGrid style={{ padding: 0 }}>
          <IonRow>
            <IonCol style={{ padding: 0 }}>
              <NoShowButton appointment={appointment} getAppointment={getAppointment} />
            </IonCol>
            <IonCol style={{ padding: 0 }}>
              <IonButton
                style={styles.button}
                disabled={updatingStatus}
                shape='round'
                expand='block'
                fill='solid'
                onClick={() => {
                  updateStatus('Arrived')
                }}
              >
                Arrived
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {['Scheduled', 'In Progress', 'Complete', 'Canceled', 'No Show'].includes(appointment?.status) ? null : (
        <IonGrid style={{ padding: 0 }}>
          {}

          <IonRow>
            <IonCol style={{ padding: 0 }}>
              <IonButton
                disabled={updatingStatus || !appointment?.barber?._id}
                expand='block'
                fill='solid'
                onClick={() => {
                  updateStatus('In Progress')
                }}
              >
                Start Appointment
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonCard>
  )
}

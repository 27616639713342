import { IonButton, IonContent, IonInput, IonLabel, IonModal, IonRadio, IonRadioGroup, IonSpinner } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import { limitDecimalPlaces } from '../../utils'
import styles from '../NewCalendarView/Styles/NoShowButtonStyles' // Import the styles

export default function NoShowButton({ appointment, getAppointment }) {
  const [chargesModalOpen, setChargesModalOpen] = useState(false)
  const { locationId, locationData } = useContext(AuthContext)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const locationNoShowSettings = selectedLocationData?.appointmentSettings?.noShowSettings
  const [selectedOption, setSelectedOption] = useState('standard')
  const [customCharge, setCustomCharge] = useState('')

  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setCustomCharge('')
    setError('')
  }

  const handleCustomChargeChange = (e) => {
    setCustomCharge(e.target.value)
    if (e.target.value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }
  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationNoShowSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationNoShowSettings?.value
    } else if (locationNoShowSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationNoShowSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const openChargesModal = () => {
    setChargesModalOpen(true)
  }
  const closeChargesModal = () => {
    setChargesModalOpen(false)
    setSelectedOption('standard')
    setCustomCharge('')
    setError('')
  }

  const handleChargeAndUpdateStatus = async () => {
    setLoading(true)

    let noShowCharge = 0
    let customizedNoShowCharge = selectedOption !== 'standard'

    if (locationNoShowSettings?.charge) {
      if (selectedOption === 'standard') {
        noShowCharge = parseFloat(defaultCancellationCharges)
      } else if (selectedOption === 'waive') {
        noShowCharge = 0
      } else {
        noShowCharge = parseFloat(customCharge)
      }
    } else {
      customizedNoShowCharge = false
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'No Show',
        paidExternal: true,
        noShowCharge,
        customizedNoShowCharge,
      }
      await axios.patch(`/appointment/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithNoShowCharge = {
        ...appointment?.payment,
        noShowCharge,
      }

      await axios.patch(`/appointment?id=${appointment?._id}`, {
        payment: paymentObjectWithNoShowCharge,
      })

      await getAppointment()
    } catch (error) {
      // Handle error
    }

    setLoading(false)
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customCharge, 2)
    setCustomCharge(formattedValue)
  }, [customCharge])
  const options = [
    {
      value: 'standard',
      label: 'Standard Cancellation Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged a cancellation fee of `,
      amount: `$${defaultCancellationCharges}`,
    },
    {
      value: 'custom',
      label: 'Custom Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged an amount you choose.`,
      customInput: true,
    },
    {
      value: 'waive',
      label: 'Waive Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will not be charged for missing the appointment.`,
    },
  ]
  return (
    <>
      <IonButton color={'dark'} shape='round' onClick={openChargesModal} style={styles.button} fill='solid'>
        No Show
      </IonButton>
      <IonModal isOpen={chargesModalOpen} onDidDismiss={closeChargesModal}>
        <CommonHeader closeButton={true} closeModal={closeChargesModal} title={'No Show'}></CommonHeader>
        <IonContent>
          <StandardContainer>
            <div style={styles.modalContent}>
              {locationNoShowSettings?.charge && (
                <div style={styles.chargeContainer}>
                  <div style={styles.chargeHeader}>
                    <div style={styles.chargeTitle}>
                      <div style={styles.titleText}>Mark as no show</div>
                    </div>
                    <div style={styles.subTitle}>
                      <div style={styles.subTitleText}>Choose how you want to proceed below.</div>
                    </div>
                  </div>
                  <div style={styles.radioGroup}>
                    <IonRadioGroup value={selectedOption} onIonChange={(e) => handleOptionChange(e.detail.value)}>
                      {options.map((option) => (
                        <div onClick={() => handleOptionChange(option.value)} key={option.value} style={styles.optionContainer(option.value == 'custom')}>
                          <div style={styles.optionInnerContainer}>
                            <div style={styles.optionTextContainer}>
                              <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                <div style={styles.optionTextHeader}>{option.label}</div>
                              </div>
                              <div style={styles.optionDescription}>
                                <span style={styles.descriptionText}>{option.description}</span>
                                {option.amount && <span style={styles.amountText}>{option.amount}</span>}
                              </div>
                              {option.customInput && selectedOption === 'custom' && (
                                <>
                                  <IonInput
                                    style={styles.customInput}
                                    fill='outline'
                                    type='number'
                                    value={customCharge}
                                    onInput={handleCustomChargeChange}
                                    placeholder='0'
                                    inputmode='decimal'
                                  />
                                  {error && <IonLabel style={styles.errorLabel}>{error}</IonLabel>}
                                </>
                              )}
                            </div>
                            <div style={styles.ionRadio}>
                              <IonRadio value={option.value} slot='start' mode='md' />
                            </div>
                          </div>
                        </div>
                      ))}
                    </IonRadioGroup>
                  </div>
                </div>
              )}
              {locationNoShowSettings?.charge && (
                <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                  <IonButton
                    disabled={
                      loading ||
                      (locationNoShowSettings?.charge
                        ? (selectedOption !== 'standard' && selectedOption !== 'waive' && customCharge === '') || error !== ''
                        : false)
                    }
                    onClick={handleChargeAndUpdateStatus}
                    style={styles.chargeButton}
                  >
                    <div style={styles.buttonInner}>
                      {loading && <IonSpinner style={{ marginRight: '0.5rem' }} name='lines' />}
                      <div style={styles.buttonText}>
                        {selectedOption === 'standard' && `Charge $${defaultCancellationCharges}`}
                        {selectedOption === 'custom' && `Charge $${customCharge}`}
                        {selectedOption === 'waive' && 'Waive Fee'}
                      </div>
                    </div>
                  </IonButton>
                </div>
              )}
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}

// typography.js

const fontSizes = {
  size6: '6px',
  size7: '7px',
  size8: '8px',
  size9: '9px',
  size10: '10px',
  size11: '11px',
  size12: '12px',
  size13: '13px',
  size14: '14px',
  size15: '15px',
  size16: '16px',
  size17: '17px',
  size18: '18px',
  size19: '19px',
  size20: '20px',
  size21: '21px',
  size22: '22px',
  size23: '23px',
  size24: '24px',
  size25: '25px',
  size26: '26px',
  size27: '27px',
  size28: '28px',
  size29: '29px',
  size30: '30px',
  size31: '31px',
  size32: '32px',
  size33: '33px',
  size34: '34px',
  size35: '35px',
  size36: '36px',
  size37: '37px',
  size38: '38px',
  size39: '39px',
  size40: '40px',
  size41: '41px',
  size42: '42px',
  size43: '43px',
  size44: '44px',
  size45: '45px',
  size46: '46px',
  size47: '47px',
  size48: '48px',
  size49: '49px',
  size50: '50px',
  size51: '51px',
  size52: '52px',
  size53: '53px',
  size54: '54px',
  size55: '55px',
  size56: '56px',
  size57: '57px',
  size58: '58px',
  size59: '59px',
  size60: '60px',
}

const fontWeights = {
  weight100: 100,
  weight200: 200,
  weight300: 300,
  weight400: 400,
  weight500: 500,
  weight600: 600,
  weight700: 700,
  weight800: 800,
  weight900: 900,
  weightBold: 'bold',
}

export { fontSizes, fontWeights }

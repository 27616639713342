import { useState, useEffect } from 'react'
import axios from 'axios'
import Avatar from '../commonComponents/avatar'
import { IonAvatar, IonSpinner } from '@ionic/react'
import PickBarberStyles from './Styles/PickBarberStyles'
export default function StepPickBarber({ businessId, scheduleData, updateScheduleData, step, stepNext, stepBack, totalSteps, trackStep }) {
  const [employees, setEmployees] = useState()

  useEffect(() => {
    getEmployeesList()
  }, [scheduleData?.location?._id, businessId])

  useEffect(() => {
    trackStep('Barber', step, scheduleData)
  }, [])

  const getEmployeesList = async () => {
    let response = await axios.get(`/employee/getPublicList?businessId=${businessId}&locationId=${scheduleData?.location?._id}`)
    setEmployees(response.data)
  }

  function selectEmployee(employee) {
    updateScheduleData('barber', employee)
    stepNext()
  }
  function selectAnyProfessional() {
    updateScheduleData('barber', 'Anyone')
    stepNext()
  }

  if (!employees)
    return (
      <div style={PickBarberStyles.loadingBox}>
        <IonSpinner name='dots' />
      </div>
    )

  return (
    <>
      <div style={PickBarberStyles.container}>
        <div style={PickBarberStyles.header}>
          <div style={PickBarberStyles.title}>Select Stylist</div>
        </div>
        <div style={PickBarberStyles.content}>
          <Card onClick={selectAnyProfessional} avatarSrc='/assets/logos/blackCircle.png' name='Any Professional' />
          {employees.map((e) => (
            <Card
              key={e._id}
              onClick={() => selectEmployee(e)}
              avatarComponent={<Avatar employee={e} businessId={businessId} small />}
              name={`${e.firstName} ${e.lastName?.charAt(0)}`}
            />
          ))}
        </div>
      </div>
    </>
  )
}

function Card({ onClick, avatarSrc, avatarComponent, name }) {
  return (
    <div onClick={onClick} style={PickBarberStyles.barberCard}>
      <div style={PickBarberStyles.barberCardInner}>
        <div style={PickBarberStyles.barberCardContent}>
          <div style={PickBarberStyles.barberCardRow}>
            <div style={PickBarberStyles.avatarContainer}>
              <div style={PickBarberStyles.avatar}>
                {avatarComponent || (
                  <IonAvatar>
                    <img alt='Avatar' src={avatarSrc} style={{ height: 40, width: 40, borderRadius: 35 }} />
                  </IonAvatar>
                )}
              </div>
            </div>
            <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
              <div style={PickBarberStyles.barberCardRow}>
                <div style={PickBarberStyles.barberName}>{name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

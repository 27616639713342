import { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import PickCustomerStyles from './Styles/PickCustomerStyles'
import { IonAvatar, IonIcon, IonSearchbar, IonSpinner } from '@ionic/react'
import { personOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import { debounce } from 'lodash'

export default function StepPickCustomer({ locationId, updateScheduleData, stepNext }) {
  const { businessData } = useContext(AuthContext)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false) // Initialize loading state as false
  useEffect(() => {
    getCustomersList(true)
  }, [])

  const getCustomersList = async (showLoader) => {
    if (showLoader) {
      setLoading(true)
    }
    let locQuery = locationId ? `&locationId=${locationId}` : ''

    let response = await axios.get(`/customer/getList?businessId=${businessData._id}${locQuery}`)
    setCustomers(response.data)
    if (showLoader) {
      setLoading(false)
    }
  }

  function handleCustomerSelection(customer) {
    updateScheduleData('customer', customer)
    stepNext('location')
  }
  const handleSearchValue = async (value) => {
    if (value !== '') {
      try {
        const searchResponse = await axios.get(`/customer/search?searchText=${value}`)

        if (searchResponse?.data) {
          setCustomers(searchResponse.data)
        }
      } catch (error) {
        console.error('Error searching customers:', error)
      } finally {
      }
    } else {
      await getCustomersList()
    }
  }
  const debouncedHandleSearchValue = useRef(debounce((value) => handleSearchValue(value), 500)).current
  if (loading)
    return (
      <div style={PickCustomerStyles.loadingBox}>
        <IonSpinner name='dots' />
      </div>
    )

  return (
    <>
      <div style={PickCustomerStyles.container}>
        <div style={PickCustomerStyles.header}>
          <div style={PickCustomerStyles.title}>Select Customer</div>
        </div>
        <IonSearchbar
          style={{ padding: 0 }}
          color='light'
          placeholder='Search...'
          mode='ios'
          onIonInput={(e) => {
            debouncedHandleSearchValue(e.detail.value)
          }}
        />

        <div style={PickCustomerStyles.customerList}>
          {customers.map((e, index) => (
            <CustomerCard index={index} key={e._id} customer={e} handleCustomerSelection={handleCustomerSelection} />
          ))}
        </div>
      </div>
    </>
  )
}

function CustomerCard({ index, customer, handleCustomerSelection }) {
  return (
    <>
      <div onClick={() => handleCustomerSelection(customer)} style={PickCustomerStyles.customerCard}>
        <div style={PickCustomerStyles.customerCardRow}>
          <div style={PickCustomerStyles.customerCardContent}>
            <div style={PickCustomerStyles.avatarContainer}>
              <IonAvatar
                style={{ backgroundColor: colors.black10, padding: 10, height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <IonIcon icon={personOutline} />
              </IonAvatar>
            </div>
            <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
              <div style={PickCustomerStyles.customerName}>{customer.firstName + ' ' + customer.lastName}</div>
              <div style={PickCustomerStyles.customerPhone}>{customer.phoneNumber}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonModal, IonRow } from '@ionic/react'
import { storefrontOutline } from 'ionicons/icons'
import { useContext, useState } from 'react'
import { AuthContext } from '../auth'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import EachLocationPermission from './EachLocationPermission'
export default function LocationPermissions({ user, refreshUser }) {
  const { locationData } = useContext(AuthContext)
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(false)
  const openModal = (location) => {
    setModalOpen(true)
    setSelectedLocation(location)
  }
  const closeModal = (location) => {
    refreshUser()
    setModalOpen(false)
    setSelectedLocation(false)
  }

  return (
    <IonGrid>
      <IonRow style={{ display: 'flex', alignItems: 'end' }}>
        <IonIcon style={{ marginRight: '10px' }} icon={storefrontOutline} size='large' />
        <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>Location Permissions</h6>
      </IonRow>

      <IonList lines='none' mode='ios' style={{ marginTop: '1rem' }}>
        {locationData &&
          locationData.map((location, locationIndex) => {
            return (
              <IonItem
                onClick={() => openModal(location)}
                // routerLink={`/core/location/entitlements/${location._id}`}
                detail={true}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <IonCol>
                  <h6 style={{ margin: '5px 0', fontWeight: '600' }} variant={'subtitle1'}>
                    {location.name}
                  </h6>
                  <h6 style={{ margin: '5px 0', fontSize: 12, color: 'grey' }} variant={'p'}>
                    {location.addressStreet1}
                    {location.addressCity}, {location.addressState} {location.addressZipcode}
                  </h6>
                </IonCol>
              </IonItem>
            )
          })}
      </IonList>
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={selectedLocation?.name} closeButton={true} closeModal={closeModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <EachLocationPermission user={user} location_id={selectedLocation?._id} />
          </StandardContainer>
        </IonContent>
      </IonModal>
    </IonGrid>
  )
}

import { IonCol, IonText, IonRadioGroup, IonIcon, IonItem, IonLabel, IonList, IonPage, IonInput } from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { AuthContext } from '../auth'
import { businessOutline, storefrontOutline, checkmarkOutline, warningOutline, codeWorkingOutline, linkOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import StripeOnboardingButton from '../business/StripeOnboardingButton'
import { useContext } from 'react'
import StripeDashboardLinkButton from '../business/StripeDashboardLinkButton'

export default function PaymentProcessorSettings({ location, updateLocation }) {
  let { selectedLocationData } = useContext(AuthContext)


  let success = <div style={{ display: 'flex', alignItems: 'center', gap: '.2rem', backgroundColor: "#cff5f6", borderRadius: 4, fontSize: 14, padding: 2, paddingLeft: 10, color: '#0055bc', fontWeight: '500' }} >Enabled <IonIcon style={{ color: "#0055bc" }} icon={checkmarkOutline} /></div>
  let failure = <div style={{ display: 'flex', alignItems: 'center', gap: '.2rem', backgroundColor: colors.orange90, borderRadius: 4, fontSize: 14, padding: 2, paddingLeft: 10, color: colors.orangeDeep, fontWeight: '500' }} >Disabled <IonIcon style={{ color: colors.orangeDeep }} icon={warningOutline} /></div>

  const billingItems = [
    { label: 'Charges', value: selectedLocationData?.stripeConnect?.account?.charges_enabled },
    { label: 'Payouts', value: selectedLocationData?.stripeConnect?.account?.payouts_enabled },
    { label: 'Details Submitted', value: selectedLocationData?.stripeConnect?.account?.details_submitted },
  ]

  const onValueChange = (field, value) => {
    updateLocation({ ...location, [field]: value })
  }

  if (!location) return <IonText>Loading...</IonText>
  const paymentProcessorType = [
    {
      value: 'envaccount',
      label: 'Env Driven Stripe Account',
      description: 'Use this only for the Highlands Ranch ClubCuts location',
      icon: codeWorkingOutline
    },
    {
      value: 'stripeconnect',
      label: 'Connected Account',
      description: 'Use this for all locations that are setup with a Stripe Connect account',
      icon: linkOutline
    }
  ];

  return (

    <StandardContainer>



      <div style={{ padding: 24 }}>
        <IonRadioGroup value={location.paymentProcessorType} onIonChange={(e) => onValueChange('paymentProcessorType', e.detail.value)}>
          {paymentProcessorType.map((option) => (
            <IonItem
              key={option.value}
              lines="none"
              button
              detail={false}
              onClick={() => onValueChange('paymentProcessorType', option.value)}
              style={{
                border: location.paymentProcessorType === option.value ? `2px solid ${colors.primaryBlue}` : '1px solid #dcdcdc',
                borderRadius: '10px',
                marginBottom: '1rem',
                backgroundColor: location.paymentProcessorType === option.value ? 'red' : 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <IonLabel style={{ padding: '1rem' }}>
                <h2 style={{ fontWeight: 'bold', margin: 0 }}>{option.label}</h2>
                <p style={{ margin: 0, color: '#6c757d' }}>{option.description}</p>
              </IonLabel>
              <IonIcon icon={option.icon} style={{
                fontSize: '24px',
                color: location.paymentProcessorType === option.value ? `${colors.primaryBlue}` : 'gray'
              }} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </div>

      {/* Stripe Connect Settings */}
      {location.paymentProcessorType === 'envaccount' ?
        <>
          <div>
            <div style={{ margin: 20 }}>
              <h6>Stripe Environment Value</h6>
              <p>This is the environment variable that refrences the API key for the stripe account.</p>
            </div>

            <IonItem>

              <IonInput
                mode='md'
                fill='outline'
                value={location.stripeEnvValue}
                onIonInput={(e) => onValueChange('stripeEnvValue', e.detail.value)}
                placeholder="Enter Stripe environment value"
              />
            </IonItem>
          </div>
        </>

        : null}

      {/* Stripe Connect Settings */}
      {location.paymentProcessorType === 'stripeconnect' ?
        <>
          <div style={{ padding: '0 1rem' }}>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              {billingItems.map((item, index) => (
                <IonItem key={index}>
                  <IonCol size='9'>
                    <IonLabel>{item.label}</IonLabel>
                  </IonCol>
                  <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                    {item.value ? success : failure}
                  </IonCol>
                </IonItem>
              ))}
            </IonList>
          </div>
          <div style={{ marginLeft: 16 }}>
            <StripeOnboardingButton locationId={selectedLocationData._id} title={'Change or Update Billing Settings'} />
          </div>
          <div style={{ marginLeft: 16 }}>
            <StripeDashboardLinkButton locationId={selectedLocationData._id} />
          </div>
        </>
        : null}
    </StandardContainer>
  )
}

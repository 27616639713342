import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { add } from 'ionicons/icons'
import { GetReviewStylesBasedOnStatus } from '../RequestCustomerReviews/GetReviewStylesBasedOnStatus'
import axios from 'axios'

export default function AcquisitionSettings() {
  const disabledStyles = GetReviewStylesBasedOnStatus('disabled')
  const [referenceList, setReferenceList] = useState([])
  const [loading, setLoading] = useState(true)
  const getAcquisitionList = async () => {
    setLoading(true)

    try {
      const response = await axios.get('customerAcquisition/reference/list')

      if (response?.data?.length > 0) {
        setReferenceList(response.data)
      }
      setLoading(false)
    } catch (error) {}
  }
  // Fetch the list when the component is about to enter
  useIonViewWillEnter(() => {
    getAcquisitionList()
  })
  useEffect(() => {
    getAcquisitionList()
  }, [])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Acquisition Settings'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '1rem' }}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Source</IonLabel>
                </IonCol>
                <IonCol size='auto' style={{ justifyContent: 'end', alignItems: 'end', display: 'flex' }}>
                  <IonButtons>
                    <IonButton routerLink='/core/acquisitionReference'>
                      <IonLabel color={'primary'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IonIcon style={{ marginRight: '10px' }} icon={add} />
                        New Tag
                      </IonLabel>
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner name='dots' />
              </div>
            )}
            {!loading && referenceList && referenceList.length > 0 && (
              <IonList mode='ios'>
                {referenceList?.map((item, index) => {
                  return (
                    <IonItem detail={true} routerLink={`/core/acquisitionReference/${item._id}`}>
                      <IonRow
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <IonCol>
                          <IonText>{item.name}</IonText>
                        </IonCol>
                        {/* <IonCol
											size='auto'
											style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
										>
											<IonText style={disabledStyles}>{'Default'}</IonText>
										</IonCol> */}
                      </IonRow>
                    </IonItem>
                  )
                })}
                {/* <IonItem detail={true} routerLink='/core/acquisitionReference'>
							My Custom Tag
						</IonItem> */}
              </IonList>
            )}
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import { IonIcon, IonModal, IonButton, IonPopover, IonItem, IonLabel } from '@ionic/react'
import { ellipsisHorizontal } from 'ionicons/icons'
import { useState } from 'react'
import axios from 'axios'
import ConfirmationModal from '../commonComponents/ConfirmationModal'

export default function DeleteEmailStep({ stepData, emailSteps, smtpSettingsId, ruleName, audiences, id, refreshRemarketingDetails, refreshEmailSteps, mode }) {
  const styles = {
    exportToCSVTitleStyle: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'grey',
    },
    moreIconStyle: {
      height: 22,
      width: 22,
      color: 'grey',
    },
  }

  const [popoverState, setPopoverState] = useState({ showPopover: false, event: undefined })
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deletingData, setDeletingData] = useState(false)

  const handleDeleteModal = (value) => {
    setDeleteModalVisible(value)
    // Close the popover when opening/closing the modal
    setPopoverState({ showPopover: false, event: undefined })
  }

  const removeObjectById = (dataArray, targetId) => {
    return dataArray.filter((item) => item.id !== targetId)
  }

  const updateRemarketingRules = async (input) => {
    try {
      const updateRemarketingResponse = await axios.patch(`/emailRemarketing/rule/?id=${id}`, input)
      if (updateRemarketingResponse.status === 200) {
        setDeletingData(false)
        handleDeleteModal(false)
        refreshRemarketingDetails()
      }
    } catch (error) {
      console.error('Error updating remarketing rules:', error)
    }
  }

  const handleDelete = () => {
    if (mode !== 'add') {
      setDeletingData(true)
    }

    let updatedStepsData = removeObjectById(emailSteps, stepData.id)
    const remarketingInput = {
      smtpSettingsId: smtpSettingsId,
      ruleName: ruleName,
      audiences: audiences,
      emailSteps: updatedStepsData,
    }

    if (mode === 'add') {
      handleDeleteModal(false)
      refreshEmailSteps(updatedStepsData)
    } else {
      updateRemarketingRules(remarketingInput)
    }
  }

  return (
    <>
      <IonButton fill='clear' onClick={(e) => setPopoverState({ showPopover: true, event: e })} id='basic-button'>
        <IonIcon size='large' style={styles.moreIconStyle} icon={ellipsisHorizontal} />
      </IonButton>

      <IonPopover isOpen={popoverState.showPopover} event={popoverState.event} onDidDismiss={() => setPopoverState({ showPopover: false, event: undefined })}>
        <IonItem button onClick={() => handleDeleteModal(true)}>
          <IonLabel style={styles.exportToCSVTitleStyle}>Delete Email Step</IonLabel>
        </IonItem>
      </IonPopover>

      {deleteModalVisible && (
        <IonModal isOpen={deleteModalVisible}>
          <ConfirmationModal
            isOpen={deleteModalVisible}
            message='Are You Sure You want to delete this rule?'
            cancel={() => handleDeleteModal(false)}
            loading={deletingData}
            save={handleDelete}
            saveText='Delete'
          />
        </IonModal>
      )}
    </>
  )
}

import React, { useContext } from 'react';
import { IonImg, IonRouterLink } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../auth';
import { colors } from '../theme/colors';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';

const Header = ({ type, scrollPosition = 0, enablePromoBanner }) => {
    const history = useHistory();
    const { userData } = useContext(AuthContext);
    const logoURL = 'assets/scixxor/scixxor_logo.png';
    const darkLogo = '/assets/scixxor/scixxor_logo.png'

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const isSmall = vw <= 500;

    // JSON configuration for menu links
    const menuLinks = [
        { link: "/about", label: "ABOUT" },
        { link: "/features", label: "FEATURES" },
        { link: "/insights", label: "INSIGHTS" },
        { link: "/pricing", label: "PRICING", condition: () => !userData?.isSubscribed },
        { link: "/login", label: "LOGIN", condition: () => !userData?._id, textColor: colors.primaryBlue },
        { link: "/signup", label: "SIGN UP", condition: () => !userData?._id, textColor: colors.primaryBlue },
        { link: "/core/dashboard", label: "DASHBOARD", condition: () => userData?._id, textColor: colors.primaryBlue, buttonType: 'contain' },
    ];

    const renderMenuItems = (textColor = 'black') => {
        return menuLinks.map(({ link, label, condition = () => true, textColor: customTextColor }) => {
            if (!condition()) return null;
            return <MenuItem key={link} link={link} label={label} textColor={customTextColor || textColor} />;
        });
    };

    if (!isSmall && (type !== 'transparent' && scrollPosition === 0)) {
        return (
            <>
                <StandardCenterCenter>
                    <div style={{ marginBottom: -50, width: '100%', height: 50, zIndex: 1000, display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                            <IonRouterLink routerLink='/'><IonImg src='/assets/scixxor/scixxor_logo.png' style={{height:35, marginLeft:20, marginRight:20, marginTop:4}}/></IonRouterLink>
                            {renderMenuItems()}
                        </div>
                    </div>
                </StandardCenterCenter>
            </>
        );
    }

    if (!isSmall && (type === 'white' || (type === 'transparent' && scrollPosition > 0))) {
        return (
            <>
                <StandardCenterCenter>
                    <div style={{ boxShadow: '0px 1px 3px #ededed', marginBottom: -50, width: '100%', height: 50, zIndex: 1000, display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                            <IonRouterLink routerLink='/'><IonImg style={{height:35, marginLeft:20, marginRight:20, marginTop:4}} src={darkLogo} alt="Scixxor" /></IonRouterLink>
                            {renderMenuItems()}
                        </div>
                    </div>
                </StandardCenterCenter>
            </>
        );
    }

    if (!isSmall && type === 'transparent') {
        return (
            <>
                <StandardCenterCenter>
                    <div style={{ marginBottom: -50, width: '100%', height: 50, zIndex: 1000, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IonRouterLink routerLink='/'><IonImg style={{ height: 25, width: 210 }} src={logoURL} /></IonRouterLink>
                        {renderMenuItems('white')}
                    </div>
                </StandardCenterCenter>
            </>
        );
    }

    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', boxShadow: '0px 1px 3px #ededed', marginBottom: -50, width: '100%', zIndex: 1000, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>
            <IonRouterLink routerLink='/'><IonImg style={{ height: 25, width: 210, marginTop: 12.5, marginBottom: 12.5 }} src={darkLogo} alt="Scixxor" /></IonRouterLink>
        </div>
    );
};

function MenuItem({ textColor = 'black', label, link, buttonType = 'clear' }) {
    return (
        <IonRouterLink routerLink={link} routerDirection='none'>
            <div style={{ fontWeight: '600', fontFamily: 'Montserrat, Avenir, Arial, Helvetica, sans-serif', fontSize: 12, color: textColor, cursor: 'pointer', padding: '0px 10px' }}>
                {label}
            </div>
        </IonRouterLink>
    );
}



export default Header;

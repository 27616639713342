import React, { useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { IonButton, IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonSpinner } from '@ionic/react'
import { card, cardOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'

// const stripePromise = loadStripe("pk_test_51MhT4FLsxOYoSyqslgv41QFIc4HDAieQxEocHJoshBEqCZpaeMf5yTiZBMMcmXn9dcdhqWWi0vW6r4gkQEzAbI2x00Sy0WiFii");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const styles = {
  element: {
    height: 40,
    padding: '10px 12px',
    fontSize: 16,
  },
  submitButton: {
    height: 40,
    borderRadius: 4,
    backgroundColor: colors.primaryBlue,
    color: 'white',
  },
}

const PaymentForm = ({ buttonText, addCardToFileSuccess, customerId }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setSubmitting(true)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (error) {
      setErrorMessage(error.message)
    } else {
      try {
        // Send the PaymentMethod ID to your server to save the payment details to the user's Stripe customer
        const { data } = await axios.post(`/customer/addCardStripe`, {
          paymentMethodId: paymentMethod.id,
          customerId: customerId,
        })

        // { success: true }
        // Reset the form fields
        const cardNumberElement = elements.getElement(CardNumberElement)
        const cardExpiryElement = elements.getElement(CardExpiryElement)
        const cardCvcElement = elements.getElement(CardCvcElement)
        if (data.status == 500) {
          setErrorMessage(data.message)
        } else {
          addCardToFileSuccess()
        }
        setTimeout(() => {
          cardNumberElement.clear()
          cardExpiryElement.clear()
          cardCvcElement.clear()
          addCardToFileSuccess()
        }, 2000)
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    setSubmitting(false)
  }

  return (
    <form onSubmit={handleSubmit} style={{ margin: 10 }}>
      <IonGrid
        style={{
          border: '1px solid #dedede',
          borderRadius: 4,
          margin: 0,
          padding: 0,
          marginBottom: 10,
        }}
      >
        <IonRow>
          <IonCol
            size='auto'
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 12,
              backgroundColor: 'white',
            }}
          >
            <IonIcon icon={cardOutline} size='large' />
          </IonCol>
          <IonCol style={{ padding: 0, height: 50 }}>
            <CardNumberElement style={styles.element} id='cardNumber' />
          </IonCol>
        </IonRow>
        <IonRow style={{ borderTop: '1px solid #d6d6d6' }}>
          <IonCol style={{ padding: 0 }}>
            <CardExpiryElement id='cardExpiry' />
          </IonCol>
          <IonCol style={{ borderLeft: '1px solid #d6d6d6', padding: 0 }}>
            <CardCvcElement className='StripeElement' id='cardCvc' />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton expand='block' type='submit' disabled={submitting} style={styles.submitButton}>
        {!submitting ? <>{buttonText}</> : <IonSpinner name='dots' />}
      </IonButton>
      {errorMessage && <div style={{ marginTop: 10, color: 'red' }}>{errorMessage}</div>}
    </form>
  )
}

const AddCardToFile = ({ buttonText, addCardToFileSuccess, customerId }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm buttonText={buttonText} addCardToFileSuccess={addCardToFileSuccess} customerId={customerId} />
    </Elements>
  )
}

export default AddCardToFile

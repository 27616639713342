import axios from 'axios'
import { useContext, useState } from 'react'
import CustomInput from '../../../SetupBusiness/CustomInput'
import { AuthContext } from '../../../auth'
import ButtonPanel from '../buttonPanel'
import FlowError from '../error'

const PersonalForm = ({ data, flowIndex, onBack, onNext, onCancel, history }) => {
  const { userData } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [error, setError] = useState('')
  const handleFirstName = (value) => setFirstName(value)
  const handleLastName = (value) => setLastName(value)

  const handleNext = async () => {
    try {
      await axios.patch(`/user`, { email: userData.email, firstName, lastName })
      setError('')
      onNext()
    } catch (error) {
      setError(error?.response?.data?.error)
      console.log('ERROR', error)
    }
  }

  return (
    <>
      <p>Let's start out with getting to know you.</p>
      <CustomInput label={'What is your first name? *'} value={firstName} placeholder='first name' handleInput={handleFirstName} />
      <CustomInput label={'What is your last name? *'} value={lastName} placeholder='last name' handleInput={handleLastName} />
      <FlowError message={error} />
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} />
    </>
  )
}

export default PersonalForm

import React from 'react'
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage } from '@ionic/react'
import {
  bagCheckOutline,
  barChartOutline,
  cardOutline,
  chatboxEllipsesOutline,
  cubeOutline,
  cutOutline,
  globeOutline,
  listOutline,
  locationOutline,
  magnet,
  magnetOutline,
  mailOutline,
  notificationsOutline,
  peopleCircleOutline,
  personOutline,
  pricetagOutline,
  starOutline,
  storefrontOutline,
} from 'ionicons/icons'
import { AuthContext } from './auth'
import StandardContainer from './commonComponents/StandardContainer'
import CheckIsManagerOrAdmin from './Utils/CheckIsManagerOrAdmin'
import GetLocationDataBasedOnLocationId from './Utils/GetLocationDataBasedOnLocationId'

const moreItems = [
  {
    icon: cardOutline,
    label: 'Transactions',
    link: '/core/transactions',
    adminOnly: true,
  },
  {
    icon: barChartOutline,
    label: 'Reports',
    link: '/core/reports',
    adminOnly: true,
  },
  {
    icon: personOutline,
    label: 'Subscriptions',
    link: '/core/subscriptions',
  },
  {
    icon: listOutline,
    label: 'Subscription Packages',
    link: '/core/subscriptionPackages',
  },
  {
    icon: storefrontOutline,
    label: 'Corporate Deals',
    link: '/core/corporateDeals',
  },
  {
    icon: cutOutline,
    label: 'Services',
    link: '/core/services',
  },
  {
    icon: cubeOutline,
    label: 'Product',
    link: '/core/products',
  },
  {
    icon: pricetagOutline,
    label: 'Discounts & Coupons',
    link: '/core/discounts',
  },
  {
    icon: bagCheckOutline,
    label: 'Checkout',
    link: '/core/checkout',
  },
  // Add more items as needed
]

const marketingItems = [
  {
    icon: starOutline,
    label: 'Request Customer Reviews',
    link: '/core/customerReviews',
    adminOnly: true,
  },
  {
    icon: mailOutline,
    label: 'Email Remarketing',
    link: '/core/emailRemarketing',
    adminOnly: true,
  },
  {
    icon: magnetOutline,
    label: 'Customer Acquisition',
    link: '/core/customerAcquisition',
    adminOnly: true,
  },
  {
    icon: notificationsOutline,
    label: 'Email Reminders',
    link: '/core/emailReminders',
    adminOnly: true,
  },
  // Add more marketing items as needed
]

const userItems = [
  {
    icon: peopleCircleOutline,
    label: 'Users',
    link: '/core/users',
  },
  {
    icon: chatboxEllipsesOutline,
    label: 'Messages',
    link: '/core/messages',
  },
  // Add more user items as needed
]

function MoreItem({ item }) {
  return (
    <IonItem button detail={true} routerLink={item.link} routerAnimation={'none'}>
      <IonIcon icon={item.icon} slot='start' />
      <IonLabel>{item.label}</IonLabel>
    </IonItem>
  )
}

function More() {
  const { logout, userData, locationData, businessData, locationId } = React.useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  return (
    <IonPage>
      <IonContent>
        <StandardContainer>
          {!userEntitlements?.noPermissions && (
            <>
              {userEntitlements?.isAdmin ? (
                <>
                  <div style={{ margin: 20 }}>
                    <h1>More</h1>
                  </div>
                  <>
                    <div style={{ margin: 20 }}>
                      <h5>Business Details</h5>
                    </div>
                    <IonList inset={true} mode='ios'>
                      <IonItem
                        button
                        detail={true}
                        routerLink={`/core/business/${businessData?._id}`}
                        routerAnimation={'none'}
                      >
                        <IonIcon icon={storefrontOutline} slot='start' />
                        <IonLabel>{businessData.name} Details</IonLabel>
                      </IonItem>

                      <IonItem
                        button
                        detail={true}
                        routerLink={`/core/businessDomains/${businessData?._id}`}
                        routerAnimation={'none'}
                      >
                        <IonIcon icon={globeOutline} slot='start' />
                        <IonLabel> Domains</IonLabel>
                      </IonItem>
                    </IonList>
                  </>
                </>
              ) : null}
              <div style={{ margin: 20 }}>
                <h5>Location Details</h5>
              </div>
              <IonList inset={true} mode='ios'>
                <IonItem
                  button
                  detail={true}
                  routerLink={`/core/locations/${selectedLocationData._id}`}
                  routerAnimation={'none'}
                >
                  <IonIcon icon={locationOutline} slot='start' />
                  <IonLabel>{selectedLocationData.name} Details</IonLabel>
                </IonItem>
                <IonItem
                  button
                  detail={true}
                  routerLink={`/core/billing`}
                  routerAnimation={'none'}
                >
                  <IonIcon icon={cardOutline} slot='start' />
                  <IonLabel>Billing & Payments</IonLabel>
                </IonItem>
                {moreItems.map(item => (
                  <MoreItem key={item.label} item={item} />
                ))}
              </IonList>

              {userEntitlements?.isAdmin && (
                <>
                  <div style={{ margin: 20 }}>
                    <h5>Marketing</h5>
                  </div>
                  <IonList inset={true} mode='ios'>
                    {marketingItems.map(item => (
                      <MoreItem key={item.label} item={item} />
                    ))}
                  </IonList>
                </>
              )}

              <div style={{ margin: 20 }}>
                <h5>Users</h5>
              </div>
              <IonList inset={true} mode='ios'>
                {userItems.map(item => (
                  <MoreItem key={item.label} item={item} />
                ))}
              </IonList>
            </>
          )}

          <div style={{ padding: 12, textAlign: 'center' }}>
            <p style={{ color: 'grey' }}>{userData?.email}</p>
            <p style={{ color: 'grey' }}>
              {userEntitlements?.isAdmin
                ? 'Admin'
                : userEntitlements?.isManager
                  ? 'Manager'
                  : userEntitlements?.isProfessional
                    ? 'Professional'
                    : ''}
            </p>
            <IonButton expand='block' color='medium' onClick={logout}>
              Logout
            </IonButton>
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

export default More

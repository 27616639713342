import React from 'react'
import { IonCard, IonInput, IonItem, IonLabel, IonRow, IonCol, IonGrid, IonText } from '@ionic/react'

export default function DetailsView({ location, updateLocation }) {
  const updateTaxRate = (value) => {
    updateLocation({ ...location, purchase: { taxRate: value } })
  }

  if (!location) return <IonText>Loading...</IonText>

  return (
    <IonCard style={{ padding: 20 }}>
      <IonGrid>
        <IonRow>
          <IonCol size='6'>
            <IonItem>
              <IonLabel position='stacked'>Tax Rate (%)</IonLabel>
              <IonInput type='number' value={location?.purchase?.taxRate || ''} onIonChange={(e) => updateTaxRate(e.detail.value)} debounce={0} />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  )
}

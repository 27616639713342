import { useState, useContext, useEffect } from 'react'
import { IonModal, IonContent, IonList, IonRow, IonItem, IonGrid, IonCol, IonLabel } from '@ionic/react'
import { AuthContext } from '../auth'

import axios from 'axios'
import StandardContainer from '../commonComponents/StandardContainer'
import CardOnFile from '../Payment/cardOnFile'
import { calculateCostPerDay, dataURLtoBlob } from '../utils'
import ChoosePackageAndPlan from './ChoosePackageAndPlan'
import SubscriptionInvoice from './SubscriptionInvoice'
import ProratedDetailsSection from './ProratedDetailsSection'
import ChangeSubscriptionActionSection from './ChangeSubscriptionActionSection'
import CommonHeader from '../commonComponents/CommonHeader'
import SignatureCanva from '../commonComponents/SignatureCanva'

function ChangeSubscriptionModal({ isOpen, setIsOpen, locationId, onSuccess, currentSubscription, alldiscounts }) {
  const { businessData } = useContext(AuthContext)
  const [customerSignatureImage, setCustomerSignatureImage] = useState(false)
  const [subscriptionPackages, setSubscriptionPackages] = useState({
    loading: false,
    data: null,
    error: null,
  })
  const [selectedPackage, setSelectedPackage] = useState(false)
  const [isCardOnFile, setIsCardOnFile] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [customProratedAmount, setCustomProratedAmount] = useState(null)
  const [proratedAmountCalculatedServer, setProratedAmountCalculatedServer] = useState({
    loading: false,
    data: null,
    error: null,
  })
  const [inputtedProratedAmount, setInputtedProratedAmount] = useState(null)
  const [isOpenChangeProratedAmountBox, setIsOpenChangeProratedAmountBox] = useState(false)
  const [officialProratedAmount, setOfficialProratedAmount] = useState(false)
  const [isErrorWithCustomInput, setIsErrorWithCustomInput] = useState(false)
  const [useCustomProrationValue, setUseCustomProrationValue] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState(false)
  const [coupon, setCoupon] = useState(false)
  const updateCustomerSignature = (value) => {
    setCustomerSignatureImage(value)
  }
  function dismiss() {
    setSelectedPackage(null)
    setIsSubscribing(false)
    setErrorMessage(null)
    setProratedAmountCalculatedServer({ loading: false, data: null, error: null })
    setIsOpen(false)
  }
  const handleSelectedPackage = (packageItem) => {
    updateCustomerSignature(false)
    setSelectedPackage(packageItem)
    setSelectedPrice(false)
  }
  const handleSelectedPrice = (price) => {
    setSelectedPrice(price)
  }
  const updateSelectedPackageAndPrice = () => {
    const packages = subscriptionPackages?.data

    const foundPackage = packages?.find((packageItem) => packageItem._id === currentSubscription?.subscriptionPackage?._id)

    if (foundPackage) {
      setSelectedPackage(foundPackage)

      const prices = foundPackage?.prices

      const foundPrice = prices.find((price) => price.stripePriceId === currentSubscription?.stripeSubscriptionPlanId)
      if (foundPrice) {
        setSelectedPrice(foundPrice)
      }
    }
    // setSelectedPackage
  }
  useEffect(() => {
    updateSelectedPackageAndPrice()
  }, [])
  useEffect(() => {
    updateSelectedPackageAndPrice()
  }, [isOpen])

  useEffect(() => {
    getSubscritpionPackages()
  }, [])

  async function getSubscritpionPackages() {
    setSubscriptionPackages({ ...subscriptionPackages, ...{ loading: true } })
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    try {
      let r = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}${locQuery}`)
      setSubscriptionPackages({ loading: false, data: r.data, error: null })
    } catch (error) {
      setSubscriptionPackages({ ...subscriptionPackages, ...{ loading: false, error } })
    }
  }

  async function getProratedCalculatedAmount() {
    setProratedAmountCalculatedServer({ ...proratedAmountCalculatedServer, ...{ loading: true } })
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    try {
      let r = await axios.get(
        `/subscription/calculateSubscriptionChange?subscriptionId=${currentSubscription?.stripeData?.id}&customerId=${currentSubscription?.customerId}&newPlanId=${selectedPrice?.stripePriceId}&businessId=${businessData._id}${locQuery}`
      )

      setProratedAmountCalculatedServer({ loading: false, data: r.data, error: null })
    } catch (error) {
      setProratedAmountCalculatedServer({
        ...proratedAmountCalculatedServer,
        ...{ loading: false, error },
      })
    }
  }

  useEffect(() => {
    if (selectedPrice) {
      getProratedCalculatedAmount()
    }
  }, [selectedPrice])
  //test comment
  const uploadCustomerSignature = async () => {
    // Convert data URL to Blob
    const dataBlob = dataURLtoBlob(customerSignatureImage)

    // Create a File object (optional)
    const file = new File([dataBlob], 'photo.jpg', { type: 'image/jpeg' })

    const data = new FormData()
    data.append('selectedFile', file)
    data.append('subscriptionId', selectedPackage._id)
    data.append('customerId', currentSubscription.customerId)

    try {
      const response = await axios.post('/subscription/customer/signature', data)

      if (response?.status === 200) {
        return true
      }
    } catch (error) {
      return false
    }
  }
  async function subscriberUserToPlan() {
    setIsSubscribing(true)
    const periodValue = `Every ${selectedPrice.interval_count === 1 ? '' : selectedPrice.interval_count} ${
      selectedPrice.interval_count > 1 ? `${selectedPrice.interval}s` : selectedPrice.interval
    }`

    const updateSubscriptionInput = {
      currentSubscription: currentSubscription._id,
      subscriptionId: selectedPackage._id,
      period: periodValue,
      businessId: businessData._id,
      customerId: currentSubscription.customerId,
      proratedAmount: officialProratedAmount,
      newPlanId: selectedPrice.stripePriceId,
      price: selectedPrice.amount,
    }
    if (coupon && coupon.stripeCouponId) {
      updateSubscriptionInput.couponId = coupon.stripeCouponId
    }
    try {
      const customerSignResponse = await uploadCustomerSignature()

      const response = await axios.patch('/subscription/change', updateSubscriptionInput)
      if (response.status === 200) {
        dismiss()
        onSuccess()
      }
    } catch (error) {
      setIsSubscribing(false)
      setErrorMessage(error?.message || 'Sorry there was an issue. Please try again.')
    }
  }
  const removeCoupon = () => {
    setCoupon(false)
  }
  useEffect(() => {
    const selectedCoupon = alldiscounts && alldiscounts.find((item, index) => item.stripeCouponId == currentSubscription.couponId)

    setCoupon(selectedCoupon)
  }, [isOpen, currentSubscription, alldiscounts])

  useEffect(() => {
    let opa
    let ucpv = false

    if (customProratedAmount && !isNaN(customProratedAmount)) {
      opa = parseFloat(customProratedAmount)
      ucpv = true
    } else {
      opa = proratedAmountCalculatedServer?.data?.prorationAmount
    }
    setUseCustomProrationValue(ucpv)
    setOfficialProratedAmount(opa)

    let ewci = false
    if (isNaN(customProratedAmount)) {
      ewci = true
    }
    setIsErrorWithCustomInput(ewci)
  }, [customProratedAmount, proratedAmountCalculatedServer])
  let selectedPlanPricePerDay = false
  let currentSubscriptionPricePerDay = false
  const packages = subscriptionPackages?.data

  const foundPackage = packages?.find((packageItem) => packageItem._id === currentSubscription?.subscriptionPackage?._id)

  if (foundPackage) {
    const prices = foundPackage?.prices

    const foundPrice = prices.find((price) => price.stripePriceId === currentSubscription?.stripeSubscriptionPlanId)

    if (foundPrice) {
      currentSubscriptionPricePerDay = calculateCostPerDay(foundPrice.amount, foundPrice.interval, foundPrice.interval_count)
    }
  }

  return (
    <IonModal onDidDismiss={dismiss} isOpen={isOpen}>
      <CommonHeader title={'Change Subscription'} closeModal={dismiss} closeButton={true} />
      <IonContent className='regularBackground'>
        <StandardContainer>
          <ChoosePackageAndPlan
            title={'Select new subscription'}
            selectedPackage={selectedPackage}
            subscriptionPackages={subscriptionPackages}
            currentSubscription={currentSubscription}
            currentSubscriptionPricePerDay={currentSubscriptionPricePerDay}
            selectedPrice={selectedPrice}
            handleSelectedPrice={handleSelectedPrice}
            handleSelectedPackage={handleSelectedPackage}
          />
          {selectedPrice ? (
            <>
              <div style={{ padding: 20, paddingBottom: 0, paddingTop: 4 }}>
                <h6>2. Credit Card</h6>
              </div>
              <IonList inset={true} mode='ios'>
                <CardOnFile customerId={currentSubscription.customerId} setIsCardOnFile={setIsCardOnFile} />
              </IonList>
            </>
          ) : null}
          {}
          {isCardOnFile && selectedPrice ? (
            <>
              <div style={{ padding: 20, paddingBottom: 0, paddingTop: 4 }}>
                <h6>3. Confirm & Pay</h6>
              </div>

              <SubscriptionInvoice currentSubscription={currentSubscription} selectedPackage={selectedPackage} selectedPrice={selectedPrice} />

              <ProratedDetailsSection
                proratedAmountCalculatedServer={proratedAmountCalculatedServer}
                useCustomProrationValue={useCustomProrationValue}
                customProratedAmount={customProratedAmount}
                isOpenChangeProratedAmountBox={isOpenChangeProratedAmountBox}
                setCustomProratedAmount={setCustomProratedAmount}
                setIsOpenChangeProratedAmountBox={setIsOpenChangeProratedAmountBox}
              />

              {coupon && (
                <>
                  <IonList mode='ios' inset={true}>
                    <div style={{ padding: '0 20px', paddingBottom: 0 }}>
                      <h6>Applied Coupon</h6>
                    </div>
                    <IonItem>
                      <IonGrid>
                        <IonRow>
                          <IonCol>
                            <IonLabel>{coupon.name}</IonLabel>
                          </IonCol>
                          {/* <IonCol style={{ display: 'flex', justifyContent: 'end' }}>
                            <IonLabel onClick={removeCoupon} style={{ color: 'red' }}>
                              Remove
                            </IonLabel>
                          </IonCol> */}
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </>
              )}
              {currentSubscription?.stripeSubscriptionPlanId == selectedPrice.stripePriceId ? null : (
                <SignatureCanva isSubscribing={isSubscribing} updateCustomerSignature={updateCustomerSignature} />
              )}
              <ChangeSubscriptionActionSection
                customerSignatureImage={customerSignatureImage}
                useCustomProrationValue={useCustomProrationValue}
                customProratedAmount={customProratedAmount}
                errorMessage={errorMessage}
                isSubscribing={isSubscribing}
                isErrorWithCustomInput={isErrorWithCustomInput}
                currentSubscription={currentSubscription}
                selectedPrice={selectedPrice}
                officialProratedAmount={officialProratedAmount}
                subscriberUserToPlan={subscriberUserToPlan}
              />
            </>
          ) : null}
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}

export default ChangeSubscriptionModal

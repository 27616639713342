import { useState } from 'react'
import { IonCard, IonIcon, IonLabel, IonItem, IonGrid, IonRow, IonCol, IonToggle, IonSelect, IonSelectOption, IonInput } from '@ionic/react'
import { pencilOutline } from 'ionicons/icons'
import PhoneNumberTextField from '../commonComponents/phoneNumberTextField'
import LocationsMultiSelect from './locationsMultiSelect'
import ProfilePicDialog from './profilePicDialog'
import Avatar from '../commonComponents/avatar'

export default function DetailsView({ employee, updateEmployee, checkEmployee, locations, locationId, businessId }) {
  const [profilePicDialogOpen, setProfilePicDialogOpen] = useState(false)
  const [cacheBuster, setCacheBuster] = useState(1)

  const onValueChange = (field, value) => {
    updateEmployee({ ...employee, [field]: value })
  }

  const openProfilePicModal = () => {
    setProfilePicDialogOpen(true)
  }

  const closeProfilePicModal = () => {
    setCacheBuster(new Date().getTime())
    setProfilePicDialogOpen(false)
  }

  if (!employee) return 'loading...'

  return (
    <IonCard>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 30 }}>
        <div onClick={openProfilePicModal} style={{ cursor: 'pointer' }}>
          <Avatar employee={employee} businessId={businessId} cacheBuster={cacheBuster} />
          <IonIcon icon={pencilOutline} style={{ marginLeft: -20 }} color={'primary'} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h2>
            {employee.firstName} {employee.lastName}
          </h2>
          <h3>{employee.role}</h3>
        </div>
        <IonItem>
          <IonLabel style={{ minWidth: 'max-content', marginRight: 10 }}>Profile Visibility</IonLabel>
          <IonToggle
            checked={employee.isPublic ?? false}
            onIonChange={(e) => {
              onValueChange('isPublic', e.detail.checked)
            }}
          />
        </IonItem>
      </div>
      <IonGrid>
        <IonRow>
          <IonCol size='12' sizeMd='6'>
            <IonItem>
              <IonLabel position='stacked'>First Name</IonLabel>
              <IonInput value={employee.firstName} onIonChange={(e) => onValueChange('firstName', e.detail.value)} />
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='6'>
            <IonItem>
              <IonLabel position='stacked'>Last Name</IonLabel>
              <IonInput value={employee.lastName} onIonChange={(e) => onValueChange('lastName', e.detail.value)} />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonItem>
          <IonLabel position='stacked'>Role</IonLabel>
          <IonSelect value={employee.role} onIonChange={(e) => onValueChange('role', e.detail.value)}>
            <IonSelectOption value={'Barber'}>Barber</IonSelectOption>
            <IonSelectOption value={'Receptionist'}>Receptionist</IonSelectOption>
            <IonSelectOption value={'Office'}>Office</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Email</IonLabel>
          <IonInput value={employee.email} onIonChange={(e) => onValueChange('email', e.detail.value)} />
        </IonItem>
        <PhoneNumberTextField value={employee.phoneNumber} onChange={(e) => onValueChange('phoneNumber', e.target.value)} />
        <LocationsMultiSelect locations={locations} employeeLocations={employee.locations} onValueChange={onValueChange} locationId={locationId} />
      </IonGrid>
      <ProfilePicDialog open={profilePicDialogOpen} handleClose={closeProfilePicModal} locationId={locationId} businessId={businessId} employee={employee} />
    </IonCard>
  )
}

import {
  IonCol,
  IonInput,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonCard,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import styles from './CreditBalance.module.css'
import spacingStyles from '../../theme/spacing.module.css'
import commonStyles from '../../theme/commonstyles.module.css'
import axios from 'axios'
import CreditBalanceTransactions from './CreditBalanceTransactions'
import CardBalance from './CardBalance'
import { colors } from '../../theme/colors'

export default function CreditDetailPage({ customerId, updateLoader }) {
  const [creditModalOpen, setCreditModalOpen] = useState(false)
  const [customer, setCustomer] = useState()
  const [customerTemp, setCustomerTemp] = useState()
  const [adjustmentType, setAdjustmentType] = useState('credit')
  const [currency, setCurrency] = useState('usd')
  const [amount, setAmount] = useState('')
  const [creditChanged, setCreditChanged] = useState(false)
  const [notes, setNotes] = useState('')
  const [updatingCredit, setUpdatingCredit] = useState(false)
  const [balance, setBalance] = useState('0')
  const [newBalance, setNewBalance] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [customerStripeId, setCustomerStripeId] = useState('')
  const [transactions, setTransactions] = useState([])
  const [amountError, setAmountError] = useState(false)
  const openCreditModal = () => {
    setCreditModalOpen(true)
  }
  const closeCreditModal = () => {
    setCreditModalOpen(false)
    getCustomerCredit(customerStripeId)
  }
  const handleSelection = (e) => {
    setAdjustmentType(e.detail.value)
    setCreditChanged(true)
  }
  const handleCurrency = (e) => {
    setCurrency(e.detail.value)
    setCreditChanged(true)
  }
  const handleAmount = (e) => {
    const value = e.detail.value
    setAmount(value)
    setNewBalance(parseFloat(balance / 100 || 0) + parseFloat(adjustmentType == 'credit' ? `-${value}` : value))

    // Validate amount
    if (isNaN(value) || parseFloat(value) < 0.01 || parseFloat(value) > 100) {
      setAmountError('Amount must be between 0.01 and 100')
    } else {
      setAmountError(false)
    }

    setCreditChanged(true)
  }
  const handleNotes = (value) => {
    setNotes(value)
    setCreditChanged(true)
  }
  const handleCredit = async () => {
    setUpdatingCredit(true)

    const creditInput = {
      customerId: customerId,
      customerStripeId: customerTemp.stripeId,
      amount: adjustmentType == 'credit' ? `-${amount}` : amount,
      currency: currency,
      internalNotes: notes,
    }

    try {
      const creditResponse = await axios.post('/customer/credit', creditInput)
      if (creditResponse.status === 200) {
        setUpdatingCredit(false)
        resetData()
        closeCreditModal()
        // getCustomerDetails()
      }
    } catch (error) {
      setUpdatingCredit(false)
      closeCreditModal()
    }
  }
  const resetData = () => {
    setAmount('')
    setAdjustmentType('credit')
    setCurrency('usd')
    setNotes('')
    setNewBalance(null)
  }
  const getCustomerDetails = async () => {
    setLoadingData(true)
    updateLoader(true)
    try {
      let response = await axios.get(`/customer?id=${customerId}`)

      setCustomer(response.data)
      setCustomerTemp(response.data)
      getCustomerCredit(response?.data?.stripeId)
      getCreditTransactions(response?.data?.stripeId)
      setCustomerStripeId(response?.data?.stripeId)
    } catch (error) {
      setLoadingData(false)
      updateLoader(false)
    }
  }
  const getCreditTransactions = async (stripeId) => {
    setLoadingData(true)
    updateLoader(true)

    try {
      const creditTransactionResponse = await axios.get(`/customer/credit/history?customerStripeId=${stripeId}`)
      if (creditTransactionResponse && creditTransactionResponse.status == 200) {
        const transactions = creditTransactionResponse.data
        setTransactions(transactions)
        setLoadingData(false)
        updateLoader(false)
      }
    } catch (error) {}
  }
  const getCustomerCredit = async (stripeId) => {
    try {
      const creditResponse = await axios.get(`/customer/credit/balance?customerStripeId=${stripeId}`)

      if (creditResponse && creditResponse.status == 200) {
        const creditData = creditResponse.data
        setBalance(creditData?.ending_balance)
        setLoadingData(false)
        updateLoader(false)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getCustomerDetails()
  }, [])
  useEffect(() => {
    getCustomerDetails()
  }, [customerId, creditModalOpen])

  return (
    <>
      <IonRow className={`${commonStyles.flexCenter} ${spacingStyles.mt4}`}>
        <CardBalance loadingData={loadingData} balance={balance} />
      </IonRow>
      <IonRow className={commonStyles.flexCenter}>
        <IonButtons className={`${commonStyles.displayFlex} ${commonStyles.justifyEnd} ${spacingStyles.ptb4} ${styles.updateCreditButton}`}>
          <IonButton onClick={openCreditModal}>Update Credit</IonButton>
        </IonButtons>
      </IonRow>
      <CreditBalanceTransactions transactions={transactions} />
      <IonModal isOpen={creditModalOpen} onDidDismiss={closeCreditModal}>
        <CommonHeader progressBar={loadingData || updatingCredit} title={'Credit Balance'} closeButton={true} closeModal={closeCreditModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonCard className={spacingStyles.p4}>
              <IonRow style={{ borderBottom: '1px solid #d5d5d5', paddingBottom: '1rem' }}>
                <IonLabel style={{ fontWeight: 'bold', color: 'black' }}>Credit Balance Adjustment</IonLabel>
                <IonRow
                  style={{
                    width: '100%',
                    margin: '1rem 0',
                    borderTop: '1px solid #d5d5d5',
                    borderBottom: '1px solid #d5d5d5',
                  }}
                >
                  <IonCol
                    size='6'
                    style={{
                      margin: '1rem 0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRight: '2px solid #d5d5d5',
                      flexDirection: 'column',
                    }}
                  >
                    <IonRow>
                      <IonLabel>Starting Balance</IonLabel>
                    </IonRow>
                    <IonRow style={{ marginTop: '0.5rem ' }}>
                      <IonLabel style={{ fontSize: '1.5rem' }}>{formatDollarValue(balance / 100 || 0)}</IonLabel>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size='6'
                    style={{
                      margin: '1rem 0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <IonRow>
                      <IonLabel>New Balance</IonLabel>
                    </IonRow>
                    <IonRow style={{ marginTop: '0.5rem ' }}>
                      <IonLabel style={{ fontSize: '1.5rem', color: newBalance && newBalance < 0 ? colors.primaryBlue : 'black' }}>
                        {/* {newBalance ? `$${newBalance / 100}` : '-'} */}
                        {newBalance ? formatDollarValue(newBalance) : '-'}
                      </IonLabel>
                    </IonRow>
                  </IonCol>
                </IonRow>

                <IonRow
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '1rem',
                  }}
                >
                  <IonLabel style={{ color: 'black', fontWeight: 'bold' }}>Adjustment Type</IonLabel>
                  <IonSelect
                    style={{
                      border: '1px solid #d6d6d6',
                      borderRadius: '5px',
                      width: '50%',
                      marginTop: '0.5rem',
                    }}
                    value={adjustmentType}
                    placeholder='Choose Type'
                    onIonChange={handleSelection}
                  >
                    <IonSelectOption value='credit'>Credit</IonSelectOption>
                    <IonSelectOption value='debit'>Debit</IonSelectOption>
                  </IonSelect>
                </IonRow>
                <IonRow className={styles.ionRowContainer}>
                  <IonLabel style={{ color: 'black', fontWeight: 'bold' }}>Amount</IonLabel>
                  <IonRow style={{ width: '100%' }}>
                    <IonInput
                      fill='outline'
                      labelPlacement='stacked'
                      label='amount'
                      className={styles.inputStyle}
                      placeholder='Amount'
                      value={amount}
                      onIonChange={handleAmount}
                    >
                      <IonLabel style={{ padding: '0 10px' }} slot='start'>
                        $
                      </IonLabel>
                    </IonInput>
                    <IonSelect disabled className={styles.selectStyle} value={currency} placeholder='Choose Type' onIonChange={handleCurrency}>
                      <IonSelectOption value='usd'>USD</IonSelectOption>
                    </IonSelect>
                    {amountError && (
                      <IonLabel color='danger' className={styles.errorMessage}>
                        {amountError}
                      </IonLabel>
                    )}
                  </IonRow>
                </IonRow>
                <IonRow style={{ width: '100%' }}>
                  <IonRow>
                    <IonLabel style={{ color: 'black', fontWeight: 'bold' }}>Add Internal Note</IonLabel>
                  </IonRow>
                  <IonRow className={`${spacingStyles.w100}  ${spacingStyles.mtb2}`}>
                    <IonTextarea
                      className={styles.textAreaStyle}
                      onIonChange={(e) => {
                        handleNotes(e.detail.value)
                      }}
                      rows={5}
                      value={notes}
                      placeholder='Description'
                    ></IonTextarea>
                  </IonRow>
                </IonRow>
                <IonRow>
                  <IonLabel style={{ color: 'grey', lineHeight: '1.2rem' }}>
                    This space can be used to provide additional information about your adjustment. This is an internal note and will not be visible to your
                    customer.
                  </IonLabel>
                </IonRow>
              </IonRow>
              <div className={`${commonStyles.displayFlex} ${commonStyles.justifyEnd} ${spacingStyles.mt2}`}>
                <IonButton onClick={handleCredit} disabled={!creditChanged || amountError}>
                  {updatingCredit && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
                  Apply to credit balance
                </IonButton>
              </div>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}

function formatDollarValue(value) {
  if (value < 0) {
    const formattedValue = Math.abs(value).toFixed(2)
    return `($${formattedValue})`
  } else {
    return `$${value.toFixed(2)}`
  }
}

import { useState, useEffect } from 'react'
import { IonRow, IonCol, IonToast, IonList, IonItem, IonLabel, IonAccordion, IonAccordionGroup } from '@ionic/react'
import CustomInput from '../../../SetupBusiness/CustomInput'
import ButtonPanel from '../buttonPanel'
import axios from 'axios'

const LocationForm = ({ data, flowIndex, onBack, onNext, onCancel, history, businessData, locationData, getBusinessData }) => {
  const [locations, setLocations] = useState([])
  const [expandedAccordion, setExpandedAccordion] = useState('location-0')
  const [showErrorToast, setShowErrorToast] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const initialLocations = locationData || []
    setLocations(initialLocations.map(location => ({
      ...location,
      isEdited: false
    })))
  }, [locationData])

  const handleInputChange = (index, field, value) => {
    setLocations(prevLocations => {
      const updatedLocations = [...prevLocations]
      updatedLocations[index] = {
        ...updatedLocations[index],
        [field]: value,
        isEdited: true
      }
      return updatedLocations
    })
  }

  const saveLocation = async (location) => {
    try {
      // Delete the isEdited property before sending the location object
      delete location.isEdited;
      const response = await axios.patch(`/location?id=${location._id}`, location)
      if (response.status !== 200) {
        throw new Error('Failed to save location data')
      }
      return true
    } catch (error) {
      console.error('Error saving location data:', error)
      return false
    }
  }

  const saveAllChangedLocations = async () => {
    setLoading(true)
    const changedLocations = locations.filter(location => location.isEdited)
    const savePromises = changedLocations.map(location => saveLocation(location))
    
    try {
      const results = await Promise.all(savePromises)
      const allSaved = results.every(result => result === true)
      
      if (allSaved) {
        setLocations(prevLocations => prevLocations.map(location => ({ ...location, isEdited: false })))
        return true
      } else {
        throw new Error('Failed to save all changed locations')
      }
    } catch (error) {
      console.error('Error saving locations:', error)
      setErrorMessage('Failed to save some location data. Please try again.')
      setShowErrorToast(true)
      return false
    } finally {
      getBusinessData()
      setLoading(false)
    }
  }

  const handleNext = async () => {
    const savedSuccessfully = await saveAllChangedLocations()
    if (savedSuccessfully) {
      onNext()
    }
  }

  return (
    <>
      <p>Does {businessData?.name} have a physical location? More than one? or just working as an independent?</p>
      
      {locations.length > 0 ? (
        <IonAccordionGroup value={expandedAccordion} onIonChange={e => setExpandedAccordion(e.detail.value)}>
          {locations.map((location, index) => (
            <IonAccordion key={location._id} value={`location-${index}`}>
              <IonItem slot="header">
                <IonLabel>{location.name || `Location ${index + 1}`}</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <CustomInput label={'Location Name'} value={location.name} placeholder='' handleInput={(value) => handleInputChange(index, 'name', value)} />
                <CustomInput label={'Address'} value={location.addressStreet1} placeholder='' handleInput={(value) => handleInputChange(index, 'addressStreet1', value)} />
                <CustomInput label={'Address (line 2)'} value={location.addressStreet2} placeholder='' handleInput={(value) => handleInputChange(index, 'addressStreet2', value)} />
                <IonRow>
                  <IonCol size='4'>
                    <CustomInput label={'City'} value={location.addressCity} placeholder='' handleInput={(value) => handleInputChange(index, 'addressCity', value)} />
                  </IonCol>
                  <IonCol size='4'>
                    <CustomInput label={'State'} value={location.addressState} placeholder='' handleInput={(value) => handleInputChange(index, 'addressState', value)} />
                  </IonCol>
                  <IonCol size='4'>
                    <CustomInput label={'Zipcode'} value={location.addressZipcode} placeholder='' handleInput={(value) => handleInputChange(index, 'addressZipcode', value)} />
                  </IonCol>
                </IonRow>
                <CustomInput label={'Location Phone number? (optional)'} value={location.phoneNumber} placeholder='#0000' handleInput={(value) => handleInputChange(index, 'phoneNumber', value)} />
                {location.isEdited && (
                  <button onClick={() => saveLocation(index)} disabled={loading}>
                    {loading ? 'Saving...' : 'Save Changes'}
                  </button>
                )}
              </div>
            </IonAccordion>
          ))}
        </IonAccordionGroup>
      ) : (
        <p>No locations found. You can add locations later in the settings.</p>
      )}

      <ButtonPanel 
        data={data} 
        flowIndex={flowIndex} 
        onBack={onBack} 
        onNext={handleNext} 
        onCancel={onCancel} 
        history={history} 
        loading={loading}
      />
      
      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => setShowErrorToast(false)}
        message={errorMessage}
        duration={3000}
        color="danger"
      />
    </>
  )
}

export default LocationForm

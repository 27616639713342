import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonModal,
  IonProgressBar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonPopover,
  IonItem,
  IonList,
} from '@ionic/react'
import { add, ellipsisVerticalOutline, settingsOutline } from 'ionicons/icons'
import { useState } from 'react'
import ConfirmationModal from './ConfirmationModal'

export default function CommonHeader({
  title,
  backIcon,
  closeButton,
  closeModal,
  handleSaveButton,
  saveButton,
  saveDisabled,
  updatingData,
  showSpinner,
  deleteButton,
  deleteMessage,
  deleteTitle,
  handleDelete,
  handleDeleteModal,
  deletingData,
  deleteModalVisible,
  loading,
  settingsIcon,
  settingsLink,
  progressBar,
  actionIcon,
  addIcon,
  handleAddButton,
  menuItems = [],
  additionalToolbar,
  menuIcon,
}) {
  const styles = {
    menuItemStyle: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'grey',
      display: 'flex',
      justifyContent: 'space-between',
    },
    iconStyle: {
      height: 22,
      width: 22,
      color: 'grey',
    },
  }

  const [popoverState, setPopoverState] = useState({ showPopover: false, event: null })

  const handleDeleteModalAndMenu = (value) => {
    handleDeleteModal(value)
    handleClose()
  }

  const handleClick = (event) => {
    setPopoverState({ showPopover: true, event: event.nativeEvent })
  }

  const handleClose = () => {
    setPopoverState({ showPopover: false, event: null })
  }

  const handleMenuItemClick = (menuItem) => {
    handleClose()
    menuItem.onClick()
  }

  return (
    <IonHeader>
      <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
        {progressBar ? <IonProgressBar type='indeterminate' /> : null}

        <IonTitle class='ion-text-center'>{title}</IonTitle>
        <IonButtons slot='start'>
          {menuIcon && <IonMenuButton id='main-content' color={'primary'} />}
          {backIcon && <IonBackButton />}
          {closeButton && (
            <IonButton color={'primary'} onClick={closeModal}>
              Close
            </IonButton>
          )}
        </IonButtons>
        <IonButtons slot='end'>
          {showSpinner && updatingData && saveButton && <IonSpinner name='lines' />}
          {loading && <IonSpinner name='lines' />}
          {saveButton && (
            <IonButton disabled={saveDisabled} onClick={handleSaveButton} color={'primary'}>
              Save
            </IonButton>
          )}
          {settingsIcon && (
            <IonButton routerLink={settingsLink} style={{ cursor: 'pointer' }} disabled={saveDisabled} onClick={handleSaveButton}>
              <IonIcon icon={settingsOutline} />
            </IonButton>
          )}
          {addIcon && (
            <IonButton color={'primary'} onClick={handleAddButton}>
              <IonIcon icon={add} />
              <IonLabel>Add</IonLabel>
            </IonButton>
          )}
          {actionIcon && (
            <>
              <IonButton id='basic-button' onClick={handleClick}>
                <IonIcon size='large' style={styles.iconStyle} icon={ellipsisVerticalOutline} />
              </IonButton>
              <IonPopover isOpen={popoverState.showPopover} event={popoverState.event} onDidDismiss={handleClose}>
                <IonList>
                  {menuItems.map((menuItem, index) => (
                    <IonItem key={index} button onClick={() => handleMenuItemClick(menuItem)}>
                      <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size='auto'>
                        <IonLabel>{menuItem.label}</IonLabel>
                      </IonCol>
                      {menuItem.icon && (
                        <IonCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size='2'>
                          <IonIcon size='small' icon={menuItem.icon} style={{ marginRight: '8px' }} />
                        </IonCol>
                      )}
                    </IonItem>
                  ))}
                </IonList>
              </IonPopover>
            </>
          )}
          {deleteButton && (
            <>
              <IonButton id='basic-button' onClick={handleClick}>
                <IonIcon size='large' style={styles.iconStyle} icon={ellipsisVerticalOutline} />
              </IonButton>
              <IonPopover isOpen={popoverState.showPopover} event={popoverState.event} onDidDismiss={handleClose}>
                <IonList>
                  <IonItem
                    button
                    onClick={() => {
                      handleDeleteModalAndMenu(true)
                    }}
                    style={styles.menuItemStyle}
                  >
                    {deleteTitle}
                  </IonItem>
                </IonList>
              </IonPopover>
            </>
          )}
          {deleteModalVisible && (
            <IonModal isOpen={deleteModalVisible}>
              <ConfirmationModal
                isOpen={deleteModalVisible}
                message={deleteMessage}
                cancel={() => handleDeleteModal(false)}
                loading={deletingData}
                save={handleDelete}
                saveText={'Delete'}
              />
            </IonModal>
          )}
        </IonButtons>
      </IonToolbar>
      {additionalToolbar ? (
        <IonToolbar style={{ background: 'white' }} color={'default'}>
          {additionalToolbar}
          {additionalToolbar ? <>{loading ? <IonProgressBar type='indeterminate' /> : null}</> : null}
        </IonToolbar>
      ) : null}
    </IonHeader>
  )
}

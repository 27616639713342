import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from './auth'
import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner,
} from '@ionic/react'
import axios from 'axios'
import commonStyles from './theme/commonstyles.module.css'
import StandardCenterCenter from './commonComponents/StandardCenterCenter'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const [isSending, setIsSending] = useState(false)
  const { firebaseUser } = useContext(AuthContext)
  const redirectTo = localStorage.getItem('redirectTo')
  const isEmailValid = email => {
    // Implement your email validation logic here
    // For example, you can use regex or other validation techniques
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailPattern.test(email)
  }

  const isFormValid = () => {
    return isEmailValid(email) && password.trim() !== ''
  }

  const updateLoginTime = async firebaseId => {
    try {
      await axios.patch(`/user/login?firebaseId=${firebaseId}`)
    } catch (error) {}
  }
  const handleLogin = async () => {
    setIsSending(true)
    try {
      const response = await signInWithEmailAndPassword(getAuth(), email, password)

      const firebaseId = response?.user?.uid
      if (firebaseId) {
        updateLoginTime(firebaseId)
      }

      if (redirectTo === 'pendingInvitations') {
        // Redirect logic for pendingInvitations
        history.push('/pendingInvitations?email=' + email)
        localStorage.removeItem('redirectTo')
      } else {
        history.push('/core/dashboard')
      }
    } catch (error) {
      alert(error)
    }
    setIsSending(false)
  }

  // const handleLogin = useCallback(async event => {
  //   setIsSending(true)
  //   event.preventDefault()
  //   const { email, password } = event.target.elements
  //   try {
  //     const response = await signInWithEmailAndPassword(getAuth(), email.value, password.value)

  //     const firebaseId = response?.user?.uid
  //     if (firebaseId) {
  //       updateLoginTime(firebaseId)
  //     }

  //     if (redirectTo === 'pendingInvitations') {
  //       // Redirect logic for pendingInvitations
  //       history.push('/pendingInvitations?email=' + email)
  //       localStorage.removeItem('redirectTo')
  //     } else {
  //       history.push('/core/dashboard')
  //     }
  //   } catch (error) {
  //     alert(error)
  //   }
  //   setIsSending(false)
  // }, [])

  return (
    <IonPage>
      <IonContent className='regularBackground'>
        <StandardCenterCenter>
          <IonGrid style={{ padding: '1rem' }}>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3rem',
                marginBottom: '0.5rem',
              }}
            >
              <IonLabel style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>SCIXXOR</IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <IonLabel style={{ fontSize: '0.9rem' }}>
                The easiest way to manage and grow your salon or barbershop
              </IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop:30 }}>Login</h1>
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize:14, }}>Email</IonLabel>
              <IonInput
                style={{ width: '100%' }}
                className={commonStyles.inputStyle}
                name='Email'
                placeholder='john@example.com'
                value={email}
                onIonInput={e => setEmail(e.detail.value)}
              />
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize:14, marginTop:24 }}>Password</IonLabel>
              <IonInput
                style={{ width: '100%' }}
                className={commonStyles.inputStyle}
                name='Password'
                placeholder='password'
                type='password'
                value={password}
                onIonInput={e => setPassword(e.detail.value)}
              />
            </IonRow>
            <IonButton
              onClick={handleLogin}
              disabled={!isFormValid() || isSending} // Disable the button if the form is not valid
              style={{ margin: '1.5rem 0' }}
              color={'dark'}
              expand='block'
            >
              {isSending && <IonSpinner style={{ marginRight: '1rem' }} name='lines' />}
              Sign In
            </IonButton>
            <IonRow
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonRouterLink routerLink='/signup'>
                <h6 style={{ color: 'black', fontWeight: 'bold' }}>Register</h6>
              </IonRouterLink>
              <h6 style={{ fontWeight: 'bold' }}>Forgot Password</h6>
            </IonRow>
          </IonGrid>
        </StandardCenterCenter>
        {/* <Container component='main' maxWidth='xs'>
        <CssBaseline />

        <div>
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              padding: 20,
              flexDirection: 'column',
            }}
          >
            <Avatar style={{ backgroundColor: colors.primaryBlue }}>
              <LockOutlinedIcon />
            </Avatar>
            <h3>Employee Sign in</h3>
          </div>
          <form onSubmit={handleLogin}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
            />
            <IonButton
              type='submit'
              expand='block'
              fill='solid'
              color='primary'
              disabled={isSending}
            >
              Sign In
            </IonButton>
            <Grid container>
              <Grid item xs>
                <Link href='#' variant='body2'>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href='/signup' variant='body2'>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}></Box>
      </Container> */}
      </IonContent>
    </IonPage>
  )
}

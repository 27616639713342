import { IonButton, IonContent, IonImg, IonItem, IonLabel, IonList, IonPage, IonRow, useIonViewWillEnter } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import Acquisition from '../reports/Acquisition'
import { useLocation } from 'react-router'

export default function CustomerAcquisition() {
  const [referenceList, setReferenceList] = useState([])
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const getAcquisitionList = async () => {
    setLoading(true)

    try {
      const response = await axios.get('customerAcquisition/reference/list')

      if (response?.data?.length > 0) {
        setReferenceList(response.data)
      }
      setLoading(false)
    } catch (error) {}
  }
  // Fetch the list when the component is about to enter
  useIonViewWillEnter(() => {
    getAcquisitionList()
  })
  useEffect(() => {
    if (location.pathname == '/core/customerAcquisition') {
      getAcquisitionList()
    }
  }, [location])
  useEffect(() => {
    getAcquisitionList()
  }, [])
  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Customer Acquisition'} settingsIcon={true} settingsLink={'/core/acquisitionSettings'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          {!loading && (
            <>
              {referenceList && referenceList.length > 0 ? (
                <>
                  <Acquisition type='newaccount' title={'New Account Acquisitions'} />
                  <Acquisition type='firstappointment' title={'First Appointment Acquisitions'} />
                  <Acquisition type='allappointment' title={'All Appointments Acquisitions'} />
                </>
              ) : (
                <>
                  <div style={{ padding: ' 0 1rem 1rem 1rem' }}>
                    <IonRow style={{ marginTop: '2rem' }}>
                      <span style={{ fontSize: '3.5rem', lineHeight: '3.5rem', fontWeight: 'bold' }}>Customer Acquisition</span>
                    </IonRow>
                    <IonRow style={{ margin: '1rem 0 ' }}>
                      <IonLabel style={{ fontSize: '1.2rem' }}>Better understand where you acquire customer digitally</IonLabel>
                    </IonRow>
                    <IonRow style={{ margin: '1rem 0 ' }}>
                      <IonButton routerLink='/core/acquisitionSettings' style={{ width: '13rem' }}>
                        Get Started
                      </IonButton>
                    </IonRow>
                  </div>
                  <IonImg
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    src={'/assets/customer-acquisition.png'}
                  ></IonImg>
                </>
              )}
            </>
          )}
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

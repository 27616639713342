import { useState, useEffect } from 'react'
import axios from 'axios'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
import MiniHeader from '../../commonComponents/MiniHeader'
import { personOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import CommonHeader from '../../commonComponents/CommonHeader'
import { cancelSubscriptionOptions } from '../../StaticData/StaticData'

export default function CancelledSubscriptionDetails({ match }) {
  let subscription_id = match?.params?.id
  const [subscription, setSubscription] = useState()
  const [subscriptionTemp, setSubscriptionTemp] = useState()
  const [subscriptionChanged, setSubscriptionChanged] = useState(false)
  const [reasonModalVisible, setReasonModalVisible] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [reason, setReason] = useState('')
  const [cancellingMembership, setCancellingMembership] = useState(false)
  const [cancelledMembership, setCancelledMembership] = useState(false)
  const getSubscription = async () => {
    let response = await axios.get(`/subscription?id=${subscription_id}`)

    setSubscription(response.data)
    setSubscriptionTemp(response.data)
    setSelectedValue(response?.data?.cancellationReason)
    setReason(response?.data?.writtenFeedback)
  }
  useEffect(() => {
    getSubscription()
  }, [subscription_id])
  useEffect(() => {
    getSubscription()
  }, [])

  const save = async () => {
    let response = await axios.patch(`/subscription?id=${subscription_id}`, subscriptionTemp)
    await getSubscription()
    setSubscriptionChanged(false)
  }

  const cancel = () => {
    setSubscriptionTemp(subscription)
    setSubscriptionChanged(false)
  }
  const openReasonModal = () => {
    setReasonModalVisible(true)
  }
  const closeReasonModal = async () => {
    setSelectedValue(subscriptionTemp?.cancellationReason)
    setReason(subscriptionTemp?.writtenFeedback)
    setReasonModalVisible(false)
    setCancellingMembership(false)
    await getSubscription()
  }
  const handleChange = (event) => {
    setSelectedValue(event.detail.value)
  }
  const handleReason = (event) => {
    setReason(event.detail.value)
  }
  const handleUpdateCancelSubscription = async () => {
    setCancellingMembership(true)
    try {
      const response = await axios.patch(`/subscription/cancel?id=${subscription_id}&customerId=${subscriptionTemp.customerId}`, {
        cancellationReason: selectedValue,
        writtenFeedback: selectedValue == 'other' ? reason : '',
      })

      if (response?.data?.success) {
        setCancelledMembership(true)
        setReasonModalVisible(false)
        setCancellingMembership(false)
        await getSubscription()
      }
      // getSubscription()
    } catch (error) {
      alert(error)
    }
  }

  const reasonItem = cancelSubscriptionOptions.find((subscriptionItem, subscriptionIndex) => subscriptionItem?.value === subscriptionTemp?.cancellationReason)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{subscriptionTemp ? 'Subscription' : 'loading...'}</IonTitle>
          {subscriptionChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {subscription ? (
          <StandardContainer>
            <IonCard style={{ padding: 20 }}>
              <MiniHeader title={'Subscription Details'} />
              <h5>
                {subscriptionTemp?.customer?.firstName} {subscriptionTemp?.customer?.lastName}
              </h5>
              <p>
                <strong>{subscriptionTemp?.subscriptionPackage?.name}</strong>
              </p>
              <p>
                ${subscriptionTemp?.price} {subscriptionTemp?.period}
              </p>
              <p>Subscription Start Date : {new Date(subscriptionTemp?.startDate).toLocaleDateString()}</p>
              <p>{subscriptionTemp?.status}</p>

              <p>{subscriptionTemp?.status == 'Canceled' ? 'Canceled Date: ' + new Date(subscriptionTemp?.endDate).toLocaleDateString() : null}</p>
              <p>Platform: {subscriptionTemp?.initiationPlatform}</p>
            </IonCard>
            <IonCard style={{ padding: 20 }}>
              <MiniHeader title={'Reason'} />
              <IonGrid style={{ marginBottom: -18, marginTop: -10 }}>
                <IonRow>
                  <IonCol style={{ display: 'flex', alignItems: 'center' }}>
                    {reasonItem?.label === 'Other' ? (
                      <IonLabel style={{ fontSize: '0.8rem', color: 'grey' }}>
                        Reason: {reasonItem.label} {subscriptionTemp.writtenFeedback ? ' - ' + subscriptionTemp.writtenFeedback : ''}
                      </IonLabel>
                    ) : (
                      <IonLabel style={{ fontSize: '0.8rem', color: 'grey' }}>{reasonItem?.label}</IonLabel>
                    )}
                  </IonCol>
                  <IonCol size='auto'>
                    <IonButton fill='clear' onClick={openReasonModal} style={{}}>
                      Change
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
            <IonCard style={{ padding: 20 }}>
              <MiniHeader title={'Sold by'} />
              <IonGrid style={{ marginBottom: -18, marginTop: -10 }}>
                <IonRow>
                  <IonCol>
                    <h6>
                      {subscriptionTemp?.soldEmployeeId ? (
                        <>
                          <span style={{ color: 'gold' }}>★</span> {subscriptionTemp?.soldEmployee?.firstName} {subscriptionTemp?.soldEmployee?.lastName}
                        </>
                      ) : (
                        <h6>Self Sale</h6>
                      )}
                    </h6>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>

            <br></br>
            <div style={{ marginLeft: 16 }}>
              <MiniHeader title={'CUSTOMER'} />
            </div>
            <IonList inset={true} mode='ios'>
              <IonItem button routerLink={`/core/customers/${subscriptionTemp?.customerId}`}>
                <IonIcon icon={personOutline} slot='start' style={{ color: colors.black }} />
                <IonLabel>Customer Profile</IonLabel>
              </IonItem>
            </IonList>
            <IonModal isOpen={reasonModalVisible} mode='ios'>
              <CommonHeader
                loading={cancellingMembership}
                title={'Update Cancellation Reason'}
                closeButton={true}
                saveButton={true}
                handleSaveButton={handleUpdateCancelSubscription}
                closeModal={closeReasonModal}
              ></CommonHeader>
              <IonContent className='regularBackground'>
                <IonCard style={{ padding: '1rem' }}>
                  <IonGrid>
                    {}
                    <IonRadioGroup value={selectedValue} onIonChange={handleChange}>
                      {cancelSubscriptionOptions.map((item, index) => (
                        <IonRow key={index} style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }} onClick={() => setSelectedValue(item.value)}>
                          {}
                          <IonLabel
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '8px', // Adjust the gap size as needed
                            }}
                          >
                            <IonRadio slot='start' checked={selectedValue === item.value} mode='md' />
                            {item.label}
                          </IonLabel>
                        </IonRow>
                      ))}
                    </IonRadioGroup>
                  </IonGrid>
                  {}
                  {selectedValue == 'other' && (
                    <IonTextarea
                      value={reason}
                      onIonChange={handleReason}
                      style={{
                        padding: '1rem',
                        border: '1px solid #ccc', // Example border style
                        borderRadius: '5px', // Example border radius
                        width: '100%', // Example width
                        marginBottom: '1rem',
                        minHeight: '6rem',
                      }}
                      placeholder='Please Explain'
                    ></IonTextarea>
                  )}
                </IonCard>
              </IonContent>
            </IonModal>
          </StandardContainer>
        ) : (
          <IonSpinner name='dots' />
        )}
      </IonContent>
    </IonPage>
  )
}

import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { roles } from '../StaticData/StaticData'
export default function EachLocationPermission({ location_id, user }) {
  const { locationData, locationId, businessData } = useContext(AuthContext)
  const [updatingPermissions, setUpdatingPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [selectedPermissions, setSelectedPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [locationDetails, setLocationData] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleupdatingPermissions = (value, permission) => {
    // Clone the object to avoid mutating the state directly
    const updatedPermissions = { ...updatingPermissions }

    // Set only the specified permission to true, others to false
    Object.keys(updatedPermissions).forEach((key) => {
      updatedPermissions[key] = key === permission ? value : false
    })

    // Set the updated object in the state
    setUpdatingPermissions(updatedPermissions)
  }

  const getLocationDetails = async () => {
    if (location_id) {
      setLoading(true)
      try {
        let response = await axios.get(`/location?id=${location_id}`)
        if (response && response.status == 200) {
          setLocationData(response.data)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
      }
    }
  }
  const updateEntitlements = () => {
    const getLocationEntitlements = () => {
      const location = user?.businesses.flatMap((business) => business.locations).find((loc) => loc._id === location_id)

      return location ? location.entitlements : null
    }

    // Use the function to update the selectedPermissions state
    const updatedPermissions = getLocationEntitlements()

    if (updatedPermissions) {
      setSelectedPermissions(updatedPermissions)
    } else {
      console.error('Location not found in the business array')
    }
  }
  useEffect(() => {
    updateEntitlements()
    getLocationDetails()
  }, [])

  useEffect(() => {
    getLocationDetails()
  }, [location_id])
  const handlePermission = async (permission) => {
    // Clone the object to avoid mutating the state directly
    const updatedPermissions = JSON.parse(JSON.stringify({ ...selectedPermissions }))

    // Set only the specified permission to true, others to false
    Object.keys(updatedPermissions).forEach((key) => {
      updatedPermissions[key] = key === permission
    })

    // Set the updated object in the state
    setSelectedPermissions(updatedPermissions)
    handleupdatingPermissions(true, permission)

    let userBusiness = JSON.parse(JSON.stringify([...user.businesses]))

    // Find the index of the business based on _id
    const businessIndex = userBusiness.findIndex((business) => business._id === businessData?._id)

    // Check if the business exists at the specified index
    if (businessIndex !== -1) {
      // Find the index of the location within the business based on _id
      const locationIndex = userBusiness[businessIndex].locations.findIndex((location) => location._id === location_id)

      // Check if the location exists at the specified index
      if (locationIndex !== -1) {
        // Update only the specified permission in the matching location of that business
        const locationEntitlements = userBusiness[businessIndex].locations[locationIndex].entitlements

        Object.keys(locationEntitlements).forEach((key) => {
          locationEntitlements[key] = key === permission ? updatedPermissions[permission] : false
        })

        const data = {
          _id: user?._id,
          email: user?.email,
          businesses: userBusiness,
        }

        try {
          let response = await axios.patch(`/user`, data)
          if (response && response.status === 200) {
            handleupdatingPermissions(false, permission)
          }
        } catch (error) {
          console.error(error)
          handleupdatingPermissions(false, permission)
        }
      }
    }
  }

  return (
    <IonCard style={{ padding: '10px' }}>
      {loading && <IonSpinner name='dots' />}
      {!loading && (
        <IonGrid>
          <IonRow style={{ display: 'flex', alignItems: 'end' }}>
            <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size='large' />
            <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>{locationDetails.name} Permissions</h6>
          </IonRow>
          <IonRow style={{ marginTop: '1rem' }}>
            {roles.map((item, index) => {
              return (
                <IonRow>
                  <IonCol size='auto'>
                    <h6
                      style={{
                        margin: '5px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {item.role}
                    </h6>
                    <IonRow onClick={() => handlePermission(item.value)}>
                      <IonCol size='10'>
                        <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                      </IonCol>
                      <IonCol>
                        {updatingPermissions[item.value] ? (
                          <IonSpinner name='lines' />
                        ) : (
                          <IonCheckbox style={{ height: '1.5rem', width: '1.5rem' }} checked={selectedPermissions[item.value]} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              )
            })}
          </IonRow>
        </IonGrid>
      )}
    </IonCard>
  )
}

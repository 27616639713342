import React, { useState, useEffect, useContext } from 'react';
import { IonButtons, IonButton, IonImg, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonList, IonCol, IonGrid, IonRow, IonIcon, IonCard } from '@ionic/react';
import { arrowForwardOutline, laptopOutline, peopleOutline, locationOutline, chevronForward, filterOutline, filterCircleOutline } from 'ionicons/icons';
import { colors } from '../theme/colors';
import Header from './header';
import StandardContainer from '../commonComponents/StandardContainer';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';

import { AuthContext } from '../auth';
import { Redirect } from 'react-router';

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const {firebaseUser} = useContext(AuthContext)

  if (firebaseUser) return <Redirect to='/core/dashboard' />


  function handleScroll(ev) {
    setScrollPosition(ev.detail.currentY);
  }

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  let isSmall = vw > 500 ? false : true;

  return (
    <IonPage>
      <Header type={'white'} scrollPosition={scrollPosition} />
      <IonContent
        fullscreen
        scrollEvents={true}
        onIonScroll={handleScroll}
      >

        <HeroSection2 isSmall={isSmall} />
        {/* <FeaturesSection isSmall={isSmall} /> */}
        {/* 
        <ServiceFiltering isSmall={isSmall} />
        <CustomerInsights isSmall={isSmall} /> */}
        <Letter />
      </IonContent>
    </IonPage>
  );
};

function ServiceFiltering({ isSmall }) {
  return (
    <div style={{ backgroundColor: colors.regularBackground }}>
      <StandardContainer padding={10} maxWidth={1000}>
        <div style={{
          justifyContent: 'center',
          width: '100%',
          display: 'flex',
          marginBottom: 50,
          marginTop: 50
        }}>
          <IonGrid style={{ padding: 0, margin: 0 }}>
            <IonRow style={{ padding: 0, margin: 0 }}>

              <IonCol size-md="6" size="12" style={{ padding: 0, margin: 0, justifyContent: 'center', alignItems: 'center' }}>
                <StandardCenterCenter>
                  <IonCard style={{ marginTop: 30, padding: -2 }}>
                    <IonImg
                      style={{
                        maxWidth: 350,
                        width: '100%',
                      }}
                      src={'/assets/home/service_filtering.webp'}
                      alt="Advanced Service Filtering"
                    />
                  </IonCard>
                </StandardCenterCenter>
              </IonCol>
              <IonCol size-md="6" size="12" style={{ padding: 0, margin: 0 }}>
                <h1 style={{ fontSize: 40 }}>Service Filtering</h1>
                <h3>Find and book the perfect service with ease.</h3>
                <p><strong>Find the Right Service</strong><br></br> Use our advanced filtering options to easily find services based on stylist expertise, available slots, and preferred location.</p>
                <p><strong>Personalized Suggestions</strong><br></br> Receive personalized service recommendations based on your past preferences and popular trends.</p>
                <ViewServicesButton />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </StandardContainer>
    </div>
  );
}

function CustomerInsights({ isSmall }) {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <StandardContainer padding={10} maxWidth={1000}>
        <div style={{
          justifyContent: 'center',
          width: '100%',
          display: 'flex',
          marginBottom: 50,
          marginTop: 50
        }}>
          <IonGrid style={{ padding: 0, margin: 0 }}>
            <IonRow style={{ padding: 0, margin: 0 }}>
              <IonCol size-md="6" size="12" style={{ padding: 0, margin: 0 }}>
                <h1 style={{ fontSize: 40 }}>Customer Insights</h1>
                <h3>Understand your customers better.</h3>
                <p><strong>Subscription Trends</strong><br></br>Track subscription patterns and preferences to tailor your services and offers for maximum satisfaction.</p>
                <p><strong>Feedback and Reviews</strong><br></br>Gather insights from customer feedback and reviews to continuously improve your services.</p>
                <p><strong>Appointment History</strong><br></br>View detailed appointment history to offer personalized experiences based on past visits.</p>
                <ViewServicesButton />
              </IonCol>
              <IonCol size-md="6" size="12" style={{ padding: 0, margin: 0, justifyContent: 'center', alignItems: 'center' }}>
                <StandardCenterCenter>
                  <IonCard style={{ marginTop: 30, padding: -2 }}>
                    <IonImg
                      className='round'
                      style={{
                        maxWidth: 350,
                        width: '100%',
                        borderRadius: 20, // Rounded corners
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow effect
                      }}
                      src={'/assets/home/customer_insights.webp'}
                      alt="Customer Insights"
                    />
                  </IonCard>
                </StandardCenterCenter>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </StandardContainer>
    </div>
  );
}

function FeaturesSection({ isSmall }) {

  let pd = isSmall ? 20 : 60;
  let imgURL = isSmall ? "/assets/home/features_small.webp" : "/assets/home/features_large.webp";

  return (
    <div style={{ position: 'relative', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: 'black', padding: pd }}>

      <h4>Why Choose Our Software?</h4>
      <br></br>
      <IonImg src={imgURL} style={{ width: '100%' }} alt="Features of Our Software" />
    </div>
  );
}

let goldGradientStyle = {
  background: "linear-gradient(45deg, #fdc601, #da9d00)",
  "-webkit-background-clip": "text",
  backgroundClip: "text",
  color: "transparent",
};

function HeroSection2({ isSmall }) {

  let backgroundImageHeight = isSmall ? 650 : 650;

  let titleFontSize = isSmall ? 33 : 45;
  let image = isSmall ? '/assets/home/hero.png' : '/assets/home/hero.png';
  let gridTopMargin = isSmall ? 10 : 70;
  let marginTopForPic = isSmall ? 0 : -60;
  let featureImageWidth = isSmall ? '100%' : '130%';

  return (
    <div style={{ fontFamily: 'Figtree', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'white', height: backgroundImageHeight }}>
      {/* <IonImg style={{ width: '100%', height: backgroundImageHeight, objectFit: 'cover', objectPosition: 'left' }} src={image} alt="Barbershop/Salon Management Software"></IonImg> */}
      <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', position: 'absolute', zIndex: 99, top: 0, color: '#fff', marginTop: 90 }}>
        <StandardContainer padding={0} maxWidth={1000}>
          <IonGrid style={{ margin: 10, padding: 0, color: 'black', marginTop: gridTopMargin }}>
            <IonRow>
              <IonCol size-md="7" size="12">
                <div style={{ display: 'flex', marginBottom: 10 }}>
                  <div style={{ backgroundColor: colors.regularBackground, borderRadius: 20, padding: 4, paddingLeft: 10, paddingRight: 10, fontSize: 14, fontWeight: '500' }}>Built By The Owners of a Barbershop</div>
                </div>
                <h1 style={{ fontFamily: 'Figtree', marginTop: 0, fontSize: titleFontSize, fontWeight: '700' }}>The Best Barbershop</h1>
                <h1 style={{ fontFamily: 'Figtree', marginTop: -10, fontSize: titleFontSize, fontWeight: '700' }}>Management</h1>
                <h1 style={{ fontFamily: 'Figtree', marginTop: -10, fontSize: titleFontSize, fontWeight: '700' }}>Software</h1>
                <p style={{ fontFamily: 'Figtree', fontSize: 20, fontWeight: 500, marginBottom: 10, marginTop: 10 }}>Trusted by top salons and barbershops for streamlined management and superior customer experience.</p>
                <IonButton color={"primary"} routerLink='/services' routerDirection='none' style={{ fontFamily: 'Montserrat, Avenir, Arial, Helvetica, sans-serif', fontWeight: '600' }}>Start for Free</IonButton>
              </IonCol>

              <IonCol size-md="5" size="12" >
                <IonImg style={{ maxWidth: 700, marginTop: marginTopForPic, width: featureImageWidth }} src={'/assets/home/hero.png'} alt="Salon Features" />
              </IonCol>
            </IonRow>

          </IonGrid>
        </StandardContainer>

      </div>
    </div>
  );
}

function Letter() {
  return (
    <StandardContainer>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <StandardCenterCenter>
        <h2>Letter from the Co-Founder</h2>
      </StandardCenterCenter>
      <IonCard style={{ marginTop: 20, color: 'black', padding:50, fontSize:16 }}>
        <div>

          <p>Dear Visitors,</p>
          <p>
            I want to share with you the story behind the creation of our barber management software. As a co-founder and owner of several barbershops, I faced numerous challenges that led me to develop this unique solution. My barbershops operate on a subscription-based model, and I quickly realized that there were no software solutions designed specifically for this type of business.
          </p>
          <p>
            Existing options required either the use of multiple systems or a significant amount of manual training for our staff to ensure accurate data entry and management. This fragmentation not only created inefficiencies but also limited our ability to generate meaningful reports and insights, which are crucial for running a successful business.
          </p>
          <p>
            Driven by these frustrations, we decided to build our own software tailored to the specific needs of subscription-based barbershops. We wanted a tool that would streamline operations, provide comprehensive reporting, and offer valuable insights—all in one place. We aligned our incentives with those of our customers because we use this software every day in our own barbershops. When the system is down, we feel the impact just as much as our clients do.
          </p>
          <p>
            Our goal is to provide a seamless and efficient experience for barbershop owners and managers, helping them focus on what they do best—providing excellent service to their clients. We believe that by solving our own problems, we can help others in the industry overcome similar challenges and succeed in their businesses.
          </p>
          <p>Thank you for taking the time to learn about our journey. We are committed to continuously improving our software and supporting the barbershop community.</p>
          <p>Warm regards,</p>
          <p>Scott<br />Co-Founder</p>
        </div>
      </IonCard>
    </StandardContainer>

  )
}

function ViewServicesButton() {
  return <IonButton routerLink='/services' routerDirection='none' fill="clear" style={{ color: '#12784b', fontFamily: 'Montserrat, Avenir, Arial, Helvetica, sans-serif' }}>View Services <IonIcon icon={chevronForward} style={{ height: 24, width: 24 }} /></IonButton>
}

export default Home;

import * as React from 'react'
import dayjs from 'dayjs'
import { formatDateToISOString, getFormattedMediumDate, getWeekday } from '../../utils'
import TimeSelector from '../timeSelector'
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react'

const times = [
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
]

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
  }
}

export default function SimpleDialog({ onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = React.useState(getDefaultData())
  const [errorMessage, setErrorMessage] = React.useState('error')
  const [isSaving, setIsSaving] = React.useState(false)

  React.useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
  }, [open])

  const save = () => {
    setErrorMessage('')
    if (data.enabled) {
      if (!data.startTime || !data.endTime) {
        setErrorMessage('**Open time and close time must be filled out')
        return
      }
    }
    setIsSaving(true)
    handleSave(data)
  }

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value, d) => {
    onClose(value)
  }

  function handleChange(id, fieldKey, newValue) {
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }

  function updateDate(id, date) {
    let weekday = getWeekday(date)
    let standardScheduleForDay = standardSchedule[weekday]
    let startTime = standardScheduleForDay.startTime
    let endTime = standardScheduleForDay.endTime
    let enabled = standardScheduleForDay.enabled

    setData({
      ...data,
      ...{
        startTime,
        endTime,
        enabled,
        dateText: getFormattedMediumDate(date),
        dateEpoch: date.getTime(),
      },
    })
  }

  return (
    <IonModal isOpen={open} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add a Modified Schedule</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent padding={20}>
        <IonList>
          <IonItem style={{ cursor: 'default' }}>
            <IonLabel>Date</IonLabel>
            <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
          </IonItem>
          <IonItem style={{ cursor: 'default' }}>
            <IonLabel>Working Day</IonLabel>
            <IonToggle checked={data.enabled} onIonChange={(e) => handleChange(data, 'enabled', e.detail.checked)} />
            <IonLabel>{data.enabled ? 'Working' : 'Day Off'}</IonLabel>
          </IonItem>
          {data.enabled && (
            <>
              <IonRow>
                <IonCol>
                  <IonItem style={{ cursor: 'default' }}>
                    <IonLabel position='stacked'>Start Time</IonLabel>
                    <TimeSelector dayKey={data._id} fieldKey='startTime' value={data.startTime} handleChange={handleChange} />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem style={{ cursor: 'default' }}>
                    <IonLabel position='stacked'>End Time</IonLabel>
                    <TimeSelector dayKey={data._id} fieldKey='endTime' value={data.endTime} handleChange={handleChange} />
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
          <IonText color='danger'>
            <p>{errorMessage}</p>
          </IonText>
        </IonList>
        <IonButton onClick={handleClose}>Cancel</IonButton>
        <IonButton disabled={isSaving} onClick={save}>
          Save
        </IonButton>
      </IonContent>
    </IonModal>
  )
}

function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = (newValue) => {
    updateDate(id, new Date(newValue))
    setValue(new Date(newValue))
  }

  return (
    <>
      <IonDatetimeButton datetime='addBreakDateTime'></IonDatetimeButton>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          id='addBreakDateTime'
          presentation='date'
          value={formatDateToISOString(new Date(value))}
          onIonChange={(e) => {
            handleChange(e.detail.value)
          }}
          formatOptions={{
            date: {
              weekday: 'short',
              month: 'long',
              day: '2-digit',
            },
          }}
        ></IonDatetime>
      </IonModal>{' '}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker size='small' inputFormat='MM/DD/YYYY' value={dayjs(value)} onChange={handleChange} />
      </LocalizationProvider> */}
    </>
  )
}

import { IonIcon, IonLabel } from '@ionic/react'
import { useState } from 'react'
import { formatDateWithWeekDay } from '../../../utils'
import { calendarOutline, timeOutline } from 'ionicons/icons'
import EditAppointmentTime from '../../details/EditAppointmentTime'
import styles from '../Styles/AppointmentDateTimeStyles'

export default function AppointmentDateTime({ mode, setStep, hideBorder, appointment, getAppointment }) {
  const [showCalendar, setShowCalendar] = useState(false)

  const handleShowCalendar = () => {
    setShowCalendar(true)
  }

  const closeCalendar = () => {
    setShowCalendar(false)
  }

  return (
    <>
      <div style={{ ...styles.containerStyle, borderBottom: hideBorder ? 'none' : styles.containerStyle.borderBottom }}>
        <div style={styles.headerStyle}>
          <div style={styles.dateTimeContainerStyle}>
            <div style={styles.dateTimeHeaderStyle}>
              <div style={styles.iconStyle}>
                <IonIcon style={styles.iconImageStyle} icon={calendarOutline} />
              </div>
              <div style={styles.dateStyle}>{appointment ? formatDateWithWeekDay(new Date(appointment.startTime)) : ''}</div>
            </div>
            <div style={styles.timeContainerStyle}>
              <div style={styles.timeHeaderStyle}>
                <div style={styles.iconStyle}>
                  <IonIcon style={styles.iconImageStyle} icon={timeOutline} />
                </div>
                <div style={styles.timeInfoStyle}>
                  <div style={styles.timeTextStyle}>
                    {appointment?.startTimeText} - {appointment?.endTimeText}
                  </div>
                  <div style={styles.durationTextStyle}>({appointment?.durationMin} min)</div>
                </div>
              </div>
            </div>
          </div>

          {['Complete'].includes(appointment.status) ? null : (
            <div style={styles.changeButtonStyle}>
              <div onClick={() => (mode == 'create' ? setStep('dateTime') : handleShowCalendar())} style={styles.changeTextStyle}>
                <IonLabel>Change</IonLabel>
              </div>
            </div>
          )}
        </div>
      </div>
      <EditAppointmentTime closeCalendar={closeCalendar} showCalendar={showCalendar} updateAppointmentData={getAppointment} appointmentData={appointment} />
    </>
  )
}

import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { colors } from '../theme/colors';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import StandardCenterCenter from '../commonComponents/StandardCenterCenter';
import CategoryToggelControls from './progressToggleControl';

const ProgressChart = ({ data, firstLoad, loading, projectedValue, selectedCategory, setSelectedCategory, currentValue }) => {
    const [chartRenderId, setChartRenderId] = useState(1)

    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'area',
                height: 250,
                toolbar: false
            },
            xaxis: {
                categories: [],
                tickAmount: 7,
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: function (value) {
                        if (!value) {
                            return '';
                        }
                        const day = value.split('/')[1];
                        return day.startsWith('0') ? day.substring(1) : day;
                    }
                },
                axisBorder: {
                    show: false // Remove the x-axis border line
                },
                axisTicks: {
                    show: false // Remove the x-axis tick marks
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            grid: {
                borderColor: '#f1f1f1',
                show: false
            },
            yaxis: {
                title: {
                    text: '',
                },
                min: 0, // Ensure the minimum y value is always 0
                labels: {
                    style: {
                        colors: 'gray'
                    },
                    formatter: getYFormatter
                },
                max: 0 // Initialize with 0, will be calculated based on data
            },
            tooltip: {
                shared: true,
            },
            fill: {
                type: ['solid', 'gradient'],
                opacity: [0, 1], // Opacity for gray and blue lines
                gradient: {
                    shadeIntensity: 1,
                    type: 'vertical',
                    opacityFrom: 0.7,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            },
            legend: {
                show: true,
                offsetY: 0
            },
            colors: ['#A9A9A9', colors.primaryBlue], // Gray and primary blue colors
            dataLabels: {
                enabled: false // Disable the number boxes on the lines
            }
        },
        series: []
    });

    function getYFormatter (value) {
        if (selectedCategory === 'Revenue') {
            return formatAsMoney(value);
        } else {
            return value?.toFixed(0);
        }
    }

    useEffect(() => {
        if (!data || !data.thisMonth || !data.lastMonth) {
            return;
        }

        const categories = data.thisMonth.trends.map(item => item.date);
        let lastMonthData = data.lastMonth.trends.map(item => item.cumulativeCount);
        const thisMonthData = data.thisMonth.trends.map(item => item.cumulativeCount);

        // If the previous month has fewer days, fill the remaining days with null
        if (lastMonthData.length < thisMonthData.length) {
            lastMonthData = [...lastMonthData, ...Array(thisMonthData.length - lastMonthData.length).fill(null)];
        }

        const maxLastMonth = Math.max(...lastMonthData.filter(num => num !== null));
        const maxThisMonth = Math.max(...thisMonthData);
        const yAxisMax = Math.ceil(Math.max(maxLastMonth, maxThisMonth) * 1.10);

        setChartData(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: categories
                },
                yaxis: {
                    ...prevState.options.yaxis,
                    max: yAxisMax
                }
            },
            series: [
                { name: 'Previous Month', data: lastMonthData },
                { name: 'Current Month', data: thisMonthData }
            ]
        }));
        setChartRenderId(JSON.stringify(data))
    }, [data]);

    return (
        <IonRow>
            <IonCol>
                <CategoryToggelControls selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            </IonCol>
            <IonCol size='12'>
                {(!data) ?
                    <StandardCenterCenter><div style={{ height: 200 }}><IonSpinner name="dots" /></div></StandardCenterCenter>
                    : <>
                        <IonGrid style={{ margin: 0, padding: 0 }}>
                            <IonRow style={{ margin: 0, padding: 0 }}>
                                <IonCol style={{ margin: 0, padding: 0 }}>
                                    <h2 style={{ marginTop: 4, marginBottom: 0 }}>{selectedCategory == "Revenue" ? formatAsMoney(currentValue): currentValue}</h2>
                                    <h6 style={{ marginTop: 0, fontSize: 12, color: 'gray' }}>{selectedCategory == "Revenue" ? formatAsMoney(projectedValue) : projectedValue} Projected</h6>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <Chart
                            key={chartRenderId} // Force re-render when chartData changes
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            height={250}
                            style={{ marginBottom: -30, marginTop: -10, marginLeft: -10, marginRight: -10 }}
                        />
                    </>}
            </IonCol>

        </IonRow>
    );
};


function formatAsMoney(amount, currency = 'USD', locale = 'en-US') {
    const roundedAmount = Math.round(amount); // Round to the nearest dollar
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0, // No decimal places
        maximumFractionDigits: 0  // No decimal places
    }).format(roundedAmount);
}

export default ProgressChart;

import React, { useState, useEffect } from 'react'
import GeneralDetails from './details_general'
import Schedule from './schedule'
import axios from 'axios'
import Purchase from './details_purchase_settings'
import { useHistory } from 'react-router-dom'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonList,
  IonItem,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonIcon,
} from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import AdvancedDetails from './AdvancedDetails'
import {
  informationCircleOutline,
  calendarOutline,
  cartOutline,
  settingsOutline,
  codeDownloadOutline,
  cashOutline
} from 'ionicons/icons'
import DetailsRelationshipToBusiness from './details_relationship_to_business'
import RelationshipToBusiness from './RelationshipToBusiness'
import PaymentProcessorSettings from './PaymentProcessorSettings'
import { AccessControl } from '../AccessControl'

export default function Details({ match }) {
  const locationId = match?.params?.id
  const history = useHistory()
  const [tabValue, setTabValue] = React.useState(0)
  const [location, setLocation] = useState()
  const [locationTemp, setLocationTemp] = useState()
  const [locationChanged, setLocationChanged] = useState(false)
  const [activeTab, setActiveTab] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const getLocation = async () => {
    let response = await axios.get(`/location?id=${locationId}`)
    setLocation(response.data)
    setLocationTemp(response.data)
  }

  useEffect(() => {
    getLocation()
  }, [locationId])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const updateLocation = (value) => {
    setLocationTemp(value)
    setLocationChanged(true)
  }

  const save = async () => {
    setIsSaving(true)
    try {
      if (locationTemp.reviewIndex) {
        delete locationTemp.reviewIndex
      }
      if (locationTemp.twilioPhoneNumber) {
        delete locationTemp.twilioPhoneNumber
      }
      if (locationTemp.images) {
        delete locationTemp.images
      }
      await axios.patch(`/location?id=${locationId}`, locationTemp)
      await getLocation()
      setLocationChanged(false)
      setActiveTab(null)
    } catch (error) {
      alert('There was a problem saving.', error.message)
    } finally {
      setIsSaving(false)
    }
  }

  const cancel = () => {
    setLocationTemp(location)
    setLocationChanged(false)
  }

  const deleteLocation = async () => {
    if (window.confirm('Are you sure you want to delete the location?')) {
      // Delete it!
      // await axios.delete(`/location?id=${locationId}`)
      history.push(-1)
    }
  }
  let body

  if (!location)
    body = (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: 200 }}>
        <IonSpinner name='dots' />
      </div>
    )

  if (activeTab == 'general') {
    body = <GeneralDetails location={locationTemp} updateLocation={updateLocation} locationId={locationId} />
  } else if (activeTab == 'schedule') {
    body = <Schedule location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'purchases') {
    body = <Purchase location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'advanced') {
    body = <AdvancedDetails deleteLocation={deleteLocation} />
  } else if (activeTab == 'relationship') {
    body = <DetailsRelationshipToBusiness location={locationTemp} updateLocation={updateLocation} />
  } else if (activeTab == 'payment_processor') {
    body = <PaymentProcessorSettings location={locationTemp} updateLocation={updateLocation} />
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          {/* {locationChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null} */}
        </IonToolbar>
      </IonHeader>
      <IonContent className='regularBackground'>
        <StandardContainer padding={20}>
          <h1>{locationTemp ? locationTemp?.name : 'Loading...'}</h1>
          <RelationshipToBusiness relationshipToBusiness={locationTemp?.relationshipToBusiness} />
          <p style={{ color: 'grey' }}>{(locationTemp?.addressStreet1) ? `${(locationTemp.addressStreet1 || "")}` : ''}</p>
          <p style={{ marginTop: -14, color: 'grey' }}>{(locationTemp?.addressStreet1) ? `${(locationTemp.addressCity || "")}, ${(locationTemp.addressState || "")} ${(locationTemp.addressZipcode || "")}` : ''}</p>

          <h5 style={{ marginTop: 30 }}>Settings</h5>
          <IonList>
            <AccessControl componentName="location_general">
              <IonItem button onClick={() => setActiveTab('general')}>
                <IonIcon icon={informationCircleOutline} slot="start" />
                <IonLabel>General</IonLabel>
              </IonItem>
            </AccessControl>
            <AccessControl componentName="location_schedule">
              <IonItem button onClick={() => setActiveTab('schedule')}>
                <IonIcon icon={calendarOutline} slot="start" />
                <IonLabel>Schedule</IonLabel>
              </IonItem>
            </AccessControl>
            <AccessControl componentName="location_advanced_all">
              <IonItem button onClick={() => setActiveTab('purchases')}>
                <IonIcon icon={cartOutline} slot="start" />
                <IonLabel>Purchases</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('relationship')}>
                <IonIcon icon={codeDownloadOutline} slot="start" />
                <IonLabel>Relationship to Business</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('payment_processor')}>
                <IonIcon icon={cashOutline} slot="start" />
                <IonLabel>Payment Processor Settings</IonLabel>
              </IonItem>
              <IonItem button onClick={() => setActiveTab('advanced')}>
                <IonIcon icon={settingsOutline} slot="start" />
                <IonLabel>Advanced</IonLabel>
              </IonItem>
            </AccessControl>
          </IonList>

          {body}
        </StandardContainer>
      </IonContent>
      {/* Modal for displaying tab content */}
      <IonModal isOpen={activeTab !== null} onDidDismiss={() => setActiveTab(null)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{activeTab}</IonTitle>
            {isSaving ? (
              <IonButtons slot="end">
                <IonSpinner name="dots" />
              </IonButtons>
            ) : locationChanged ? (
              <>
                <IonButtons slot='end'>
                  <IonButton onClick={async () => { await cancel(); setActiveTab(null) }}>Cancel</IonButton>
                </IonButtons>
                <IonButtons slot='end'>
                  <IonButton color='primary' onClick={save}>
                    Save
                  </IonButton>
                </IonButtons>
              </>
            ) : (
              <IonButtons slot="end">
                <IonButton onClick={() => setActiveTab(null)}>Close</IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {body}
        </IonContent>
      </IonModal>
    </IonPage>
  )
}

import { useState, useEffect } from 'react'
import axios from 'axios'
import { IonButton, IonCheckbox, IonIcon, IonSpinner } from '@ionic/react'
import { timeOutline } from 'ionicons/icons'

const styles = {
  floatingButton: {
    color: 'white',
    fontWeight: '600',
    position: 'fixed',
    bottom: 10,
    right: '5%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'end',
    flexDirection: 'column',
    display: 'flex',
    padding: -1,
  },
  buttonStyle: {
    width: 120,
    margin: 0,
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default function StepPickService({
  userSubscription,
  scheduleData,
  businessId,
  locationId,
  updateScheduleData,
  step,
  stepNext,
  stepBack,
  totalSteps,
  isWalkin,
  trackStep,
}) {
  const [employee, setEmployee] = useState([])
  const [employees, setEmployees] = useState([])
  const [locationServices, setLocationServices] = useState()
  const [servicesSelected, setServicesSelected] = useState([])

  useEffect(() => {
    let servicesList = []
    for (let s of scheduleData.services) {
      servicesList.push(s._id)
    }
    setServicesSelected(servicesList)
  }, [scheduleData])

  useEffect(() => {
    getEmployeesList()
  }, [])

  useEffect(() => {
    trackStep('Service', step, scheduleData)
  }, [])

  const getEmployeesList = async () => {
    // let response = await axios.get(`/employee/getPublicList?businessId=${businessId}&locationId=${scheduleData.location._id}`)
    // setEmployees(response.data)
    if (scheduleData?.barber?._id) {
      let response = await axios.get(`/employee/public?id=${scheduleData?.barber?._id}`)
      setEmployees([response.data])
    }
    if (scheduleData?.anyProfessional || isWalkin) {
      let response = await axios.get(`/employee/getPublicListWithServices?businessId=${businessId}&locationId=${scheduleData.location._id}`)
      setEmployees(response.data)
    }
  }

  useEffect(() => {
    getServicesList()
  }, [])

  const getServicesList = async () => {
    let locQuery = locationId ? `&locationId=${locationId}` : ''
    let response = await axios.get(`/service/getList?businessId=${businessId}${locQuery}`)
    setLocationServices(response.data)
  }

  function handleServiceSelection(service) {
    if (servicesSelected.includes(service._id)) {
      //Remove Service
      let servicesList = []
      for (let s of scheduleData.services) {
        if (s._id != service._id) {
          servicesList.push(s)
        }
      }
      updateScheduleData('services', servicesList)
    } else {
      //Add Service
      updateScheduleData('services', [...scheduleData.services, service])
    }
  }

  function confirmSelection() {
    let servicesList = []
    scheduleData?.services?.map((item, index) => {
      const checkIfBarberHasService = scheduleData?.barber?.servicesInfo?.find((barberService, index) => barberService._id == item._id)
      let updatedService = { ...item }
      if (checkIfBarberHasService) {
        updatedService['durationMin'] = checkIfBarberHasService.durationMin
      } else {
        const service = locationServices.find((locationService, index) => locationService._id == item._id)
        updatedService['durationMin'] = service.durationMin
      }
      servicesList.push(updatedService)
    })

    updateScheduleData('services', servicesList)
    stepNext()
  }

  return (
    <>
      <ServicesList
        scheduleData={scheduleData}
        userSubscription={userSubscription}
        locationServices={locationServices}
        employees={employees}
        handleServiceSelection={handleServiceSelection}
        servicesSelected={servicesSelected}
        isWalkin={isWalkin}
      />
      <NextButton confirmSelection={confirmSelection} servicesSelected={servicesSelected} />
    </>
  )
}

function NextButton({ confirmSelection, servicesSelected }) {
  if (servicesSelected.length <= 0) return null
  return (
    <div style={styles.floatingButton}>
      <IonButton color={'dark'} onClick={confirmSelection} style={styles.buttonStyle}>
        Next
      </IonButton>
    </div>
  )
}

function ServicesList({ locationServices, employees, handleServiceSelection, servicesSelected, userSubscription, isWalkin, anyProfessional, scheduleData }) {
  if (!locationServices || (!isWalkin && !employees))
    return (
      <div style={styles.loadingBox}>
        <IonSpinner name='dots' />
      </div>
    )

  // if (anyProfessional) {
  return (
    <div
      style={{
        padding: 20,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'inline-flex',
      }}
    >
      <div
        style={{
          alignSelf: 'stretch',
          height: 51,
          paddingBottom: 12,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 10,
          display: 'flex',
        }}
      >
        <div
          style={{
            alignSelf: 'stretch',
            height: 39,
            color: '#222222',
            fontSize: 32,
            fontFamily: 'Figtree',
            fontWeight: '600',
            lineHeight: '38.4px',
            wordWrap: 'break-word',
          }}
        >
          Select Services
        </div>
      </div>
      <div
        style={{
          alignSelf: 'stretch',
          paddingBottom: 32,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {locationServices &&
          locationServices.map((row) => {
            const isServiceAvailable = employees.some((employee) => {
              return employee.services && employee.services.includes(row._id)
            })

            if (isServiceAvailable) {
              return (
                <ServiceCard
                  scheduleData={scheduleData}
                  userSubscription={userSubscription}
                  service={row}
                  handleServiceSelection={handleServiceSelection}
                  key={row._id}
                  servicesSelected={servicesSelected}
                />
              )
            }

            return null
          })}
      </div>
    </div>
  )
}

function ServiceCard({ scheduleData, service, handleServiceSelection, servicesSelected, userSubscription }) {
  let priceBoxStyles = { border: '1px solid grey' }
  if (servicesSelected.includes(service._id)) {
    priceBoxStyles = {
      backgroundColor: '#2175ea',
      border: '1px solid #2175ea',
      color: 'white',
    }
  }

  let price = `${service.price}`
  if (userSubscription) {
    if (service.includedInSubscriptions.some((subscription) => subscription._id === userSubscription.subscriptionPackageId)) {
      price = 'Free'
    }
  }
  const checkIfBarberHasService = scheduleData?.barber?.servicesInfo?.find((item, index) => item._id == service._id)
  let updatedService = { ...service }
  if (checkIfBarberHasService) {
    updatedService['durationMin'] = checkIfBarberHasService.durationMin
  }

  return (
    <>
      <div
        onClick={() => {
          handleServiceSelection(updatedService)
        }}
        style={{
          cursor: 'pointer',
          alignSelf: 'stretch',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 24,
          display: 'flex',
        }}
      >
        <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'flex' }}>
          <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
            <div
              style={{
                width: '100%',
                paddingTop: 14,
                paddingBottom: 14,
                borderBottom: '1px #EBEBEB solid',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 10,
                display: 'flex',
              }}
            >
              <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div
                  style={{
                    color: '#222222',
                    fontSize: 18,
                    fontFamily: 'Figtree',
                    fontWeight: '600',
                    lineHeight: '28px',
                    wordWrap: 'break-word',
                  }}
                >
                  {updatedService.name}
                </div>
                <div style={{ justifyContent: 'flex-end', alignItems: 'center', gap: 10, display: 'flex' }}>
                  <div style={{ borderRadius: 99, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <IonCheckbox
                      slot='end'
                      checked={servicesSelected?.some((selectedService) => selectedService === service._id)}
                      onIonChange={() => handleServiceSelection(updatedService)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div
                  style={{
                    paddingRight: 8,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 8,
                    display: 'inline-flex',
                  }}
                >
                  <div style={{ alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                    <div
                      style={{
                        flex: '1 1 0',
                        color: '#717171',
                        fontSize: 16,
                        fontFamily: 'Figtree',
                        fontWeight: '400',
                        lineHeight: '24px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {updatedService.description}
                    </div>
                  </div>
                  <div style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}>
                    <div style={{ height: 20, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex' }}>
                      <div style={{ flex: '1 1 0', height: 20, justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
                        <div style={{ width: 16, height: 16, position: 'relative' }}>
                          <IonIcon icon={timeOutline} />
                        </div>
                        <div
                          style={{
                            flex: '1 1 0',
                            color: '#222222',
                            fontSize: 14,
                            fontFamily: 'Figtree',
                            fontWeight: '400',
                            lineHeight: '20px',
                            wordWrap: 'break-word',
                          }}
                        >
                          {checkIfBarberHasService && checkIfBarberHasService.durationMin ? checkIfBarberHasService.durationMin : updatedService.durationMin}{' '}
                          min
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 4, display: 'inline-flex' }}>
                  <div style={{ justifyContent: 'flex-end', alignItems: 'flex-start', gap: 4, display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', display: 'inline-flex' }}>
                      <div style={{ paddingLeft: 18, justifyContent: 'flex-end', alignItems: 'center', display: 'inline-flex' }}>
                        <div style={{ textAlign: 'right', position: 'relative', display: 'inline-block' }}>
                          <span
                            style={{
                              color: '#222222',
                              fontSize: 14,
                              fontFamily: 'Figtree',
                              fontWeight: '600',
                              lineHeight: '28px',
                              wordWrap: 'break-word',
                              position: 'absolute',
                              top: '-8px',
                              left: '-1px',
                            }}
                          >
                            $
                          </span>
                          <span
                            style={{
                              color: '#222222',
                              fontSize: 18,
                              fontFamily: 'Figtree',
                              fontWeight: '600',
                              lineHeight: '18px',
                              wordWrap: 'break-word',
                              paddingLeft: '10px', // Adjust padding as needed to align with dollar sign
                            }}
                          >
                            {price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

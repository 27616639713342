import React, { useState, useEffect, useContext } from 'react'
import ResultsList from './resultsList'
import axios from 'axios'
import AddDialog from './addDialog'
import { AuthContext } from '../auth'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import StandardContainer from '../commonComponents/StandardContainer'

export default function ServiceListView() {
  const { businessData, locationId } = useContext(AuthContext)
  const [services, setServices] = useState([])
  const [addServiceDialogOpen, setAddServiceDialogOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  // useEffect(()=>{
  //     window.onpopstate = () => {
  //         getServicesList()
  //     }
  //     return ()=>{window.onpopstate = null}
  // })
  useEffect(() => {
    getServicesList()
  }, [businessData, locationId])

  const getServicesList = async (ignoreLoading) => {
    if (!businessData?._id) return
    setLoading(!ignoreLoading)
    setErrorMessage(null)
    try {
      let locQuery = locationId ? `&locationId=${locationId}` : ''
      let response = await axios.get(`/service/getList?businessId=${businessData._id}${locQuery}`)
      setServices(response.data)
    } catch (error) {
      setErrorMessage('Sorry there was an error fetching the data')
    }
    setLoading(false)
  }

  const openAddServiceDialog = () => {
    setAddServiceDialogOpen(true)
  }
  const closeAddServiceDialog = () => {
    getServicesList()
    setAddServiceDialogOpen(false)
  }

  async function handleRefresh(event) {
    await getServicesList(true)
    event.detail.complete()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonTitle>Services</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={openAddServiceDialog}>
              <IonIcon slot='icon-only' icon={addCircle} color='primary' />
            </IonButton>
          </IonButtons>
          {loading ? <IonProgressBar type='indeterminate' /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <StandardContainer>
          <ResultsList services={services} locationId={locationId} />
        </StandardContainer>
      </IonContent>
      <AddDialog open={addServiceDialogOpen} handleClose={closeAddServiceDialog} businessId={businessData._id} locationId={locationId} />
    </IonPage>
  )
}

import Avatar from '../commonComponents/avatar'
import { useHistory } from 'react-router-dom'
import { IonAvatar, IonItem, IonLabel, IonList } from '@ionic/react'

export default function ResultsList({ employees = [], locationId, businessId }) {
  const history = useHistory()

  return (
    <>
      <h6 style={{ color: 'grey', marginLeft: 20 }}>Employees</h6>
      <IonList inset={true} mode='ios'>
        {/* <IonListHeader>
        <IonLabel>Employees</IonLabel>
      </IonListHeader> */}
        {employees?.map((row) => (
          <IonItem button detail={true} routerLink={`/core/employees/${row._id}`} routerDirection='forward' key={row._id}>
            <IonAvatar slot='start' style={{ marginTop: 10, marginBottom: 10 }}>
              <Avatar employee={row} businessId={businessId} small />
            </IonAvatar>
            <IonLabel>
              {/* {`${row.firstName} ${row.lastName}`} */}
              <h4 style={{ fontWeight: '600', color: 'black', fontSize: 18 }}>{`${row.firstName} ${row.lastName}`}</h4>
              <p>{row.role}</p>
            </IonLabel>
          </IonItem>
        ))}
        {/* <IonItem style={{height:1200}}>
          
        </IonItem> */}
      </IonList>
    </>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
} from '@ionic/react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { add, checkmarkCircle, removeCircleOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import axios from 'axios'
import { useLocation, useParams } from 'react-router'

export default function BusinessDomains() {
  const { id } = useParams() // 'id' must match the parameter name in the route path

  const { businessData, getBusinessData } = useContext(AuthContext)
  const [domainInfo, setDomainInfo] = useState(businessData?.domainInfo || [])
  const [showModal, setShowModal] = useState(false)
  const [newDomainName, setNewDomainName] = useState('')
  const [dataChanged, setDataChanged] = useState(false)
  const [savingDomains, setSavingDomains] = useState(false)
  const [hasPrimaryDomain, setHasPrimary] = useState(false)
  const addDomain = () => {
    const newDomain = {
      name: newDomainName,
      hostname: newDomainName,
    }
    setDomainInfo([...domainInfo, newDomain])
    setShowModal(false)
    setNewDomainName('')
    setDataChanged(true)
  }

  const removeDomain = index => {
    const updatedDomains = [...domainInfo]
    updatedDomains.splice(index, 1)
    setDomainInfo(updatedDomains)
  }

  const makePrimary = index => {
    const updatedDomains = domainInfo.map((domain, idx) => {
      let domainData = {
        ...domain,
      }
      if (idx === index) {
        domainData.type = 'primary'
      } else {
        delete domainData.type
      }
      return domainData
    })
    setDataChanged(true)
    setDomainInfo(updatedDomains)
  }

  const updateDomains = async () => {
    try {
      setSavingDomains(true)
      const input = { domainInfo: domainInfo }

      const response = await axios.patch(`/business?id=${businessData?._id}`, input)

      if (response.status === 200) {
        setSavingDomains(false)
        setDataChanged(false)
        getBusinessData()
      }
    } catch (error) {
      console.error('Error updating domains', error)
    }
  }
  const location = useLocation()
  useEffect(() => {
    if (location.pathname == `/core/businessDomains/${id}`) {
      setDomainInfo(businessData?.domainInfo || [])
      setDataChanged(false)
      setHasPrimary(false)
    }
  }, [location])

  useEffect(() => {
    setDomainInfo(businessData?.domainInfo || [])
    setDataChanged(false)
    setHasPrimary(false)
  }, [businessData])

  useEffect(() => {
    const hasPrimary = domainInfo.some(domain => domain.type === 'primary')
    setHasPrimary(hasPrimary)
  }, [domainInfo])

  return (
    <IonPage id='main-content'>
      <CommonHeader
        saveDisabled={savingDomains || !hasPrimaryDomain || !dataChanged}
        saveButton={true}
        handleSaveButton={updateDomains}
        title={'Business Domains'}
        backIcon={true}
        loading={savingDomains}
      ></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ margin: '1rem' }}>
            <IonLabel style={{ fontSize: '12px', color: colors.grey }}>
              Click on any domain to make it primary
            </IonLabel>
          </div>
          <IonList mode='ios' inset={true}>
            {domainInfo.map((item, index) => (
              <IonItem key={index} onClick={() => makePrimary(index)}>
                <IonGrid>
                  <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                    <IonCol size='auto'>
                      <IonIcon
                        style={{ marginRight: '0.5rem', color: 'red', height: '1.5rem' }}
                        icon={removeCircleOutline}
                        onClick={e => {
                          e.stopPropagation() // Prevent the primary toggle action
                          removeDomain(index)
                        }}
                      />
                    </IonCol>
                    <IonCol>
                      <IonRow>
                        <IonCol>
                          <IonLabel>{item.name}</IonLabel>
                          <IonLabel style={{ color: 'grey', fontSize: '12px' }}>
                            {item.hostname}
                          </IonLabel>
                        </IonCol>
                        {item.type === 'primary' && (
                          <IonCol size='auto'>
                            <IonIcon color='primary' icon={checkmarkCircle} />
                          </IonCol>
                        )}
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            ))}
          </IonList>
          <IonButton fill='clear' slot='end' onClick={() => setShowModal(true)}>
            <IonIcon slot='start' icon={add}></IonIcon>
            Add New Domain
          </IonButton>
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <CommonHeader title={'Domain Details'}></CommonHeader>
            <IonContent className='regularBackground'>
              <StandardContainer>
                <IonCard style={{ padding: '1rem' }}>
                  <IonItem>
                    <IonLabel position='floating'>Domain Name</IonLabel>
                    <IonInput
                      value={newDomainName}
                      onIonInput={e => setNewDomainName(e.detail.value.trim())}
                      type='text'
                      placeholder='Enter domain'
                    />
                  </IonItem>
                  <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'end' }}>
                    <IonButton disabled={!newDomainName.trim()} onClick={addDomain}>
                      Submit
                    </IonButton>
                    <IonButton color='medium' onClick={() => setShowModal(false)}>
                      Close
                    </IonButton>
                  </div>
                </IonCard>
              </StandardContainer>
            </IonContent>
          </IonModal>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonInput, IonLabel, IonRow, IonSpinner } from '@ionic/react'
import StandardContainer from '../../commonComponents/StandardContainer'
export default function CancelAppointmentForm({
  defaultCancellationCharges,
  defaultChargeEnabled,
  toggleDefaultCharge,
  appointment,
  customChargeValue,
  handleCustomChargeValue,
  error,
  closeModal,
  loading,
  handleCancelAppointment,
  cardOnFile,
}) {
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)

  const cancellationAllowed = timeDifferenceInHours > 24

  return (
    <IonContent className='regularBackground'>
      <StandardContainer>
        <div style={{ marginTop: '2rem', padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {/* <h5 style={{ fontWeight: 'bold', color: 'black' }}>Cancel Appointment</h5> */}
          <IonLabel style={{ fontWeight: 'bold' }}>
            {!cancellationAllowed && cardOnFile && appointment?.agreedToCancelPolicy
              ? `Since you are canceling within 24 hours of the appointment you will be charged a ${defaultCancellationCharges} fee. Would you still like to cancel?`
              : 'Are you sure you want to cancel'}
          </IonLabel>
          {!(!cancellationAllowed && cardOnFile && appointment?.agreedToCancelPolicy) ? null : (
            <>
              <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                <IonCheckbox slot='start' checked={defaultChargeEnabled} onIonChange={toggleDefaultCharge}></IonCheckbox>

                <IonLabel onClick={toggleDefaultCharge} style={{ marginLeft: '0.5rem' }}>
                  Charge {appointment?.customer?.firstName} {appointment?.customer?.lastName} a cancelation charge of ${defaultCancellationCharges}
                </IonLabel>
              </div>
              {!defaultChargeEnabled && (
                <div style={{ width: '100%' }}>
                  <h6 style={{ color: 'black', fontSize: '12px' }}>Custom Charge</h6>
                  <IonRow style={{ margin: 0, padding: 0 }}>
                    <IonCol>
                      <IonInput
                        fill='outline'
                        type='number'
                        value={customChargeValue}
                        onIonInput={(e) => handleCustomChargeValue(e.detail.value)}
                        placeholder='0'
                        inputmode='decimal'
                      />
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </>
          )}
          {error && <IonLabel style={{ width: '100%', margin: '1rem  0', color: 'red', fontSize: '12px' }}>{error}</IonLabel>}
          <div style={{ marginTop: '1rem', borderTop: '1px solid #d3d3d3' }}></div>
          <IonButtons
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IonButton onClick={() => closeModal()} style={{ color: 'grey' }}>
              Dismiss
            </IonButton>
            {loading && <IonSpinner name='lines' />}
            {!loading && (
              <IonButton
                color={'primary'}
                disabled={
                  cancellationAllowed || !cardOnFile || !appointment?.agreedToCancelPolicy
                    ? false
                    : !((defaultChargeEnabled === true || customChargeValue != '') && error == '')
                }
                onClick={handleCancelAppointment}
              >
                Cancel Appointment
              </IonButton>
            )}
          </IonButtons>
        </div>
      </StandardContainer>
    </IonContent>
  )
}

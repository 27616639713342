import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonModal, IonRow, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { colors } from '../../../theme/colors'
import { cardOutline } from 'ionicons/icons'
import AddCardToFile from './AddCardToFile'
import CommonHeader from '../../../commonComponents/CommonHeader'

export default function CardOnFile({ customerId, onCardUpdate = () => {}, setIsCardOnFile = () => {}, buttonText = 'Save', watcherValue = false }) {
  const [card, setCard] = useState()
  const [loading, setLoading] = useState(false)

  const [showCardInput, setShowCardInput] = useState(false)
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)
  const getCard = async () => {
    setLoading(true)
    let response = await axios.get(`/customer/cardStripe?customerId=${customerId}`)
    setCard(response.data)
    setIsCardOnFile(response?.data?.cardOnFile || false)
    setLoading(false)
  }

  useEffect(() => {
    getCard()
  }, [watcherValue])

  function handleCardUpdate() {
    getCard()
    closeAddPaymentMethodModal()
    onCardUpdate()
  }
  const openAddPaymentMethodModal = () => {
    setAddCardModalOpen(true)
  }
  const closeAddPaymentMethodModal = () => {
    setAddCardModalOpen(false)
  }
  const cardBrandImages = {
    visa: '/assets/cards/visa.png',
    mastercard: '/assets/cards/mastercard.jpg',
    amex: '/assets/cards/amex.png',
    discover: '/assets/cards/discover.png',
    diners: '/assets/cards/diners.png',
    jcb: '/assets/cards/jcb.png',
    unionpay: '/assets/cards/unionpay.png',
  }

  const cardBrand = card?.cardDetails?.cardBrand?.toLowerCase()
  const cardImageSrc = cardBrandImages[cardBrand] || '/assets/cards/default.png' // Default image if card brand is not recognized

  return (
    <>
      {}
      <IonGrid style={{ margin: 0 }}>
        <IonRow style={{ display: 'flex', alignItems: 'center' }}>
          {!loading && (
            <>
              {card?.cardOnFile ? (
                <IonCol
                  size='auto'
                  style={{
                    border: `1px solid ${colors.black20}`,
                    height: 30,
                    width: 'auto',
                    borderRadius: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img style={{ height: '100%' }} src={cardImageSrc} alt={cardBrand} />
                </IonCol>
              ) : (
                <IonCol style={{ color: colors.black50, display: 'flex', alignItems: 'center' }}>
                  <IonIcon style={{ marginRight: 10, height: 35, width: 35 }} icon={cardOutline} />
                  <IonLabel>None Selected</IonLabel>
                </IonCol>
              )}
            </>
          )}
          <IonCol>
            <div style={{ height: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              {loading ? (
                <IonSpinner name='dots' />
              ) : (
                <>
                  {' '}
                  {!card?.cardOnFile ? (
                    <IonRow style={{ marginRight: 10, display: 'flex', justifyContent: 'end' }}>
                      <IonLabel onClick={openAddPaymentMethodModal} color={'primary'} style={{ textDecoration: 'underline' }}>
                        Add Payment Method
                      </IonLabel>
                    </IonRow>
                  ) : (
                    <IonRow>
                      <IonLabel style={{ fontWeight: '500', color: '#313131' }}>{capitalizeFirstLetter(card?.cardDetails?.cardBrand)}</IonLabel>
                      <IonLabel>...</IonLabel>
                      <IonLabel>{card?.cardDetails?.cardLast4}</IonLabel>
                    </IonRow>
                  )}
                </>
              )}
            </div>
          </IonCol>
          {card?.cardOnFile && (
            <IonCol size='auto'>
              <div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                <IonButton
                  fill='clear'
                  onClick={() => {
                    openAddPaymentMethodModal()
                  }}
                >
                  {card?.cardOnFile ? <> {'Change'}</> : ''}
                </IonButton>
              </div>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      <IonModal isOpen={addCardModalOpen}>
        <IonContent>
          <CommonHeader title={'Add New Card'} closeButton={true} closeModal={closeAddPaymentMethodModal} />
          <AddCardToFile buttonText={buttonText} addCardToFileSuccess={handleCardUpdate} customerId={customerId} />
        </IonContent>
      </IonModal>
    </>
  )
}

function capitalizeFirstLetter(str) {
  if (!str) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

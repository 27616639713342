import { useState } from 'react'
import { colors } from '../../../theme/colors'
import { IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import NoShowButton from '../../details/NoShowButton'
import { cashOutline, timeOutline } from 'ionicons/icons'
import PaymentOptions from './PaymentOptions'

export default function StatusButtons({ appointment, rescheduling, updateStatus, updatingStatus, getAppointment, subscription }) {
  const [paymentOptionsModalOpen, setPaymentOptionsModalOpen] = useState(false)

  return (
    <>
      {['In Progress', 'Complete', 'Canceled', 'No Show', 'Arrived'].includes(appointment?.status) ? null : !appointment?.barber?._id ? (
        <IonRow>
          <IonCol>
            <p style={{ margin: 0, color: colors.orange }}>You must add a professional before you can start the appointment</p>
          </IonCol>
        </IonRow>
      ) : !appointment?.startTimeText ? (
        <IonRow>
          <IonCol>
            <p style={{ margin: 0, color: colors.orange }}>You must add a start and end time of the appointment</p>
          </IonCol>
        </IonRow>
      ) : (
        <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'flex-end', gap: 8, display: 'inline-flex' }}>
          <NoShowButton appointment={appointment} getAppointment={getAppointment} />

          <IonButton
            style={{
              flex: '1 1 0',
              height: 52,
              background: 'radial-gradient(50.91% 25.97% at 50.91% 21.92%, #005BCC 0%, #006AF5 53%, #0068DE 100%)',
              borderRadius: 60,
              overflow: 'hidden',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 6,
              display: 'flex',
            }}
            disabled={updatingStatus}
            expand='block'
            fill='solid'
            shape='round'
            onClick={() => {
              updateStatus('Arrived')
            }}
          >
            Arrived
          </IonButton>
        </div>
      )}
      {['Scheduled', 'In Progress', 'Complete', 'Canceled', 'No Show'].includes(appointment?.status) ? null : (
        <IonButton
          disabled={updatingStatus || !appointment?.barber?._id}
          expand='block'
          shape='round'
          fill='solid'
          onClick={() => {
            updateStatus('In Progress')
          }}
        >
          <IonIcon icon={timeOutline} />
          <IonLabel style={{ marginLeft: 20 }}> Start Appointment</IonLabel>
        </IonButton>
      )}
      {['In Progress'].includes(appointment?.status) ? (
        <IonButton
          shape='round'
          disabled={updatingStatus || rescheduling}
          expand='block'
          fill='solid'
          onClick={() => {
            setPaymentOptionsModalOpen(true)
            // updateStatus('Complete')
          }}
        >
          <IonIcon icon={cashOutline} />
          <IonLabel style={{ marginLeft: 10 }}>Finalize Payment + Tip</IonLabel>
        </IonButton>
      ) : null}

      <PaymentOptions
        setPaymentOptionsModalOpen={setPaymentOptionsModalOpen}
        paymentOptionsModalOpen={paymentOptionsModalOpen}
        appointment={appointment}
        getAppointment={getAppointment}
        subscription={subscription}
      />
    </>
  )
}

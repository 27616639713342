import { IonCol, IonContent, IonItem, IonLabel, IonList, IonPage, IonSpinner, IonToggle } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'

export default function EmailReminder() {
  const { locationData, locationId, businessData } = useContext(AuthContext)
  const [enableEmailReminders, setEnableEmailReminders] = useState(false)
  const [enablingReminders, setEnablingReminders] = useState(true)
  const toggleEmailReminders = async () => {
    let value = !enableEmailReminders
    setEnableEmailReminders(value)
    setEnablingReminders(true)
    try {
      const response = await axios.patch(`/location?id=${locationId}`, {
        businessId: businessData?._id,
        remindersEnabled: value,
      })
      if (response && response.status === 200) {
        setEnablingReminders(false)
      }
    } catch (error) {}
    setTimeout(() => {
      setEnablingReminders(false)
    }, 1000)
  }
  useEffect(() => {
    const locationDataResponse = GetLocationDataBasedOnLocationId(locationData, locationId)
    if (locationDataResponse?._id) {
      setEnableEmailReminders(locationDataResponse?.remindersEnabled)
      setEnablingReminders(false)
    } else {
      setEnablingReminders(false)
    }
  }, [locationId])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={'Email Reminders'} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <div style={{ padding: '0 1rem' }}>
            <IonList inset={true} style={{ margin: '1rem 0' }} mode='ios'>
              <IonItem>
                <IonCol size='9'>
                  <IonLabel>Enable Email Reminders</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'end' }}>
                  {enablingReminders && <IonSpinner name='lines' />}
                  {!enablingReminders && (
                    <IonToggle
                      justify='space-between'
                      size='small'
                      checked={enableEmailReminders}
                      onIonChange={(e) => {
                        toggleEmailReminders(e.detail.checked)
                      }}
                    />
                  )}
                </IonCol>
              </IonItem>
            </IonList>
          </div>
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}

import { IonButton, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import { AuthContext } from '../auth'

const AppHeader = () => {
  const history = useHistory()
  const { logout } = useContext(AuthContext)
  const handleClick = () => history.push('/')
  const handleSignout = () => logout()
  return (
    <IonHeader>
      <IonToolbar color='dark'>
        <IonTitle slot='start' onClick={handleClick}>
          SCIXXOR
        </IonTitle>
        <IonButton color='light' fill='outline' slot='end' onClick={handleSignout}>
          Sign Out
        </IonButton>
      </IonToolbar>
    </IonHeader>
  )
}

export default AppHeader

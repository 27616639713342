import TimeZonePicker from '../commonComponents/TimeZonePicker'
import BookingThreshold from './BookingThreshold'
import ChargeCustomer from './ChargeCustomer'
import { IonButton } from '@ionic/react'

export default function AdvancedDetails({ deleteLocation }) {
  return (
    <div>
      <TimeZonePicker />
      <BookingThreshold />
      {/* component for charging customer for cancelling appointment before 24 hrs */}
      <ChargeCustomer type='cancel' title={'Cancel Appointment Charges'} />
      {/* component for charging a customer if they dont show up for the appointment */}
      <ChargeCustomer type='noShow' title={'No Show Charges'} />
      <div style={{ margin: '1rem ' }}>
        <IonButton color='warning' onClick={deleteLocation}>
          Delete
        </IonButton>
      </div>
    </div>
  )
}

import { useState, useEffect } from 'react'
import slugify from 'slugify'
import CustomInput from '../../../SetupBusiness/CustomInput'
import ButtonPanel from '../buttonPanel'
import FlowError from '../error'
import axios from 'axios'
import StripeOnboardingButton from '../../StripeOnboardingButton'
import { colors } from '../../../theme/colors'

const BusinessForm = ({ data, flowIndex, onBack, onNext, onCancel, history, businessData, getUserData, getBusinessData }) => {
  const [businessName, setBusinessName] = useState('')
  const [slugifiedName, setSlugifiedName] = useState('')
  const [domainName, setDomainName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleBusinessName = (value) => {
    setSlugifiedName(generateSlugifiedName(value))
    setBusinessName(value) // Update business name
  }

  const handleDomainName = (value) => setDomainName(value.toLowerCase())
  const handleNext = async () => {
    try {
      setIsLoading(true)
      await axios.post(`/business`, { name: businessName, website: domainName })
      setError('')
      getBusinessData()
      getUserData()
      setIsLoading(false)
      onNext()
    } catch (error) {
      setError(error?.response?.data?.error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if(businessData?._id){
      onNext()
    }
  }, [businessData])

  return (
    <>
      <p>Next let's capture some high level information about your business.</p>
      <CustomInput label={'What is your business name? *'} value={businessName} placeholder='ex: Scixxor' handleInput={handleBusinessName} />

      <br></br>
      {/* <StripeOnboardingButton/> */}

      {/* <div style={{ display: 'flex', marginBottom:10 }}>
        <div style={{ backgroundColor: colors.primaryBlue90, color: colors.primaryBlue, border: `1px solid ${colors.primaryBlue}`, borderRadius: 20, paddingLeft: 8, paddingRight: 8 }}>{slugifiedName}</div>
      </div> */}
      {/* <CustomInput
        label={'Generated Scixxor subdomain'}
        placeholder='auto generated from your business name'
        value={slugifiedName}
        handleInput={() => {}}
        readonly
      /> */}
      {/* <p>
        The generated Scixxor subdomain can be used while you are testing out the new site or can be used right out of the gate to start getting your customers
        on board.
      </p>
      <CustomInput label={'Already have a domain name? (optional)'} placeholder='ex: scixxor.com' value={domainName} handleInput={handleDomainName} />
      <p>Your domain name (if you have one) can be set up to point to your Scixxor site later from the admin section</p> */}
      <FlowError message={error} />
      <ButtonPanel data={data} flowIndex={flowIndex} onBack={onBack} onNext={handleNext} onCancel={onCancel} history={history} loading={isLoading} />
    </>
  )
}

function generateSlugifiedName(value) {
  // First, slugify the value with the desired options
  let slug = slugify(value, { lower: true, trim: true });

  // Remove any characters that are not letters, digits, or hyphens
  slug = slug.replace(/[^a-z0-9-]/g, '');

  // Ensure the slug starts with a letter
  if (!/^[a-z]/.test(slug)) {
    slug = 'a' + slug;
  }

  // Ensure the slug is between 4 and 20 characters
  if (slug.length < 4) {
    slug = slug.padEnd(4, 'a'); // Pad with 'a' if too short
  } else if (slug.length > 20) {
    slug = slug.substring(0, 20); // Trim to 20 characters if too long
  }

  // Set the slugified name with the desired format
  const slugifiedName = `${slug}.scixxor.com`;

  console.log(slugifiedName);
  return slugifiedName;
}

export default BusinessForm

import FinalizePaymentAndTip from './FinalizePaymentAndTip'
import ExternalPayment from './ExternalPayment'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react'
import { cardOutline, cashOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { calculatePayment } from '../../../sharedComponents/paymentCalculation'

export default function PaymentOptions({ appointment, getAppointment, subscription, paymentOptionsModalOpen, setPaymentOptionsModalOpen }) {
  const [finalizePaymentModalOpen, setFinalizePaymentModalOpen] = useState(false)
  const [payExternalModalOpen, setPayExternalModalOpen] = useState(false)
  const [tip, setTip] = useState()
  const [payment, setPayment] = useState([])
  const [updating, setUpdating] = useState(false)
  const payedExternal = async () => {
    if (window.confirm('Has the customer paid through other ways than this app? This should be uncommon. Click "Ok" to continue')) {
      setUpdating(true)
      try {
        let response = await axios.patch(`/appointment/updateStatus`, {
          appointmentId: appointment?._id,
          status: 'Complete',
          paidExternal: true,
        })
        await getAppointment()
      } catch (error) {}
      setUpdating(false)
      setFinalizePaymentModalOpen(false)
      setPaymentOptionsModalOpen(false)
      setPayExternalModalOpen(false)
    }
  }

  useEffect(() => {
    // if (appointment && appointment.status != 'Complete') {
    setPayment(
      calculatePayment(
        appointment?.services,
        appointment?.products,
        appointment?.discounts,
        subscription?.data?.subscription?.subscriptionPackageId,
        tip || appointment?.payment?.tipAmount,
        tip?.id || appointment?.payment?.tipId,
        null,
        appointment?.payment?.cashTipAmount || 0
      )
    )
    // } else if (appointment) {
    //   setPayment(appointment?.payment)
    // }
  }, [subscription, appointment, tip])
  return (
    <>
      <IonModal
        isOpen={paymentOptionsModalOpen}
        onDidDismiss={() => {
          setPaymentOptionsModalOpen(false)
          setPayExternalModalOpen(false)
        }}
        trigger='open-modal'
        initialBreakpoint={0.75}
        breakpoints={[0.75, 1]}
        cssClass='custom-modal'
      >
        <IonContent>
          <div style={{ padding: '1rem' }}>
            <h2>Payment Options</h2>
            <IonList mode='ios' inset={true}>
              <IonItem onClick={() => setFinalizePaymentModalOpen(true)} detail={true}>
                <IonIcon icon={cardOutline} slot='start' />
                <IonLabel>Pay with card</IonLabel>
              </IonItem>
              <IonItem onClick={() => setPayExternalModalOpen(true)} detail={true}>
                <IonIcon icon={cashOutline} slot='start' />
                <IonLabel>Pay external</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonModal>
      {}
      <FinalizePaymentAndTip
        setPaymentOptionsModalOpen={setPaymentOptionsModalOpen}
        isOpen={finalizePaymentModalOpen}
        setOpen={setFinalizePaymentModalOpen}
        appointment={appointment}
        updateAppointment={getAppointment}
        tip={tip}
        setTip={setTip}
        payment={payment}
      />
      <ExternalPayment
        payment={payment}
        payExternalModalOpen={payExternalModalOpen}
        setPayExternalModalOpen={setPayExternalModalOpen}
        updating={updating}
        appointment={appointment}
        payedExternal={payedExternal}
      />
    </>
  )
}

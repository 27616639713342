import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ResultsListItem from '../appointments/ResultsListItem'
import { IonList } from '@ionic/react'

export default function DetailsHistory({ customer_id, businessId, checkCustomer, locations, locationId }) {
  let [history, setHistory] = useState({ loading: false, data: null, error: null })

  async function getHistory() {
    if (customer_id) {
      try {
        setHistory({ ...history, ...{ loading: true } })
        let response = await axios.get(`/customer/appointments?customerId=${customer_id}`)

        setHistory({ loading: false, data: response.data, error: null })
      } catch (error) {
        setHistory({ loading: false, data: null, error: { message: error.message } })
      }
    }
  }
  useEffect(() => {
    getHistory()
  }, [customer_id])

  if (!customer_id) return 'loading...'

  return (
    <>
      <IonList inset={true}>
        {history?.data?.map((row) => (
          <ResultsListItem row={row} businessId={businessId} />
        ))}
      </IonList>
    </>
  )
}
